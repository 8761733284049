import React from 'react';
import { Util } from '../../../../core/util/util';
import { actionColorScheme } from '../../../../core/constants/colors';

function calculateActionTotals(chartData) {
    const totals = {};

    for (const platform of Object.values(chartData) as any) {
        for (const [action, actionData] of Object.entries(platform.actions) as any) {
            if (!totals[action]) {
                totals[action] = { notifications: 0, total: 0 };
            }
            totals[action].notifications += actionData.notifications;
            totals[action].total += actionData.total;
        }
    }

    return totals;
}

export const ActionInfoCard = ({ chartData }) => {
    const actionTotals = calculateActionTotals(chartData);
    const totalNotifications: any = Object.values(chartData).reduce((total, platform: any) => total + platform.notifications, 0);
    const totalChats: any = Object.values(chartData).reduce((total, platform: any) => total + platform.total, 0);

    return (
        <div style={{
            userSelect: 'none',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr))',
            gap: '10px',
            justifyContent: 'center',
            padding: '20px'
        }}>
            {Object.entries(actionTotals).map(([action, data]: any) => {
                const notificationsPercentage = ((data.notifications / totalNotifications) * 100).toFixed(2);
                const chatsPercentage = ((data.total / totalChats) * 100).toFixed(2);
                const colorScheme = actionColorScheme[action];
                const backgroundColor = colorScheme ? `linear-gradient(to bottom, ${colorScheme.primary}, ${colorScheme.secondary})` : 'lightgray';
                const color = colorScheme ? 'white' : 'black';

                return (
                    <div key={action} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        border: '1px solid lightgrey',
                        borderRadius: '10px',
                        padding: '20px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        margin: '10px',
                        background: backgroundColor,
                        color: color
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <hr style={{ flex: 1, borderTop: '2px solid' }} />
                            <h3 style={{ fontSize: 22, fontWeight: 500, margin: '0 10px' }}>{Util.capitalize(action)}</h3>
                            <hr style={{ flex: 1, borderTop: '2px solid' }} />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ fontSize: 18, fontWeight: 500 }}>Notificaciones</div>
                            <div>{data.notifications} ({notificationsPercentage}%)</div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ fontSize: 18, marginTop: 8, fontWeight: 500 }}>Chats</div>
                            <div>{data.total} ({chatsPercentage}%)</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
