import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { UserSocialMedia } from '../../../models/db/user-social-media';
import { UserSocialMediaService } from '../../../services/db/userSocialMedia/user-social-media.service';





export const useUserSocialMedia = () => {

    const getAllIdUserSocialMedia = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            UserSocialMediaService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getUserSocialMediaById = (token, id) => {
        return new Promise<any>((res, rej) => {
            UserSocialMediaService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getUserSocialMedia = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            UserSocialMediaService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addUserSocialMedia = (token, user: UserSocialMedia) => {
        return new Promise<any>((res, rej) => {
            UserSocialMediaService.add(token, { ...user })
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateUserSocialMedia = (token, user: UserSocialMedia) => {
        return new Promise<any>((res, rej) => {
            UserSocialMediaService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const deleteUserSocialMedia = (token, idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            UserSocialMediaService.delete(token, idList, action)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getUserSocialMediaAutocomplete = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            UserSocialMediaService.getAutocomplete(token)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    return {
        getAllIdUserSocialMedia,
        getUserSocialMediaById,
        getUserSocialMedia,
        addUserSocialMedia,
        updateUserSocialMedia,
        deleteUserSocialMedia,

        get: getUserSocialMedia,
        add: addUserSocialMedia,
        update: updateUserSocialMedia,
        autocomplete: getUserSocialMediaAutocomplete,
        delete: deleteUserSocialMedia
    }
}