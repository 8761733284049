import React from 'react';
import { Navigate } from "react-router-dom";


interface GuardRouteProps {
    hasAccess: boolean;
    redirectPath?: string;
    children: JSX.Element;
    isAllowed?: boolean;

    roles?: any;
}

/**
 * Encargado de proteger las rutas
 * @param param0 
 * @returns 
 */
const GuardRoute = ({
    roles,
    hasAccess,
    isAllowed,
    children,
    redirectPath = "/login"
}: GuardRouteProps) => {
    const identity = localStorage.getItem("identity");
    let idRole = identity
        ? JSON.parse(localStorage.getItem("identity"))
            ? JSON.parse(localStorage.getItem("identity")).idRole
            : undefined
        : undefined;
    if (!hasAccess) {
        return <Navigate to="/login" replace />;
        // } else if (!isAllowed) {
        //     console.log("identity test", localStorage.getItem("identity"))
        //     return <Navigate to={redirectPath} replace />;
        // }
    } else if (!roles.includes(idRole)) {
        return <Navigate to={redirectPath} replace />;
    }
    return children;
};


export default GuardRoute;