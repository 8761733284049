


import React, { useEffect, useState } from 'react'
import { ErrorDragDropProps } from '../DragDrop';
import { FileItem } from '../model/FileItem';
import { TypeAlloweds } from '../util/format-types';



interface Props {
    fileItemList: FileItem[];
    maxSizeMB: number;
    typeAllowed: TypeAlloweds[]
    handleFileItem: (files: FileItem[]) => void;
    handleError: (key: string, value: boolean) => void;

}

export const useDragDrop = ({
    fileItemList,
    maxSizeMB,
    typeAllowed,
    handleError,
    handleFileItem
}: Props) => {

    const [fileList, setFilesList] = useState<FileItem[]>([]);
    const [isOverDrop, setOverDrop] = useState(false);


    useEffect(() => {

    }, []);


    const _getTransfer = (event: any) => {
        return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
    }

    const _extractFiles = async (archivosList: FileList) => {
        handleError("type", false);
        handleError("size", false);

        let fileItemListAux = [...fileItemList];

        let count = 0;
        for (const propiedad in Object.getOwnPropertyNames(archivosList)) {
       


            const fileTemporal = archivosList[propiedad];
      
            // const archivoTemporal = archivosList[propiedad];
            let canBeLoaded = await _handleFileCanBeLoaded(fileTemporal)
  
            if (canBeLoaded) {
                const fileItem = new FileItem(fileTemporal);
                fileItemListAux.push(fileItem);
                console.log("puede ser cargado");
            } else {
                console.warn("Un archivo no puede ser cargado -" + "\n\n"
                    + "Tipo:    " + fileTemporal.type + "\n"
                    + "Tamaño:  " + fileTemporal.size + "\n"
                    + "Nombre:  " + fileTemporal.name);
            }

            if (count === archivosList.length - 1) {
                setTimeout(() => {
                    handleFileItem(fileItemListAux);
                }, 50);
            }

            count += 1;
        }
    }


    // Validaciones
    const _handleFileCanBeLoaded = async (file: File) => {
        // console.log("Probando validaciones");
        // console.log("Check tipo de archivo", await _checkTypeFile(file.type));
        // console.log("Check nombre repetido", !_checkOldFile(file.name));
        // console.log("Check tamaño archivo", !_checkSizeFile(file.size));
        let allow = await _checkTypeFile(file.type);
        return (!_checkOldFile(file.name) && allow && _checkSizeFile(file.size)) ? true : false;
    }



    const _checkOldFile = (nameFile): boolean => {

        for (const fileItem of fileItemList) {

            if (fileItem.name == nameFile) {
                console.log(`El archivo ${nameFile} ya está agregado`);
                return true;
            }
        }

        return false;
    }


    const _checkTypeFile = (type: string) => {
        if (typeAllowed.length == 0) return true; // permite cualquiera
        console.warn("tipo del archivo", type);

        return (async () => {
            for (const t of typeAllowed) {
                let allow = await new Promise(res => {
                    let p = (type === '' || type === undefined) ? false : type.startsWith(t);
                    res(p);
                })
                if (allow) return true;
            }
            // this.error.emit({ tipoArchivo: true });
            // probar error aquí
            handleError("type", true);
            return false;
        })();
    }


    const _checkSizeFile = (size) => {
        if (maxSizeMB === undefined) return true;

        let byteMaximo = (maxSizeMB * 1204) * 1024;
        if (byteMaximo < size) handleError("size", true);
        if (byteMaximo < size) {
            // Codigo de Error. El archivo pesa más de lo permitido
            console.error("Codigo de Error. El archivo pesa más de lo permitido");
        }
        return byteMaximo > size;

    }


    const handleDrop = (e) => {
        const transferencia = _getTransfer(e);
        if (!transferencia) return;
        _extractFiles(transferencia.files);

        e.preventDefault();
        e.stopPropagation();

    }

    const handleDragOver = (e) => {
        // console.log("over", e);
        setOverDrop(true);
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDragLeave = (e) => {
        // console.log("leave", e);
        setOverDrop(false);
        e.preventDefault();
        e.stopPropagation();
    }



    return {
        isOverDrop,

        handleDrop,
        handleDragOver,
        handleDragLeave,

    }
}
