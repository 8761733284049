


import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { CRow } from '@coreui/react-pro';


export class TextPayload {

    message?: string;
    text?: string[];

}


export const TextBot = (textpayload: TextPayload & { userType: "bot" | "human", bannerColor: string }) => {
    const { text, userType, bannerColor } = textpayload;
    const styles = {
        botBubble: {
            margin: "5px",
   
            alignSelf: userType === 'bot' ? 'flex-start' : 'flex-end',
        }
    };

    return (
        <div style={styles.botBubble}>
            {text[0]}
        </div>
    );
}
