import React, { useState } from 'react'
import { Pagination } from '../../../models/pagination';
import { SmtpConfigurationService } from '../../../services/db/smtp/smtp-configuration.service';
import { SmtpConfiguration } from '../../../models/db/smtp-configuration';



export const useSmtpConfiguration = () => {

    const getSmtpConfigurationById = (token, id) => {
        return new Promise<any>((res, rej) => {
            SmtpConfigurationService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getSmtpConfiguration = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            SmtpConfigurationService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addSmtpConfiguration = (token, user: SmtpConfiguration) => {
        return new Promise<any>((res, rej) => {
            SmtpConfigurationService.add(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateSmtpConfiguration = (token, user: SmtpConfiguration) => {
        return new Promise<any>((res, rej) => {
            SmtpConfigurationService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }




    return {

        getSmtpConfigurationById,
        getSmtpConfiguration,
        addSmtpConfiguration,
        updateSmtpConfiguration,

        getById: getSmtpConfigurationById,
        get: getSmtpConfiguration,
        add: addSmtpConfiguration,
        update: updateSmtpConfiguration,
        autocomplete: getSmtpConfiguration,

    }
}