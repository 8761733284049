import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Login } from '../../../../core/models/login/login';
import { CAlert, CCol, CContainer, CFormInput, CInputGroup, CInputGroupText, CLoadingButton, CRow } from '@coreui/react-pro';
import { cilLockLocked, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { COLORS } from '../../../../core/constants/colors';
import logoNimo from "../../../../core/assets/img/logo_nimo-grupo-positivo-1tinta.png"



interface LoginFormProps {
  loadingButton: boolean;
  access: (values: Login) => void;
}

const initialValues: Login = {
  username: "",
  password: ""
}

const LoginForm = (props: LoginFormProps) => {

  const { access, loadingButton } = props;

  const { handleChange, values, handleSubmit,
    handleBlur, errors, touched, getFieldProps } = useFormik({
      initialValues: initialValues,
      onSubmit: (value) => access(value),
      validationSchema: Yup.object({
        username: Yup
          .string()
          .max(20, "Debe de tener 20 caracteres o menos")
          .required("Requerido"),
        password: Yup
          .string()
          .max(12, "Debe de tener 12 caracteres o menos")
          .required("Requerido"),
        //   email: Yup
        //     .string()
        //     .email("Inserta un email válido")
        //     .required("Requerido"),
      }),
    })




  return (
    <div>
      <form noValidate onSubmit={handleSubmit}>
        <CContainer>
          <CCol>
            <img className="sidebar-brand-full" src={logoNimo} alt="My Cool Image" height={120} />

            <CInputGroup className="mb-3">
              <CInputGroupText>
                <CIcon icon={cilUser} />
              </CInputGroupText>
              <CFormInput type="text" {...getFieldProps("username")} />
              {/* <Field name="username" type="text" /> */}
            </CInputGroup>
          </CCol>
          {touched.username && errors.username && <CAlert color="danger">{errors.username}</CAlert>}

        </CContainer>
        <CContainer>
          <CCol>
            <CInputGroup className="mb-4">
              <CInputGroupText>
                <CIcon icon={cilLockLocked} />
              </CInputGroupText>
              <CFormInput type="password" {...getFieldProps("password")} />
            </CInputGroup>
          </CCol>
          {touched.password && errors.password && <CAlert color="danger">{errors.password}</CAlert>}

        </CContainer>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CLoadingButton loading={loadingButton} type='submit' style={{ backgroundColor: COLORS.NIMO_CLARO }}>Entrar</CLoadingButton>
        </div>
      </form>



      {/* <Formik
        initialValues={initialValues}
        onSubmit={(values) => access(values)}
        validationSchema={Yup.object({
          username: Yup
            .string()
            .max(15, "Debe de tener 15 caracteres o menos")
            .required("Requerido"),
          password: Yup
            .string()
            .min(4, "Debe de tener al menos 4 caracteres")
            .required("Requerido"),
        })
        }
      >

        {(formik) => (
          <Form>
            <CContainer>
              <CCol>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput placeholder="Username" autoComplete="username" name='username' />

                  <Field name="username" type="text" />

                </CInputGroup>
              </CCol>

              <ErrorMessage name={'username'} component="span" />
            </CContainer>
            <CContainer>
              <CCol>
                <CInputGroup className="mb-4">
                  <CInputGroupText>
                    <CIcon icon={cilLockLocked} />
                  </CInputGroupText>
                  <CFormInput name="password" type="password" placeholder='Contraseña' />
                </CInputGroup>
              </CCol>
              <ErrorMessage name={'password'} component="span" />
            </CContainer>
            <button className="btn btn-primary btn-access" type='submit'>Login</button>
          </Form>
        )}
      </Formik> */}
    </div>
  )
}

export default LoginForm