export const BASE_ROUTE = "/dashboard"

export const ROUTE_NAME = {

    login: "/login",

    usuarios: "/usuarios",
    usuariosCrear: "/usuarios/crear",
    usuariosActualizar: "/usuarios/actualizar",
    usuariosEliminar: "/usuarios/eliminar",

    notificaciones: "/notificaciones",

    citas: "/citas",

    conversaciones: "/conversaciones",

    usuariosSocialMedia: "/usuarios-del-bot",

    smtp: "/configuracion-smtp",

    configuration: "/configuracion",

    home: "/home",

    rol: "/roles",
    rolCrear: "/roles/crear",
    rolActualizar: "/roles/actualizar",
    rolEliminar: "/roles/eliminar",


}