import axios from "axios";
import { Subject, Observable } from 'rxjs';
import { FileObservableProps } from "../../interfaces/interfaces";
import { FileItem } from "../../model/FileItem";



export class FileItemObservableService {

    private static _instance: FileItemObservableService;


    private _control: Subject<FileObservableProps>;
    public control$: Observable<FileObservableProps>;

    public constructor() {
        this._control = new Subject<FileObservableProps>();
        this.control$ = this._control.asObservable();
    }

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    sendFileItemList(value: FileObservableProps) {
        this._control.next(value);
    }

    clear() {
        this._control.next({ id: "", fileItemList: [] });
    }


}