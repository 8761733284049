import React from 'react'

import { CRow } from '@coreui/react-pro';
import BotMessage from './BotMessage';
import { CardPayload } from './card-bot';
import { QuickReplyPayload } from './quickreply-bot';
import { TextPayload } from './text-bot';

export interface BotPayload {
    item: TextPayload | CardPayload | QuickReplyPayload;
    user: {
        userType: "bot" | "human";
    };
    notification: {
        notificationType: any,
        hour: string
    };
}

interface HandleBotPayload {
    botPayload: BotPayload[];
    rrss: "telegram" | "facebook" | "instagram" | "web" | "whatsapp";
}

const HandleBotConversationPayload = ({ botPayload, rrss }: HandleBotPayload) => (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {botPayload.map((bot, i) => (
            <BotMessage key={i} {...bot} rrss={rrss.toLowerCase()} />
        ))}
    </div>
);


export default HandleBotConversationPayload;
