import React, { useContext, useLayoutEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CCollapse, CContainer, CImage, CRow, CSmartTable } from '@coreui/react-pro'
import { LoginService } from '../../../core/services/login/login-service';
import { LoaderContext } from '../../components/loader/context/LoaderContext';
import SearchTable from '../../components/@filters/searchTable/SearchTable';
import { Pagination } from '../../../core/models/pagination';
import { useNavigate } from 'react-router-dom';
import { Item } from '@coreui/react-pro/dist/components/table/types';
import moment from 'moment';
import GKDateRangePicker from '../../components/@filters/dateRangePicker/DateRangePicker';
import { START_DATE_DEFAULT, END_DATE_DEFAULT } from '../../components/@filters/dateRangePicker/custon-ranges';
import { UserSocialMedia } from '../../../core/models/db/user-social-media';
import { useUserSocialMedia } from '../../../core/hooks/db/user-social-media/useUserSocialMedia';
import { cibTelegram, cibFacebook, cibDotNet, cilMonitor, cibWhatsapp, cibInstagram, cibProbot, cilMoodBad } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { Util } from '../../../core/util/util';
import useWindowDimensions from '../../../core/hooks/window-dimensions/userWindowDimensions';


const INIT_PAGINATION: Pagination = { page: 1, itemsPerPage: 10 };
const INIT_ALL_ROW: Pagination = { page: 1, itemsPerPage: 0 };

const LABEL_PAGE = "Usuarios del bot"


const UsuariosSocialMedia = () => {

    const { getUserSocialMedia } = useUserSocialMedia();
    const { width, height } = useWindowDimensions();

    const navigate = useNavigate();

    const [body, setBody] = useState<UserSocialMedia[]>([]);
    const [reload, setReload] = useState<boolean>(true);


    const { handleLoading, changeStyle } = useContext(LoaderContext);

    const [token, setToken] = useState(null);

    const [pagination, setPagination] = useState<Pagination>({
        ...INIT_PAGINATION,
        // startDate: moment(START_DATE_DEFAULT).format("YYYY-MM-DD"),
        // endDate: moment(END_DATE_DEFAULT).format("YYYY-MM-DD")
    });

    useLayoutEffect(() => {

        setReload(false);

        if (reload) {
            /**
             * Cuando se inicia la página o se hace algún cambio en el
             * paginado
             */
            initPage();
        }
    }, [reload]);


    const initPage = (paginationDefault = pagination) => {
        (async () => {
            changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
            handleLoading(true);

            const token = await LoginService.getTokenAsync();
            setToken(token)

            const response = await getUserSocialMedia(token, paginationDefault);
            if (response && response.ok) {
                let rows = response.item.rows;
                for (let i = 0; i < response.item.rows.length; i++) {
                    rows[i].priv_countConversation =
                        rows[i]?.conversations
                            ? rows[i]?.conversations.length
                            : 0;

                    // Hay que agregar estos dos para añadir los checks
                    rows[i].priv_check = "";
                    // row
                }

                setBody(rows);
                setPagination({
                    ...paginationDefault,
                    totalItems: response.item.totalItems,
                    totalPages: response.item.totalPages,
                })

                setTimeout(() => {
                    handleLoading(false)
                }, 200)
            } else {
                console.warn("Ha ocurrido un problema al devolver los usuarios")
            }
        })()
    }

    const handleDate = (startDate: string, endDate: string) => {
        if (startDate && endDate) {
            setPagination({
                ...pagination,
                page: 1,
                startDate,
                endDate
            })
            setReload(true);

        }
    }

    /**
     * Buscador
     * @param value 
     */
    const handleMessage = (value: string) => {
        setPagination({
            ...pagination,
            page: 1,
            wordList: value
        })
        setReload(true);

    }

    /**
    * Buscador con chips
    * @param value 
    */
    const handleMessageList = (value: string[]) => {
        setPagination({
            ...pagination,
            page: 1,
            wordList: value.join(",")
        })
        setReload(true);

    }

    const [details, setDetails] = useState([])
    const toggleDetails = (index) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
            newDetails.splice(position, 1)
        } else {
            newDetails = [...details, index]
        }
        setDetails(newDetails)
    }

    return (
        <CCard className="mb-4">
            <CCardHeader>
                <CRow>
                    <CCol xs={8} md={4} xl={4}>
                        <h4 id="title-page" className="card-title mb-0">
                            {LABEL_PAGE}
                        </h4>
                        <div className="small text-medium-emphasis">Listado</div>
                    </CCol>
                    <CCol sm={8} className="d-none d-md-block">
                        {/* <Link to={'/dashboard/usuarios/crear'} >
                            <CButton color="primary" className="float-end"  >
                                <CIcon icon={cilPlus} />
                            </CButton>
                        </Link> */}
                        <div className="col-4 float-end me-3">
                            <SearchTable
                                handleMessageList={handleMessageList}
                                handleMessage={handleMessage}
                                disabled={false}
                                multiWord={true} />
                        </div>

                    </CCol>


                </CRow>
            </CCardHeader>

            <CCardBody>
                <CRow>
                    <CCol>

                        {/* No hay información */}
                        {!body || body.length === 0 && (
                            <CContainer>
                                <div className="row justify-content-md-center">
                                    <CCol md="auto">
                                        <div style={{ fontSize: 20 }} className='d-flex justify-content-center align-items-center'>
                                            <span>No hay información <CIcon size='lg' icon={cilMoodBad} /></span>
                                        </div>
                                    </CCol>
                                </div>

                            </CContainer>
                        )}



                        {body && body.length > 0 && pagination && pagination.totalPages && (
                            <div className={width < 780 ? 'table-responsive' : ''}>
                                <CSmartTable
                                    items={body}
                                    columns={[
                                        {
                                            key: "idUserPlatform",
                                            label: "Identificador"
                                        },
                                        {
                                            key: "platform",
                                            label: "Plataforma",
                                            _style: { width: '15%' },
                                        },
                                        {
                                            key: "priv_countConversation",
                                            label: "Chats",
                                            sorter: false,
                                            _style: { width: '95px', textAlign: "right" },

                                        },
                                        {
                                            key: "createdDate",
                                            label: "Primer uso",

                                        },
                                        {
                                            key: "updatedDate",
                                            label: "Último uso",

                                        },
                                        // {
                                        //     key: 'show_details',
                                        //     label: '',
                                        //     _style: { width: '5%' },
                                        //     filter: false,
                                        //     sorter: false,
                                        //     _props: { className: 'fw-semibold' },
                                        // },
                                    ]}
                                    scopedColumns={{
                                        platform: (item) => {

                                            switch (item.platform) {
                                                case "TELEGRAM":
                                                    return (
                                                        <td className="d-flex flex-justify-content">
                                                            <div className='mx-2'>
                                                                <CIcon icon={cibTelegram} className="mr-2" size="xl" style={{ color: '#3b9eeb' }} />
                                                            </div>
                                                            <span>{Util.capitalize(item.platform)}</span>
                                                        </td>
                                                    )
                                                case "FACEBOOK":
                                                    return (
                                                        <td className="d-flex flex-justify-content">
                                                            <div className='mx-2'>
                                                                <CIcon icon={cibFacebook} className="mr-2" size="xl" style={{ color: '#10588f' }} />
                                                            </div>
                                                            <span>{Util.capitalize(item.platform)}</span>
                                                        </td>
                                                    )
                                                case "WEB":
                                                    return (
                                                        <td className="d-flex">
                                                            <div className='mx-2'>
                                                                <CIcon icon={cibProbot} size="xl" style={{ color: '#e9111f' }} />
                                                            </div>
                                                            <span>{Util.capitalize(item.platform)}</span>
                                                        </td>
                                                    )
                                                case "WHATSAPP":
                                                    return (
                                                        <td className="d-flex flex-justify-content">
                                                            <div className='mx-2'>
                                                                <CIcon icon={cibWhatsapp} className="mr-2" size="xl" style={{ color: '#1dbf22' }} />
                                                            </div>
                                                            <span>{Util.capitalize(item.platform)}</span>
                                                        </td>
                                                    )
                                                case "INSTAGRAM": {
                                                    return (
                                                        <td className="d-flex flex-justify-content">
                                                            <div className='mx-2'>
                                                                <CIcon icon={cibInstagram} className="mr-2" size="xl" style={{ color: '#de124f' }} />
                                                            </div>
                                                            <span>{Util.capitalize(item.platform)}</span>
                                                        </td>
                                                    )
                                                }
                                                default:
                                                    return (<span></span>)
                                            }
                                        },
                                        idUserPlatform: (item) => (
                                            <td>
                                                <div className="d-flex flex-justify-content">
                                                    # <span className='mx-2' >{item.idUserPlatform}</span>
                                                </div>
                                            </td>
                                        ),
                                        createdDate: (item) =>
                                            (<td> {item.createdDate ? moment.utc(item.createdDate).format("DD/MM/YYYY") : 'Fecha no registrada'}</td>),
                                        updatedDate: (item) =>
                                            (<td> {item.createdDate ? moment.utc(item.updatedDate).format("DD/MM/YYYY") : 'Fecha no registrada'}</td>),
                                        priv_countConversation: (item) => (
                                            <td >
                                                <div className="d-flex justify-content-end">{item.priv_countConversation}</div>
                                            </td>
                                        )

                                        // show_details: (item) => {
                                        //     return (
                                        //         <td className="py-2">
                                        //             <CButton
                                        //                 color="primary"
                                        //                 variant="outline"
                                        //                 shape="square"
                                        //                 size="sm"
                                        //                 onClick={() => {
                                        //                     toggleDetails(item.id)
                                        //                 }}
                                        //             >
                                        //                 {details.includes(item.id) ? 'Ocultar' : 'Mostrar'}
                                        //             </CButton>
                                        //         </td>
                                        //     )
                                        // },
                                        // details: (item, number) => {
                                        //     return (
                                        //         <CCollapse visible={details.includes(item.id)}>
                                        //             <CCardBody className="p-3">
                                        //                 <h4>{item.title}</h4>
                                        //                 <p className="text-title">
                                        //                     {moment(item.createdDate).format("DD/MM/YYYY")}
                                        //                 </p>
                                        //                 {item.userSocialMedias && (
                                        //                     <table style={{ width: '50%', borderCollapse: 'collapse' }}>
                                        //                         <tbody>
                                        //                             <tr>
                                        //                                 <td style={{ fontWeight: 'bold' }}>Plataforma</td>
                                        //                                 <td style={{ fontWeight: 'bold' }}>Usuarios</td>
                                        //                             </tr>
                                        //                             <tr>
                                        //                                 <td style={{ color: '#999' }}>{item.userSocialMedias.platform}</td>
                                        //                                 <td style={{ color: '#999' }}>{item.userSocialMedias.username}</td>
                                        //                             </tr>
                                        //                         </tbody>
                                        //                     </table>

                                        //                 )}
                                        //                 <div style={{ marginTop: 50 }}>
                                        //                     <div className="clearfix">
                                        //                         <h5>Información proveniente del jsonLead</h5>
                                        //                         <pre>{item.jsonLead}</pre>
                                        //                         <CImage rounded thumbnail src="https://previews.123rf.com/images/ccs0xx01/ccs0xx011504/ccs0xx01150400002/39059726-isometrical-construction-site-icon.jpg" width={200} height={200} />
                                        //                     </div>

                                        //                 </div>
                                        //             </CCardBody>
                                        //         </CCollapse>
                                        //     )
                                        // },
                                    }}

                                    // columnFilter={{
                                    //     external: true
                                    // }}

                                    columnSorter={{
                                        external: true
                                    }}

                                    pagination
                                    clickableRows
                                    itemsPerPageSelect
                                    itemsPerPageLabel={"Registros por página"}
                                    onRowClick={(item: Item, index: number, columnName: string, event) => {
                                        let target = typeof event !== 'boolean' ? event.nativeEvent.target as HTMLElement : event;
                                        let value = typeof target === 'boolean' ? target : target.tagName;

                                        /** Significa que se ha clicado en el check (INPUT) */
                                        if (typeof target !== 'boolean' && value === "INPUT") {
                                            //    Clica al check
                                        } else if (typeof target !== 'boolean' && value === "TD") {
                                            //    Console ir al detalle
                                        }
                                    }}

                                    onSelectAll={() => {
                                        console.log("calling all of ids")
                                    }}
                                    onSelectedItemsChange={(items) => {
                                        console.log("items that you selected", items)
                                    }}


                                    onSorterChange={(value) => {
                                        console.log("what is value?", value)
                                        if (value.state === 0) {
                                            setPagination({
                                                ...pagination,
                                                relatedTableOrder: null,
                                                order: null,
                                                orderBy: null
                                            })

                                        } else {
                                            setPagination({
                                                ...pagination,
                                                relatedTableOrder: null,
                                                order: value.state,
                                                orderBy: value.column
                                            })
                                        }

                                        setTimeout(() => {
                                            setReload(true);
                                        }, 100)

                                    }}


                                    paginationProps={{
                                        activePage: pagination.page,
                                        pages: pagination.totalPages,
                                        limit: 4,
                                        onActivePageChange: (activePage: number) => {
                                            console.log(activePage)
                                            setPagination({
                                                ...pagination,
                                                page: activePage,
                                            })
                                            setReload(true);

                                        }
                                    }}

                                    onItemsPerPageChange={(currItemsPerPage) => {
                                        const { page, totalItems } = pagination;

                                        const totalPages = Math.floor(totalItems / currItemsPerPage) + 1;
                                        const newPositionPage = Math.min(totalPages, page);
                                        setPagination({
                                            ...pagination,
                                            page: newPositionPage,
                                            itemsPerPage: currItemsPerPage
                                        })
                                        setReload(true);

                                    }}
                                />
                            </div>
                        )}
                    </CCol>



                </CRow>

            </CCardBody>
            {/* <CCardFooter>
        <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
          Footer
        </CRow>
      </CCardFooter> */}
        </CCard >

    )
}

export default UsuariosSocialMedia



