/**
 * A medida que se vayan necesitando nuevos tipos esta lista se va rellenando con ellos
 */
export const MimeDDTypes = [
    { type: "application/pdf", extension: " .pdf" },
    { type: "image/jpg", extension: " .jpg" },
    { type: "image/jpeg", extension: " .jpeg" },
    { type: "image/png", extension: " .png" },
    { type: "image/gif", extension: " .gif" },
    { type: "image/*", extension: " .jpg, .gif, .png" },
    { type: "video/mp4", extension: " .mp4" },
    { type: "video/ogg", extension: " .ogg" },
    { type: "video/webm", extension: " .webm" },
    { type: "video/avi", extension: " .avi" },
    { type: "video/quicktime", extension: " .mov" },
    { type: "video/x-ms-wmv", extension: " .wmv" },
    { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", extension: " .docx" },
    { type: "application/msword", extension: " .doc" },
    { type: "application/vnd.ms-excel", extension: " .xls, .xlt, xla" },
    { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", extension: " .xlsx" },
    { type: "text/csv", extension: " .csv" },
    { type: "application/vnd.openxmlformats-officedocument.presentationml.presentation", extension: " .pptx" },
    { type: "application/vnd.ms-powerpoint", extension: " .ppt" },
]



/**
 * Tipos (mimes) permitidos. Usados en el drag and drop para que el usuario sepa que tipos puede añadir en él.
 * Se pueden incluir más de cara al futuro.
 */
export type TypeAlloweds =
    "application/pdf" |
    "image/jpg" |
    "image/jpeg" |
    "image/png" |
    "image/gif" |
    "image/*" |
    "video/mp4" |
    "video/ogg" |
    "video/webm" |
    "video/avi" |
    "video/quicktime" |
    "video/x-ms-wmv" |
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" |
    "application/msword" |
    "application/vnd.ms-excel" |
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" |
    "text/csv" |
    "application/vnd.openxmlformats-officedocument.presentationml.presentation" |
    "application/vnd.ms-powerpoint"



export const formatFileAllowedToExt = (mimeList: string[]) => {
    return mimeList.map(item => {
        return MimeDDTypes.find(mm => mm.type === item).extension
    });;
}