
import { useContext, useState, useEffect } from "react";
import { Pagination } from "../../core/models/pagination";
import DragDrop from "../components/drag-drop/DragDrop";
import React from "react";
import { FileItemObservableService } from "../components/drag-drop/services/rxJS/fileitem-observable.service";
import { CButton, CCard, CCardBody, CCardHeader, CCol, CContainer, CLoadingButton, CModalFooter, CMultiSelect, CRow, CWidgetStatsB, CWidgetStatsD } from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { hideActionBar, loadActionBar, showActionBar } from "../components/actionBar/actions/actionBar-action";
import { ActionBarReduxState } from "../components/actionBar/reducers/actionBar-reducer";
import { RootState } from "../../core/store/store";
import ModalCover from "../components/modalCover/ModalCover";
import { cibFacebook, cibLinkedin, cibMessenger, cibTelegram, cibTwitter, cibWhatsapp, cilCalendar, cilMoodBad } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CChart, CChartLine } from '@coreui/react-chartjs'
import { useConversation } from "../../core/hooks/db/conversation/useConversation";
import { LoginService } from "../../core/services/login/login-service";
import GKDateRangePicker from "../components/@filters/dateRangePicker/DateRangePicker";
import SearchTable from "../components/@filters/searchTable/SearchTable";
import { TestChart } from "../components/TestChart";
import { HomeBarChart } from "../components/@home/HomeBarChart/HomeBarChart";
import { TotalCard } from "../components/@home/TotalCard/TotalCard";
import { HomeLineChart } from "../components/@home/HomeLineChart/HomeLineChart";
import { ActionInfoCard } from "../components/@home/ActionCard/ActionInfoCard";
import FilterSelectDataComponent from "../components/@filters/FilterSelectData/FilterSelectDataComponent";
import { OptionFilterHome, OptionFilterHomeList, initialOptions } from "../components/@filters/FilterSelectData/model/initialOptionFSD2";
import { ConversationSearch } from "../../core/models/interfaces/conversation-search";
import { SimpleInfoCard } from "../components/@home/SimpleInfoCard/SimpleInfoCard";
import FlashCard from "../components/@home/FlashCard/FlashCard";

import '../components/@home/HomeBarChart/style-homebarchart.scss';

import moment from "moment";
import 'moment/locale/es';
import { LoaderContext } from "../components/loader/context/LoaderContext";
import { COLORS } from "../../core/constants/colors";
moment.locale('es');


const INIT_PAGINATION: Pagination = { page: 1, itemsPerPage: 10 };
const INIT_ALL_ROW: Pagination = { page: 1, itemsPerPage: 0 };

const LABEL_PAGE = "Selecciona fechas para filtrar"
const optionsSocialMedia = [
  {
    value: 'instagram',
    text: 'Instagram',
    selected: false
  },
  {
    value: 'whatsapp',
    text: 'Whatsapp',
    selected: false
  },
  {
    value: 'facebook',
    text: 'Facebook',
    selected: false
  },
  {
    value: 'Web',
    text: 'Web',
    selected: false
  },
  {
    value: 'telegram',
    text: 'Telegram',
    selected: false
  }
]

interface PlatformCount {
  total: number;
  notifications: number;
}

interface CountChatsResponse {
  INSTAGRAM: PlatformCount;
  FACEBOOK: PlatformCount;
  TELEGRAM: PlatformCount;
  WHATSAPP: PlatformCount;
  WEB: PlatformCount;

}

const HomePage = () => {


  const { getCountByPlatform, getConversationSpecific } = useConversation();

  // const [date, setDate] = useState<{ startDate: string, endDate: string }>(undefined);
  const [date, setDate] = useState<{ startDate: any, endDate: any }>({
    startDate: new Date(new Date(new Date().setDate(new Date().getDate() - 29)).setHours(0, 0, 0, 0)),
    endDate: new Date(new Date().setHours(23, 59, 59, 999))
  });
  
  const [counterSocialMedia, setCounterSocialMedia] = useState<CountChatsResponse>(undefined);

  const [socialMediaList, setSocialMediaList] = useState<{ value: any, text: any, selected: boolean }[]>(optionsSocialMedia);
  const { handleLoading, loading, changeStyle } = useContext(LoaderContext);

  /** Se usa para que no salga el mensaje de "No hay información" antes de clicar el botón de Buscar */
  const [firstTime, setFirstTime] = useState<boolean>(false);


  /** Para el filtro inferior */
  const [subFilter, setSubFilter] = useState<{
    countChat: number,
    countNotification: number,
    totalCountPartial: number,
    totalNotificationPartial: number,
    percentageNotification: number,
    percentageChat: number
  }>(undefined);


  useEffect(() => {
    search();


  }, [])



  const getMainChart = async (date: { startDate: any, endDate: any }, socialMedia = []) => {
    try {

      setSubFilter(undefined);
      setCounterSocialMedia(undefined);

      handleLoading(true);


      let token = await LoginService.getTokenAsync();
      const result = await getCountByPlatform(token, date, socialMedia);
      console.log(result)

      if (result && result.ok) {
        setCounterSocialMedia(result.item);
      } else {
        setCounterSocialMedia(undefined);
      }

      setTimeout(() => {
        handleLoading(false);
      })
    } catch (e) {
      console.error(e);
      handleLoading(false);
    }
  }


  const getSpecific = async (value: ConversationSearch) => {
    try {
      // setLoading(true);
      handleLoading(true);

      let token = await LoginService.getTokenAsync()
      const result = await getConversationSpecific(token, value);
      console.log("SPECIFIC:", result)

      if (result && result.ok) {
        let amountNotification: number = result.item.rows.reduce((prev, curr) => {
          console.log(JSON.parse(curr.parameters).brand)
          prev += curr.conversation.includes('"message":"notification"') ? 1 : 0
          return prev;
        }, 0);

        try {
          const { percentageQuantityChats, percentageQuantityNotifications, totalChats, totalNotifications } = calculateTotalsAndPercentages(counterSocialMedia, result.item.totalItems, amountNotification)

          // console.log(calculateTotalsAndPercentages(counterSocialMedia, result.item.totalItems, amountNotification))
          // console.log(calculateTotalsAndPercentages(counterSocialMedia, result.item.totalItems, amountNotification))
          // console.log(calculateTotalsAndPercentages(counterSocialMedia, result.item.totalItems, amountNotification))
          // console.log(calculateTotalsAndPercentages(counterSocialMedia, result.item.totalItems, amountNotification))
          // console.log(calculateTotalsAndPercentages(counterSocialMedia, result.item.totalItems, amountNotification))

          setSubFilter({
            countChat: totalChats,
            totalCountPartial: result.item.totalItems,
            countNotification: totalNotifications,
            totalNotificationPartial: amountNotification,
            percentageChat: +percentageQuantityChats,
            percentageNotification: +percentageQuantityNotifications
          })

          handleLoading(false);

        } catch (e) {
          console.error(e)
          handleLoading(false);
          setSubFilter(undefined)
        }

        // setCounterSocialMedia(result.item);
      } else {
        setSubFilter({
          countChat: 0,
          totalCountPartial: 0,
          countNotification: 0,
          totalNotificationPartial: 0,
          percentageChat: 0,
          percentageNotification: 0
        })
        handleLoading(false);

        // setCounterSocialMedia(undefined);
      }


    } catch (e) {
      console.error(e);
      handleLoading(false);

      setSubFilter(undefined)

      // setLoading(false);
    }
  }


  //////////////////

  const handleDate = (startDate, endDate) => {

    if (startDate && endDate) {
      setDate({ startDate, endDate })
    } else {
      setDate(undefined)
    }

  }

  const handleChangeSocialMedia = (selectedSocialMedias) => {
    setSocialMediaList(prevList => {
      return prevList.map(rrss => {
        const isSelected = selectedSocialMedias.some(selected => selected.value === rrss.value);
        return {
          ...rrss,
          selected: isSelected
        }
      });
    });

  }

  const handleOptionsChange = (options) => {
    console.log('Opciones seleccionadas:', options);
    if (options) {
      const searchParams: ConversationSearch = {
        page: 1,
        itemsPerPage: 10000000,
        location: socialMediaList && socialMediaList.length > 0 ? socialMediaList.filter(f => f.selected).map(item => item.value) : [],

        actionParams: options.action ? options.action : [],
        typeAppointmentParams: options.typeAppointment ? options.typeAppointment : [],
        attClientParams: options.attClient ? options.attClient : [],
        brandParams: options.brand ? options.brand : [],
        vehicleTypeParams: options.vehicleType ? options.vehicleType : [],
        createdDate: date ? date : undefined
      };

      console.log("what is search params", searchParams);
      // Ahora en lugar de actualizar el estado, llamar directamente a la función con los nuevos parámetros
      getSpecific(searchParams);
    } else {
      setSubFilter(undefined);

    }

  };

  const search = () => {
    if (!firstTime) {
      setFirstTime(true);
    }
    getMainChart(date, socialMediaList.filter(f => f.selected).map(item => item.value))
  }

  function calculateTotalsAndPercentages(data, quantityChats, quantityNotifications) {
    let totalChats = 0;
    let totalNotifications = 0;

    // Sumar todos los chats y notificaciones
    for (const platform of Object.values(data) as any) {
      totalChats += platform.total;
      totalNotifications += platform.notifications;
    }

    // Calcular los porcentajes de las cantidades proporcionadas
    const percentageQuantityChats = quantityChats && quantityChats > 0 ? ((quantityChats / totalChats) * 100).toFixed(2) : 0;
    const percentageQuantityNotifications = quantityNotifications && quantityNotifications > 0 ? ((quantityNotifications / totalNotifications) * 100).toFixed(2) : 0;

    return {
      totalChats,
      totalNotifications,
      quantityChats,
      percentageQuantityChats,
      quantityNotifications,
      percentageQuantityNotifications,
    };
  }




  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>
          <CRow>
            <CCol xs={8} md={8} xl={8}>
              <h4 id="title-page" className="card-title mb-0">
                {LABEL_PAGE}
              </h4>
              {/* <div className="small text-medium-emphasis">Listado</div> */}
            </CCol>
            <CCol sm={10} className="d-none d-md-block">
              {/* <Link to={'/dashboard/usuarios/crear'} >
                            <CButton color="primary" className="float-end"  >
                                <CIcon icon={cilPlus} />
                            </CButton>
                        </Link> */}
              <div className="col-4 float-end me-3">
              </div>
            </CCol>
          </CRow>
          <CRow>
            <div style={{ marginTop: 16 }}></div>
            <CCol>
              <GKDateRangePicker
                handleDate={handleDate}
                defaultStartDate={date?.startDate}
                defaultEndDate={date?.endDate}
              />
              <div style={{ marginTop: 30 }}></div>
            </CCol>
            <CCol>
              <CMultiSelect
                placeholder="Redes sociales..."
                selectAllLabel="Seleccionar todos"
                options={socialMediaList}
                onChange={handleChangeSocialMedia}
              />
            </CCol>

            <CContainer>
              <div className="row justify-content-md-center">
                <CCol md="auto">
                  <div style={{ fontSize: 20, marginBottom: 10 }} className='d-flex justify-content-center align-items-center'>
                    <CLoadingButton onClick={search} style={{ backgroundColor: COLORS.NIMO_CLARO }}>Buscar</CLoadingButton>
                  </div>
                </CCol>
              </div>
            </CContainer>
          </CRow>

        </CCardHeader>

      </CCard >
      {!counterSocialMedia && firstTime && !loading && (
        <CContainer>
          <div className="row justify-content-md-center">
            <CCol md="auto">
              <div style={{ fontSize: 20 }} className='d-flex justify-content-center align-items-center'>
                <span>No hay información <CIcon size='lg' icon={cilMoodBad} /></span>
              </div>
            </CCol>
          </div>
        </CContainer>
      )}

      {counterSocialMedia && (
        <>

          <CCol xl={12}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
              <div style={{ width: "95%" }}>

                <CRow>
                  {/* <HomeBarChart chartData={counterSocialMedia} /> */}

                  {/* <TotalCard chartData={counterSocialMedia} /> */}
                </CRow>

              </div>

            </div>
          </CCol>
          <div style={{ marginBottom: 30 }}></div>

          <CCol xl={12}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
              <div style={{ width: "70%" }}>

                <CRow>
                  <CRow>

                    <TotalCard chartData={counterSocialMedia as any} onlyChats={false} />
                    <div style={{ marginBottom: 15 }}></div>
                    <TotalCard chartData={counterSocialMedia as any} onlyChats={true} />
                    <div style={{ marginBottom: 30 }}></div>

                    <div className="chart-container">
                      <CCard >
                        <CCardBody>
                          <CRow>
                            <CCol xs={12} md={12} xl={12}>
                              <h4 id="traffic" className="card-title mb-0">
                                Chats por rama
                              </h4>
                              {date && date.startDate && date.endDate && (
                                <div className="small text-medium-emphasis" style={{ marginBottom: 15 }}>
                                  {moment(date.startDate).format('MMMM D, YYYY')} - {moment(date.endDate).format('MMM D, YYYY')}
                                </div>
                              )}
                            </CCol>
                            <HomeBarChart chartData={counterSocialMedia as any} />
                          </CRow>
                        </CCardBody>
                      </CCard >
                      <div style={{ marginBottom: 30 }}></div>
                    </div>



                    <ActionInfoCard chartData={counterSocialMedia as any} />



                  </CRow>
                </CRow>


              </div>

            </div>
            <div className="chart-container">

              {/* <pre>{JSON.stringify(subFilter, null, 2)}</pre> */}
              <div style={{ marginBottom: 30 }}></div>
              <div style={{ marginBottom: 30 }}></div>
              <CRow >

                <CCol xl={12}>


                  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>

                    <div style={{ width: "80%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                      <FilterSelectDataComponent
                        initialOptions={initialOptions}
                        onFilter={handleOptionsChange}
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: 30 }}></div>

                  <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>

                    <div style={{ width: "80%", display: "flex", alignItems: "center", justifyContent: "space-around" }}>


                      {
                        subFilter && (


                          <>
                            <FlashCard
                              label={"Chats"}
                              total={subFilter.countChat}
                              percentage={subFilter.percentageChat}
                              partialTotal={subFilter.totalCountPartial}
                              color="#ffff"
                            />

                            <FlashCard
                              label={"Notificaciones"}
                              total={subFilter.countNotification}
                              percentage={subFilter.percentageNotification}
                              partialTotal={subFilter.totalNotificationPartial}
                              color={COLORS.NIMO_CLARO}
                            />
                          </>

                        )
                      }
                    </div>
                  </div>


                </CCol>
              </CRow>
            </div>
            {/* <pre>{JSON.stringify(calculateTotals(counterSocialMedia, 10, 20), null, 2)}</pre> */}
          </CCol>
        </>
      )}
    </>
  )
}

export default HomePage
