import { CCard, CCardHeader, CCol, CCardBody, CRow } from '@coreui/react-pro';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { User } from '../../../core/models/db/user';
import { LoginService } from '../../../core/services/login/login-service';
import { LoaderContext } from '../../components/loader/context/LoaderContext';
import { SmtpConfiguration } from '../../../core/models/db/smtp-configuration';
import { useSnackbar } from '../../components/snackbar/hook/useSnackbar';
import { useSmtpConfiguration } from '../../../core/hooks/db/smtp-configuration/useSmtpConfiguration';
import SmtpForm from './SmtpForm';
import Snackbar from '../../components/snackbar/Snackbar';

const LABEL_PAGE = "Configuración SMTP"


const SmtpConfigurationFormDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const stateLocation = location.state;
    // const { id } = useParams();

    const { getSmtpConfiguration, addSmtpConfiguration, updateSmtpConfiguration } = useSmtpConfiguration();


    const [smtp, setSmtp] = useState<SmtpConfiguration>({
        name: "",
        host: "",
        port: "",
        type: "",
        user: "",
        password: "",
        priv_confirmPassword: "",
    })

    const { handleLoading, changeStyle } = useContext(LoaderContext);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [typeAction, setTypeAction] = useState<"add" | "update">(null);

    const [token, setToken] = useState(null);

    const { state, handleSnackbar } = useSnackbar({
        color: "",
        wait: 3000,
        message: "",
        changeState: false,
    })

    /**
  * Se usa al comienzo de cargar la página para rellenar la tabla
  */
    useEffect(() => {
        (async () => {
            changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
            handleLoading(true);
            const token = await LoginService.getTokenAsync();
            setToken(token)
            const path = location.pathname;

            //   if (path.includes("actualizar") && stateLocation && stateLocation.row) {
            //     let row: User = { ...stateLocation.row };
            //     row.password = "";
            //     row.priv_confirmPassword = "";
            //     delete row.token;
            //     delete row.roles;
            //     delete row.priv_role;
            //     delete row.createdDate;
            //     delete row.deletedDate;
            //     delete row.updatedDate;

            //     setUser(row);
            //     setTypeAction("update");
            //   } else if (path.includes("actualizar") && id) {
            //     let result = await getUserById(token, id)
            //     if (result && result.ok) {
            //       console.log("what is the result", result)
            //       let user = result.item;
            //       user.password = "";
            //       user.priv_confirmPassword = "";
            //       delete user.token;
            //       delete user.roles;
            //       delete user.createdDate;
            //       delete user.deletedDate;
            //       delete user.updatedDate;
            //       setUser(user);
            //       setTypeAction("update");
            //     } else {
            //       console.warn("No se encontró ningún id");
            //       navigate(BASE_ROUTE + ROUTE_NAME.usuariosCrear);
            //     }
            //   } else {
            // setTypeAction("add");
            //   }

            let result = await getSmtpConfiguration(token, { page: 1, itemsPerPage: 1 });
    
            if (result && result.ok && result.item.rows && result.item.rows.length > 0) {
                setSmtp({
                    ...result.item.rows[0],
                    priv_confirmPassword: ''
                })
                setTypeAction("update")
            } else if (result.item.rows && result.item.rows.length === 0) {
                setTypeAction("add")
            } else {

                console.warn("Ha ocurrido un problema al devolver los smtp")
            }

            handleLoading(false);
        })()
    }, []);


    /**
     * Crea el usuario
     * @param smtp 
     */
    const add = async (smtp: SmtpConfiguration) => {
        try {
            let item = { ...smtp };
            delete item.priv_confirmPassword;
            setLoadingButton(true);

            const result = await addSmtpConfiguration(token, item);
            if (result && result.ok && result.item) {
                handleSnackbar("El registro se ha creado", "success");
                setTimeout(() => {
                    setLoadingButton(false);
                    setSmtp({
                        ...result.item,
                        priv_confirmPassword: ''
                    })
                    setTypeAction('update');
                }, 1000)
            }

        } catch (e) {
            handleSnackbar("Ha habido un error al crear el registro", "success");
            setLoadingButton(false);
            console.error(e)
        }
    }

    /**
    * Crea el usuario
    * @param smtp 
    */
    const update = async (smtp: SmtpConfiguration) => {
        try {
            let u = { ...smtp };
            delete u.priv_confirmPassword;
            setLoadingButton(true);

            const result = await updateSmtpConfiguration(token, u);
            if (result && result.ok) {
                handleSnackbar("El registro se ha actualizado", "success");
                setTimeout(() => {
                    setLoadingButton(false);
                    setSmtp({
                        ...smtp,
                        priv_confirmPassword: ''
                    })
                }, 1000)
            }

        } catch (e) {
            handleSnackbar("Ha habido un error al crear el registro", "success");
            setLoadingButton(false);
            console.error(e)
        }
    }

    return (
        <CCard className="mb-4">
            <Snackbar {...state} />

            <CCardHeader>
                <CCol sm={5} >
                    <h4 id="title-page" className="mb-0">
                        {/* <span className='me-1'>
                            <Link to={'/dashboard/usuarios'} style={{ color: 'black' }}>
                                <CIcon icon={cilArrowLeft} />
                            </Link>
                        </span> */}
                        {LABEL_PAGE}
                    </h4>
                    {/* <div className="small text-medium-emphasis mx-4">Detalle</div> */}
                    <div className="small text-medium-emphasis">{typeAction === 'add' ? "Crear" : "Actualizar"}</div>

                </CCol>
            </CCardHeader>

            <CCardBody>
                <CRow>
                    {smtp && typeAction && (
                        <SmtpForm
                            loadingButton={loadingButton}
                            smtp={smtp}
                            typeAction={typeAction}
                            add={add}
                            update={update}
                        />
                    )}

                </CRow>
            </CCardBody>
            {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            Footer
          </CRow>
        </CCardFooter> */}
            <CRow>

            </CRow>
        </CCard>
    )
}

export default SmtpConfigurationFormDetail

