import { CBadge, CCol, CRow, CSpinner, CVirtualScroller } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import ConversationFilter from '../conversationFilter/ConversationFilter'
import '../styles.scss';
import { useConversation } from '../../../../core/hooks/db/conversation/useConversation';
import { LoginService } from '../../../../core/services/login/login-service';
import { Pagination } from '../../../../core/models/pagination';
import { Conversation } from '../../../../core/models/db/conversation';
import { UserSocialMedia } from '../../../../core/models/db/user-social-media';
import { cibTelegram, cibFacebook, cilMonitor, cibWhatsapp, cibInstagram, cibProbot } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { classNames } from '../../../utils/classes-css';
import moment from 'moment';
import { Util } from '../../../../core/util/util';
import { ConversationSearch } from '../../../../core/models/interfaces/conversation-search';



// const INIT_PAGINATION: Pagination = { page: 1, itemsPerPage: 15 };
const INIT_PAGINATION = { page: 1, itemsPerPage: 15 };

const NUM_FINAL = 3;


const choosePlatformIcon = (platform, userSocialMedia: UserSocialMedia, location) => {
  // console.log(platform)
  switch (platform) {
    case "TELEGRAM":
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            <CIcon icon={cibTelegram} className="mr-2" size="lg" style={{ color: '#3b9eeb' }} />
          </div>
          <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform}</span>
        </div>
      )
    case "FACEBOOK":
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            <CIcon icon={cibFacebook} className="mr-2" size="lg" style={{ color: '#10588f' }} />
          </div>
          <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform}</span>


        </div>
      )
    case "WEB":
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            <CIcon icon={cibProbot} size="lg" style={{ color: '#e9111f' }} />
          </div>
          {/* <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform} - {location}</span> */}
          <span style={{ fontSize: "11px" }}>{location}</span>
        </div>
      )
    case "WHATSAPP":
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            <CIcon icon={cibWhatsapp} className="mr-2" size="lg" style={{ color: '#1dbf22' }} />
          </div>
          <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform}</span>
        </div>
      )
    case "INSTAGRAM":
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            <CIcon icon={cibInstagram} className="mr-2" size="lg" style={{ color: '#de124f' }} />
          </div>
          <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform}</span>

        </div>
      )
    default:
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            {/* <CIcon icon={cibProbot} size="lg" style={{ color: '#e9111f' }} /> */}
          </div>
          <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform}</span>
        </div>
      )
  }
}

interface ConversationSideProps {

  conversationSelected(conversation: Conversation): void;
}

const ConversationSide = (props: ConversationSideProps) => {

  const { conversationSelected } = props;

  const { getConversation, getConversationSpecific } = useConversation();
  // const [pagination, setPagination] = useState<Pagination>(INIT_PAGINATION);
  const [pagination, setPagination] = useState<ConversationSearch>(INIT_PAGINATION);


  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [limitScroll, setLimitScroll] = useState<number>(0);


  const [isScrollBlocked, setScrollBlocked] = useState(false);

  useEffect(() => {
    // TODO: Por defecto tenia que buscase, pero no hace falta debido a que ya se activa el buscador
    // desde el componente filtro
    (async () => {
      // paginateConversation2();
    })();
  }, [])

  const paginateConversation2 = async (pagination: ConversationSearch = INIT_PAGINATION, reset = false) => {
    try {
      setLimitScroll(limitScroll => (pagination.itemsPerPage * pagination.page) - 9);

      if (reset) {
        // Reinicio la conversación mostrada
        conversationSelected(undefined);
      }

      setScrollBlocked(true);

      const token = await LoginService.getTokenAsync();
      // console.log("I am goint to start looking for...", pagination)
      const response = await getConversationSpecific(token, pagination);
      if (response && response.ok) {
        // console.log("what is result - conversation", response);

        if (conversations.length === 0 || reset) {
          setConversations([...response.item.rows]);
        } else {
          setConversations([...conversations, ...response.item.rows]);
        }

        setPagination({
          ...pagination,
          totalItems: response.item.totalItems,
          totalPages: response.item.totalPages,
        })
      }
    } catch (e) {
      console.error(e);
    } finally {
      setScrollBlocked(false);
    }
  }


  const infoBoxItem = (conversation: Conversation) => {
    let userSocialMedia: UserSocialMedia = conversation.userSocialMedias;

    let existNotification = false;
    let brand = undefined;
    let action = undefined;

    if (conversation && conversation.conversation && conversation.parameters) {
      const params = JSON.parse(conversation.parameters);
      brand = Util.capitalize(params?.brand);
      action = Util.capitalize(params?.action);

      // for (const payload of JSON.parse(conversation.conversation)) {
      //   // console.log(payload)
      //   const { item } = payload;
      //   if (item.message === "notification") {
      //     existNotification = true;

      //     if (item.params?.brand) {
      //       brand = Util.capitalize(item.params.brand);
      //     }

      //     if (item.params?.action) {
      //       action = Util.capitalize(item.params.action);
      //     }
      //   }
      // }

      existNotification = conversation.conversation.includes('"message":"notification"');
    }

    return (
      <div style={{ padding: '10px 0', display: 'flex', justifyContent: 'flex-start', width: "95%", }}
        onClick={() =>
          conversationSelected(conversation)}
      >
        {userSocialMedia && (
          <div style={{ display: 'flex', flexDirection: "column", width: "100%", }}>
            <div style={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>
              {/* {conversation.id} */}
              {choosePlatformIcon(userSocialMedia.platform, userSocialMedia, conversation.location)}
              <span style={{ fontSize: "11px", marginRight: "7px" }}>
                {conversation.createdDate ? moment(conversation.createdDate).format("DD/MM/YYYY HH:mm") : 'Fecha no registrada'}
              </span>
            </div>
            <div>
              {action && (
                <span className='m-1'><CBadge color="info" size={'sm'} shape="rounded-pill">{action}</CBadge></span>
              )}
              {brand && (
                <span className='m-1'><CBadge color="dark" size={'sm'} shape="rounded-pill">{brand}</CBadge></span>
              )}
              {existNotification && (
                <span className='m-1'><CBadge color="success" size={'sm'} shape="rounded-pill"><span>Notificación</span></CBadge></span>
              )}

              {/* Cuando no encuentra ningún dato para mostrar */}
              {!action && !brand && !existNotification && (
                <span className='m-1'><CBadge color="secondary" size={'sm'} shape="rounded-pill">Sin información</CBadge></span>

              )}
            </div>
          </div>
        )}
      </div>
    )
  }




  return (
    <div className="conversation-side-content" style={isScrollBlocked ? { overflowY: 'hidden' } : {}}>

      {isScrollBlocked && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // Puedes ajustar el color y la transparencia a tu gusto
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <CSpinner color="dark" />
        </div>
      )}

      <CRow>
        <CCol>
          <ConversationFilter search={paginateConversation2}></ConversationFilter>
          {/* <pre>{JSON.stringify(pagination, null, 2)}</pre> */}
          {/* Separador */}
          <div style={{ height: "50px" }}></div>

          <CRow>

            <CVirtualScroller
              visibleItems={10}
              onScroll={(currentItemIndex: number) => {
                // console.log("INDEX PAGE", currentItemIndex);
                // console.log("LIMIT PAGE", limitScroll);
                // console.log("***");
                if (currentItemIndex === limitScroll
                  && conversations.length < pagination.totalItems) {
                  // console.log("VOY A ENTRAR")
                  const { page } = pagination;
                  paginateConversation2({ ...pagination, page: page + 1 })
                } else {
                  // console.log("NO ENTRO")
                }
              }}>

              {conversations && conversations.map((conv, i) => {
                return (
                  <div key={i} className={classNames(`box-conversation-single`)}>
                    {/* <span>{conv.idUserSocialMediaFk} - {i}</span> */}
                    {infoBoxItem(conv)}
                  </div>
                )
              })}
            </CVirtualScroller>

          </CRow>
        </CCol>
      </CRow>
    </div>
  )
}

export default ConversationSide


