import React, { useState } from 'react'
import { Pagination } from '../../../models/pagination';
import { Configuration } from '../../../models/db/configuration';
import { ConfigurationService } from '../../../services/db/configuration/configuration.service';




export const useConfiguration = () => {

    const getConfigurationById = (token, id) => {
        return new Promise<any>((res, rej) => {
            ConfigurationService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getConfiguration = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            ConfigurationService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addConfiguration = (token, user: Configuration) => {
        return new Promise<any>((res, rej) => {
            ConfigurationService.add(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateConfiguration = (token, user: Configuration) => {
        return new Promise<any>((res, rej) => {
            ConfigurationService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }




    return {

        getConfigurationById,
        getConfiguration,
        addConfiguration,
        updateConfiguration,

        getById: getConfigurationById,
        get: getConfiguration,
        add: addConfiguration,
        update: updateConfiguration,
        autocomplete: getConfiguration,

    }
}