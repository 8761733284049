import { cilArrowLeft } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCard, CCardHeader, CCol, CCardBody, CRow } from "@coreui/react-pro";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { BASE_ROUTE, ROUTE_NAME } from "../../../core/constants/route_name";
import { useRole } from "../../../core/hooks/db/role/useRole";
import { Role } from "../../../core/models/db/role";
import { LoginService } from "../../../core/services/login/login-service";
import { LoaderContext } from "../../components/loader/context/LoaderContext";
import { useSnackbar } from "../../components/snackbar/hook/useSnackbar";
import RoleForm from "./RoleForm";
import Snackbar from "../../components/snackbar/Snackbar";


const LABEL_PAGE = "Roles"


const RoleFormDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateLocation = location.state;
  const { id } = useParams();

  const { getRoleById, addRole, updateRole } = useRole();

  const [role, setRole] = useState<Role>({
    title: "",
    description: ""
  })

  const { handleLoading, changeStyle } = useContext(LoaderContext);
  const [loadingButton, setLoadingButton] = useState(false);
  const [typeAction, setTypeAction] = useState<"add" | "update">(null);

  const [token, setToken] = useState(null);

  const { state, handleSnackbar } = useSnackbar({
    color: "",
    wait: 3000,
    message: "",
    changeState: false,
  })

  /**
* Se usa al comienzo de cargar la página para rellenar la tabla
*/
  useEffect(() => {
    (async () => {
      changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
      handleLoading(true);
      const token = await LoginService.getTokenAsync();
      setToken(token)
      const path = location.pathname;

      if (path.includes("actualizar") && stateLocation && stateLocation.row) {
        let row: Role = { ...stateLocation.row };
        delete row.users;
        delete row.priv_check;
        delete row.createdDate;
        delete row.deletedDate;
        delete row.updatedDate;

        setRole(row);
        setTypeAction("update");
      } else if (path.includes("actualizar") && id) {
        let result = await getRoleById(token, id)
        if (result && result.ok) {
          console.log("what is the result", result)
          let role = result.item;
          delete role.users;
          delete role.priv_check;
          delete role.createdDate;
          delete role.deletedDate;
          delete role.updatedDate;
          setRole(role);
          setTypeAction("update");
        } else {
          console.warn("No se encontró ningún id");
          navigate(BASE_ROUTE + ROUTE_NAME.usuariosCrear);
        }
      } else {
        setTypeAction("add");
      }

      handleLoading(false);
    })()
  }, []);


  /**
   * Crea el usuario
   * @param role 
   */
  const add = async (role: Role) => {
    try {
      let u = { ...role };
      setLoadingButton(true);
      console.log(u)
      const result = await addRole(token, u);
      if (result && result.ok) {
        handleSnackbar("El registro se ha creado", "success");
        setTimeout(() => {
          setLoadingButton(false);
          navigate(BASE_ROUTE + ROUTE_NAME.rol);
        }, 1600)
      } else {
        setLoadingButton(false);
        handleSnackbar("Hubo un problema al crear el registro", "error");
      }

    } catch (e) {
      setLoadingButton(false);

      handleSnackbar("Ha habido un error al crear el registro", "error");
      console.error(e)
    }
  }

  /**
  * Crea el usuario
  * @param role 
  */
  const update = async (role: Role) => {
    try {
      let u = { ...role };
      setLoadingButton(true);


      const result = await updateRole(token, u);
      if (result && result.ok) {
        handleSnackbar("El registro se ha actualizado", "success");
        setTimeout(() => {
          setLoadingButton(false);
          navigate(BASE_ROUTE + ROUTE_NAME.rol);
        }, 1600)
      } else {
        setLoadingButton(false);
        handleSnackbar("Hubo un problema al actualizar el registro", "error");
      }

    } catch (e) {
      handleSnackbar("Ha habido un error al actualizar el registro", "error");
      setLoadingButton(false);
      console.error(e)
    }
  }

  return (
    <CCard className="mb-4">
      <Snackbar {...state} />

      <CCardHeader>
        <CCol sm={5} >
          <h4 id="title-page" className="mb-0">
            <span className='me-1'>
              <Link to={'/dashboard/roles'} style={{ color: 'black' }}>
                <CIcon icon={cilArrowLeft} />
              </Link>
            </span>  {LABEL_PAGE}
          </h4>
          <div className="small text-medium-emphasis mx-4">{typeAction === "add" ? "Crear" : "Actualizar"}</div>
        </CCol>
      </CCardHeader>

      <CCardBody>
        <CRow>
    
          {role && typeAction && (
            <RoleForm
              loadingButton={loadingButton}
              role={role}
              typeAction={typeAction}
              add={add}
              update={update}
            />
          )}

        </CRow>
      </CCardBody>
      {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            Footer
          </CRow>
        </CCardFooter> */}
      <CRow>

      </CRow>
    </CCard>
  )
}

export default RoleFormDetail



