import React, { useEffect, useState } from 'react'
import { SmtpConfiguration } from '../../../core/models/db/smtp-configuration';
import { useFormik } from 'formik';
import validationSmtpConfigurationSchema from './scf_validator_yup';
import * as Yup from 'yup';
import { cilUser, cilEnvelopeClosed, cilLockLocked, cilInfo, cilFeaturedPlaylist } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CRow, CCol, CInputGroup, CInputGroupText, CFormInput, CAlert, CFormCheck, CFormLabel, CContainer } from '@coreui/react-pro';
import { Autocomplete } from '@mui/material';
import ButtonLoadingSucessForm from '../../components/buttonLoadingSucessForm/ButtonLoadingSucessForm';
import CheckPassword from '../../components/checkPassword/CheckPassword';
import DragDrop from '../../components/drag-drop/DragDrop';


interface SmtpFormProps {
    smtp: SmtpConfiguration;
    typeAction: "add" | "update";
    loadingButton?: boolean;

    add?: (smtp: SmtpConfiguration) => Promise<any>;
    update?: (smtp: SmtpConfiguration) => Promise<any>;
}


const SmtpForm = (props: SmtpFormProps) => {

    /**
  * Este fragmento es necesario porque el navegador rellena de manera
  * automática los inputs username y password. Después de intentarlo mediante
  * otros métodos se ha optado por el siguiente
  */
    const autocompleteOff = () => setTimeout(() => setAutoOff(false), 100)
    const [autoOff, setAutoOff] = useState(null);
    useEffect(() => {
        autocompleteOff()
    }, [])

    // Comienzo de la página
    // Comienzo de la página
    // Comienzo de la página

    const {
        smtp,
        typeAction,
        loadingButton,

        add,
        update,

    } = props;


    const [showPassword, setShowPassword] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: {
            ...smtp,
        },
        validateOnBlur: false,
        onSubmit: (value) => {
            console.log(value)
            if (typeAction === "add") {
                add(value);
            } else if (typeAction === "update") {
                update(value);
            }
        },
        validationSchema: Yup.lazy(values => validationSmtpConfigurationSchema(values, typeAction, touched))
    })

    useEffect(() => {
        // console.log("Activating smpt update");
        setValues(smtp)
    }, [smtp])

    const changeToBackup = (value: boolean) => {
        if (value) {
            setValues(
                {
                    ...smtp,
                }
            );
        }
    }

    const isEdit = (value: boolean) => {
        setDisabled(value);
    }

    return (
        <CRow >

            <form onSubmit={handleSubmit} >
                <CRow className="row justify-content-md-center">
                    {/* <pre> {JSON.stringify(smtp, null, 2)}</pre>
                    <pre> {JSON.stringify(values, null, 2)}</pre> */}
                    <CCol sm={6}>

                        <CCol>
                            {/* <CFormLabel htmlFor="email-smtp"><b>Email</b></CFormLabel> */}
                            <CInputGroup className="mb-4">

                                <CFormInput id="email-smtp"
                                    placeholder='Email'
                                    disabled={(disabled && typeAction !== "add") || loadingButton}
                                    type="text"
                                    {...getFieldProps("user")} />
                                <CInputGroupText>
                                    {/* <CIcon icon={cilEnvelopeClosed} /> */}
                                    Email
                                </CInputGroupText>
                            </CInputGroup>
                        </CCol>
                        <div style={{ height: "auto" }}>
                            {touched.user && errors.user && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'>{errors.user}</div>
                                </CAlert>
                            )}
                        </div>

                        {/* <CFormLabel htmlFor="email-smtp"><b>Host</b></CFormLabel> */}
                        <CInputGroup className="mb-3">
                            <CFormInput
                                id="host-smtp"
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                placeholder='Host'
                                {...getFieldProps("host")}
                            />
                            <CInputGroupText>
                                {/* <CIcon icon={cilFeaturedPlaylist} /> */}
                                Host
                            </CInputGroupText>
                        </CInputGroup>

                        <div style={{ height: "auto" }}>
                            {touched.host && errors.host && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'>{errors.host}</div>
                                </CAlert>
                            )}
                        </div>

                        <CCol>
                            {/* <CFormLabel htmlFor="port-smtp"><b>Puerto</b></CFormLabel> */}

                            <CInputGroup className="mb-4">

                                <CFormInput id="port-smtp"
                                    placeholder='Puerto'
                                    disabled={(disabled && typeAction !== "add") || loadingButton}
                                    type="number"
                                    {...getFieldProps("port")} />
                                <CInputGroupText>
                                    {/* <CIcon icon={cilEnvelopeClosed} /> */}
                                    Puerto
                                </CInputGroupText>
                            </CInputGroup>
                        </CCol>
                        <div style={{ height: "auto" }}>
                            {touched.port && errors.port && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'>{errors.port}</div>
                                </CAlert>
                            )}
                        </div>

                        <CCol>
                            {/* <CFormLabel htmlFor="name-smtp"><b>Nombre referencial</b></CFormLabel> */}
                            <CInputGroup className="mb-4">
                                <CFormInput id="name-smtp"
                                    placeholder='Nombre'
                                    disabled={(disabled && typeAction !== "add") || loadingButton}
                                    type="text"
                                    {...getFieldProps("name")} />
                                <CInputGroupText>
                                    {/* <CIcon icon={cilEnvelopeClosed} /> */}
                                    Nombre referencial
                                </CInputGroupText>
                            </CInputGroup>
                        </CCol>
                        <div style={{ height: "auto" }}>
                            {touched.name && errors.name && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'>{errors.name}</div>
                                </CAlert>
                            )}
                        </div>

                        {/* <CFormLabel htmlFor="pass-smtp"><b>Password</b></CFormLabel> */}
                        <CInputGroup className="mb-3">

                            <CFormInput
                                id="pass-smtp"
                                placeholder='Contraseña'
                                type="text" {...getFieldProps("password")}
                                readOnly={autoOff}
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                autoComplete="new-password" />
                            {/* <CFormInput
                                placeholder='Repetir contraseña'
                                type="password" {...getFieldProps("priv_confirmPassword")}
                                readOnly={autoOff}
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                            /> */}
                            <CInputGroupText>
                                {/* <CIcon icon={cilLockLocked} /> */}
                                Password
                            </CInputGroupText>
                        </CInputGroup>

                        <div style={{ height: "auto" }}>
                            {touched.password && errors.password && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'> {errors.password}</div>
                                </CAlert>
                            )}
                            {/*    {touched.priv_confirmPassword && errors.priv_confirmPassword && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'>{errors.priv_confirmPassword}</div>
                                </CAlert>
                            )}*/}
                        </div>



                        <CContainer style={{ marginTop: 50 }}>
                            <ButtonLoadingSucessForm
                                loadingButton={loadingButton}
                                className="d-flex d-row"
                                changeToBackup={changeToBackup}
                                onSubmit={handleSubmit}
                                setDisabled={isEdit}
                                typeAction={typeAction}
                                disabled={!isValid}
                            />
                        </CContainer>


                    </CCol>
                </CRow>
            </form >
        </CRow >
    )
}

export default SmtpForm
