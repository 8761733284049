
import React, { useContext, useEffect, useState } from 'react'
import ModalCover, { ModalCoverProps } from '../../modalCover/ModalCover'
import { RootState } from '../../../../core/store/store';
import { ActionBarOptions } from '../interfaces';
import { hideActionBar, resetCurrentPageActionBar } from '../actions/actionBar-action';
import { useDispatch, useSelector } from "react-redux";
import { useUser } from '../../../../core/hooks/db/user/useUser';
import { useRole } from '../../../../core/hooks/db/role/useRole';
import { TableNamesType } from '../../../../core/models/interfaces/interfaces';
import { AxiosResponse } from 'axios';
import { LoaderContext } from '../../loader/context/LoaderContext';

interface DeleteModalProps {
    table: TableNamesType;
    items: any[],
    handleModalDelete: (value?: boolean) => void;


    // interface ModalCover
    count?: number;
    show?: any;
    children?: any;
    title?: any;
    hasCloseButton?: any;
    hasSuccessButton?: any;
    hasCloseHeaderButton?: any;

}

const DeleteModal = (props: DeleteModalProps) => {
    const {
        table,
        handleModalDelete,
        items,

        // interface ModalCover
        count,
        show,
        children,
        title,
        hasCloseButton = true,
        hasSuccessButton = false,
        hasCloseHeaderButton = true,

    } = props;


    const { deleteUser } = useUser()
    const { deleteRole } = useRole()

    const dispatch = useDispatch();

    const [fnDelete, setFnDelete] =
        useState<(token: any, idList: string[] | number[], action: boolean) => Promise<AxiosResponse<any, any>> | null>(null);
    const [hasError, setHasError] = useState(false);
    const [labels, setLabels] = useState<{
        labelSigle: string,
        labelMany: string,
        titleModal: string
    }>({
        labelMany: "¿Quieres eliminar los registros elegidos?",
        labelSigle: "¿Quieres eliminar el registro elegidos?",
        titleModal: "Eliminar registro"
    });

    const { handleLoading } = useContext(LoaderContext);

    useEffect(() => {
        if (!fnDelete) {
            switch (table) {
                case "roles":
                    setLabels({
                        labelMany: "¿Quieres eliminar los roles elegidos?",
                        labelSigle: "¿Quieres eliminar el rol elegido?",
                        titleModal: "Eliminar rol"
                    })
                    setFnDelete(() => deleteRole);
                    break;
                case "users":
                    setLabels({
                        labelMany: "¿Quieres eliminar los usuarios elegidos?",
                        labelSigle: "¿Quieres eliminar el usuario elegido?",
                        titleModal: "Eliminar usuario"
                    })
                    setFnDelete(() => deleteUser);
                    break;
                default:
                    setHasError(true);
                    console.error("Mal uso del modal DELETE. Tabla recibida", table)
            }
        }
    }, [table])


    const successButton = async () => {

        try {
            handleLoading(true);
            const token = localStorage.getItem("token");
            let result = await fnDelete(token, items, true)
            console.log("[Modal Delete] - result is...", result)
        } catch (e) {
            console.error(e)
        } finally {
            handleModalDelete(false);
            setTimeout(() => {
                dispatch(resetCurrentPageActionBar());
            }, 100)
        }
    }

    return (
        <ModalCover
            show={!hasError ? show : false}

            title={labels.titleModal}
            hasCloseButton={true}
            hasSuccessButton={true}

            handleModal={handleModalDelete}
            fnSuccess={successButton}
        >
            <div>
                {count > 1
                    ? (
                        <span>{labels.labelMany}</span>
                    )
                    : (
                        <span>{labels.labelSigle}</span>
                    )
                }
            </div>
        </ModalCover>
    )
}

export default DeleteModal

