import { combineReducers, createStore } from 'redux';
import actionBarReducer from '../../app/components/actionBar/reducers/actionBar-reducer';


const rootReducer = combineReducers({
    actionBar: actionBarReducer,
    // myComponent: myReducer,
});


const store = createStore(rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export default store;