import { ActionBarOptions } from "../interfaces";

const SHOW_ACTION_BAR = 'SHOW_ACTION_BAR';
const HIDE_ACTION_BAR = 'HIDE_ACTION_BAR';
const LOAD_ACTION_BAR = 'LOAD_ACTION_BAR';
const LOAD_ITEM_ACTION_BAR = 'LOAD_ITEM_ACTION_BAR';
const RESET_PAGE_ACTION_BAR = 'RESET_PAGE_ACTION_BAR';

export const showActionBar = () => ({ type: SHOW_ACTION_BAR });
export const hideActionBar = () => ({ type: HIDE_ACTION_BAR });
export const loadActionBar = (options: ActionBarOptions) => ({
    type: LOAD_ACTION_BAR,
    payload: options
});

export const loadItemActionBar = (items: any[]) => ({
    type: LOAD_ITEM_ACTION_BAR,
    items
});

export const resetCurrentPageActionBar = () => ({ type: RESET_PAGE_ACTION_BAR });


