import { CSSProperties, useEffect, useState } from "react";

// interface LoaderProps {
//     isLoading?: boolean;
//     styleLoader?: CSSProperties;
//     classNameLoader?: string;
// }


export const useTableSearch = () => {

    const [message, setMessage] = useState('');
    const [updated, setUpdated] = useState<string[]>([]);

    const handleChange = (event) => {
        setMessage(event.target.value);
    }

    const handleKeyDown = (event, multiWord?: boolean) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            let array = [...updated];

            array.push(event.target.value)
            setUpdated(array);
            setMessage("")
        }

    }

    const handleDelete = (ind) => {
        let array = [...updated];
        array.splice(ind, 1);
        setUpdated(array);
    }

    return {
        message,
        updated,

        handleChange,
        handleKeyDown,
        handleDelete
    }
}