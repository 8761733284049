import { CCard, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import React, { useState } from 'react'
import ConversationSide from './conversationSide/ConversationSide'
import { Conversation } from '../../../core/models/db/conversation';
import './styles.scss';
import HandleBotConversationPayload from './components/HandleBotConversationPayload';


const ConversationPage = () => {
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [conversation, setConversation] = useState<Conversation>(undefined);


    return (
        <CCard style={{ overflow: 'hidden' }} className="mb-4">
            <CCardHeader>
                <CRow>
                    <CCol xs={6} md={6} xl={6}>
                        <h4 id="title-page" className="card-title mb-0">
                            Conversaciones
                        </h4>
                    </CCol>
                </CRow>
            </CCardHeader>



            <CRow>
                {/* <CCol className={sidebarVisible ? "sidebar-visible" : "sidebar-hidden"}> */}
                <CCol className={"sidebar-visible"}>

                    <div className="sidebar-content">
                        <ConversationSide conversationSelected={setConversation}></ConversationSide>
                    </div>
                </CCol>


                <CCol style={{ maxHeight: 820, overflow: 'auto', padding: 20, backgroundColor: "#fcf8ed" }}>

                    {/* <CRow>
                        <div>
                            <button onClick={() => setSidebarVisible(!sidebarVisible)}>
                                {sidebarVisible ? 'Ocultar' : 'Mostrar'}
                            </button>
                        </div>
                    </CRow> */}

                    {conversation && conversation.conversation && (
                        <>
                            {HandleBotConversationPayload({ rrss: conversation.location as any, botPayload: JSON.parse(conversation.conversation) as any })}
                        </>
                    )}



                </CCol>
            </CRow>

        </CCard>
    )
}

export default ConversationPage
