import moment from 'moment';
import React from 'react';
import { Util } from '../../../core/util/util';

/**
 * Para la información proveniente del json lead 
 * Usado en notificaciones (notification) y citas (appointment)
 */
const CustomerInformation = ({ jsonLead }) => {
    try {
        if (jsonLead) {
            let object = JSON.parse(jsonLead);
            return (
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <div style={{ flex: '1 0 20%', marginTop: '10px' }}>
                        <h4 style={{ color: '#4a4a4a' }}>Información del cliente</h4>
                        {object.name && <p><strong>Nombre:</strong> <span style={{ color: '#999' }}>{object.name}</span></p>}
                        {object.email && <p><strong>Email:</strong> <a href={`mailto:${object.email}`} target="_blank" style={{ color: '#999', textDecoration: 'underline #999' }}>{object.email}</a></p>}
                        {object.phoneNumber && <p><strong>Teléfono:</strong> <a href={`tel:${object.phoneNumber}`} target="_blank" style={{ color: '#999', textDecoration: 'underline #999' }}>{object.phoneNumber}</a></p>}
                        {object.zipCode && <p><strong>Código postal:</strong> <span style={{ color: '#999' }}>{object.zipCode}</span></p>}
                    </div>
                    <div style={{ flex: '1 0 20%', marginTop: '10px' }}>
                        <h4 style={{ color: '#4a4a4a' }}>Petición del cliente</h4>
                        {object.action && <p><strong>Acción:</strong> <span style={{ color: '#999' }}>{object.action ? Util.capitalize(object.action) : object.action}</span></p>}
                        {object.typeAppointment && <p><strong>Tipo de cita:</strong> <span style={{ color: '#999' }}>{object.typeAppointment ? Util.capitalize(object.typeAppointment) : object.typeAppointment}</span></p>}
                        {object.vehicleType && <p><strong>Tipo de vehículo:</strong> <span style={{ color: '#999' }}>{object.vehicleType}</span></p>}
                        {object.brand && <p><strong>Marca:</strong> <span style={{ color: '#999' }}>{object.brand}</span></p>}
                        {object.location && <p><strong>Ubicación:</strong> <span style={{ color: '#999' }}>{object.location}</span></p>}
                        {/*object.phoneLocation && <p><strong>Ubicación del teléfono:</strong> <span style={{color: '#999'}}>{object.phoneLocation}</span></p>*/}
                    </div>
                    <div style={{ flex: '1 0 20%', marginTop: '10px' }}>
                        <h4 style={{ color: '#4a4a4a' }}>Contacto</h4>
                        {object.attClient && <p><strong>Atención al cliente:</strong> <span style={{ color: '#999' }}>{object.attClient}</span></p>}
                        {/*object.rrss && <p><strong>Red social:</strong> <span style={{color: '#999'}}>{object.rrss}</span></p>*/}
                        {object.timeDate && <p><strong>Fecha y hora:</strong>  <span style={{ color: '#999' }}>
                            {object.timeDate.includes("-")
                                ? moment(object.timeDate).format("DD/MM/YYYY HH:mm")
                                : object.timeDate}
                        </span></p>}
                    </div>
                </div>
            )
        } else {
            return null;
        }
    } catch (e) {
        console.error(e)
        return null;
    }
}

export default CustomerInformation;
