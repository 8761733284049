

import { Role } from '../../../core/models/db/role';
import { useFormik } from 'formik';
import * as Yup from 'yup';



import ButtonLoadingSucessForm from '../../components/buttonLoadingSucessForm/ButtonLoadingSucessForm';
import validationRoleSchema from './rf_validator_yup';
import { CRow, CCol, CInputGroup, CInputGroupText, CFormInput, CAlert, CContainer } from '@coreui/react-pro';
import { useState, useEffect } from 'react';
import CIcon from '@coreui/icons-react';



interface RoleFormProps {
    role: Role;
    typeAction: "add" | "update";
    loadingButton?: boolean;

    add?: (role: Role) => Promise<any>;
    update?: (role: Role) => Promise<any>;
}



const RoleForm = (props: RoleFormProps) => {

    /**
     * Este fragmento es necesario porque el navegador rellena de manera
     * automática los inputs rolename y password. Después de intentarlo mediante
     * otros métodos se ha optado por el siguiente
     */
    const autocompleteOff = () => setTimeout(() => setAutoOff(false), 100)
    const [autoOff, setAutoOff] = useState(null);
    useEffect(() => {
        autocompleteOff()
    }, [])


    // Comienzo de la página
    // Comienzo de la página
    // Comienzo de la página

    const {
        role,
        typeAction,
        loadingButton,

        add,
        update,
    } = props;


    const [showPassword, setShowPassword] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: {
            ...role,
        },
        validateOnBlur: false,
        onSubmit: (value) => {
            console.log(value)
            if (typeAction === "add") {
                add(value);
            } else if (typeAction === "update") {
                update(value);
            }
        },
        validationSchema: Yup.lazy(values => validationRoleSchema(values, typeAction, touched))
    })


    /**
     * Si hay que mantener la pagina tras haber creado el registro, 
     * descomenta este trozo de código
     * @param value 
     */
    // useEffect(() => {
    //     console.log("Activating role update");
    //     setValues({
    //         ...role,
    //     })
    // }, [role])


    const changeToBackup = (value: boolean) => {
        if (value) {
            setValues({ ...role });
        }
    }

    const isEdit = (value: boolean) => {
        setDisabled(value);
    }

    return (

        <CRow>
            {/* <h4>Original</h4>
            <pre>
                {JSON.stringify(role, null, 2)}
            </pre>
            <h4> Modificado</h4>
            <pre>
                {JSON.stringify(values, null, 2)}
            </pre> */}
            <form onSubmit={handleSubmit}>
                <CRow className="row justify-content-md-center">

                    <CCol sm={6}>

                        <CInputGroup className="mb-3">
                            <CInputGroupText>
                                {/* <CIcon icon={cilRole} /> */}
                                Título
                            </CInputGroupText>
                            <CFormInput
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                placeholder='Título'
                                {...getFieldProps("title")}
                            />
                        </CInputGroup>

                        <div style={{ height: "auto" }}>
                            {touched.title && errors.title && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'> {errors.title}</div>
                                </CAlert>
                            )}
                        </div>


                        <CInputGroup className="mb-3">
                            <CInputGroupText>
                                {/* <CIcon icon={cilU} /> */}
                                Descripción
                            </CInputGroupText>
                            <CFormInput
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                placeholder='Descripción'
                                {...getFieldProps("description")}
                            />
                        </CInputGroup>

                        <div style={{ height: "auto" }}>
                            {touched.description && errors.description && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'> {errors.description}</div>
                                </CAlert>
                            )}
                        </div>


                        <CContainer style={{ marginTop: 50 }}>
                            <ButtonLoadingSucessForm
                                loadingButton={loadingButton}
                                className="d-flex d-row"
                                changeToBackup={changeToBackup}
                                onSubmit={handleSubmit}
                                setDisabled={isEdit}
                                typeAction={typeAction}
                                disabled={!isValid}
                            />
                        </CContainer>


                    </CCol>
                </CRow>
            </form >
        </CRow >


    )
}

export default RoleForm



