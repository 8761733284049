import * as Yup from 'yup';


const validationConfigurationSchema = (values, typeAction, touched) => Yup.object({
    title: Yup
        .string(),
    web: Yup
        .string(),
    socialMedial: Yup
        .string(),
    legalUrl: Yup
        .string(),
    notificationEmail: Yup
        .string()
        .email("Inserta un email válido"),
    instagram: Yup
        .string(),
    facebook: Yup
        .string(),
    twitter: Yup
        .string(),

});

export default validationConfigurationSchema;
