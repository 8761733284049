import * as Yup from 'yup';


const validationSmtpConfigurationSchema = (values, typeAction, touched) => Yup.object({
    password: Yup.string()
        // .min(6, "Debe de tener 6 caracteres o más")
        // .max(20, "Debe de tener 20 caracteres o menos")
        .test(
            'password',
            'La contraseña es necesaria',
            (value) => {
                if (typeAction === 'add' && (!value || !value.trim())) {
                    return false;
                } else {
                    return true;
                }
            }
        ),

    // priv_confirmPassword: Yup
    //     .string()
    //     .test('priv_confirmPassword', 'Confirma la contraseña',
    //         function (value) {
    //             const { password } = this.parent;
    //             console.log("look ak this, alfredo", password, value)
    //             if (!!password) {
    //                 if (value !== password) {
    //                     return false;   // Retornamos false en vez de un objeto de error
    //                 }
    //             }
    //             return true;
    //         }),


    name: Yup
        .string()
        .required("Agrega un nombre identificativo"),
    user: Yup
        .string()
        .email("Inserta un email válido")
        .required("Es necesario agregar un email"),
    host: Yup
        .string()
        .required("Agrega un host"),
    port: Yup
        .number()
        .required("Agrega un puerto"),

});

export default validationSmtpConfigurationSchema;
