
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store/store';
import { ActionBarOptions } from './interfaces';
import { cilTrash, cilLoopCircular } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { ActionBarReduxState } from './reducers/actionBar-reducer';
import { hideActionBar, loadActionBar } from './actions/actionBar-action';
import { useLocation } from 'react-router-dom';
import DeleteModal from './components/DeleteModal';
import { TableNamesType } from '../../../core/models/interfaces/interfaces';

// interface ActionBarProps { }

const ActionBar: React.FC<any> = () => {
    const show = useSelector((state: RootState) => state.actionBar.showActionBar);
    const options: ActionBarOptions = useSelector((state: RootState) => state.actionBar.options);

    const dispatch = useDispatch();
    const location = useLocation();


    const [showDelete, setShowDelete] = useState(false);

    /**
     * Muestra o coulta el modal
     * @param value 
     */
    const handleModalDelete = (value: boolean) => {
        setShowDelete(value);
    }

    useEffect(() => {
        /**
         * Fuerza a esconder el ActionBar tras cambiar de pantalla
         * y limpia las opciones
         */
        dispatch(loadActionBar({}));
        dispatch(hideActionBar());
    }, [location]);

    const generateButtons = (options: ActionBarOptions) => {
        const buttons = [];
        if (options?.delete) {
            buttons.push(<button key={'delete'} onClick={() => {
                setShowDelete(true)
            }} style={buttonStyle}><CIcon icon={cilTrash} size='lg' /></button>);
        }
        if (options?.change?.show) {
            buttons.push(<button key={'change'} style={buttonStyle}><CIcon icon={cilLoopCircular} size='lg' /> {options.change.text}</button>);
        }
        return buttons;
    };

    if (!show) {
        return null;
    }

    const actionBarStyle: React.CSSProperties = {
        animation: show ? "fadeIn 0.5s ease forwards" : "fadeOut 0.5s ease forwards",
        position: "absolute",
        bottom: "8px",
        left: "20px",
        right: "20px",
        height: "60px",
        borderRadius: "10px",
        backgroundColor: "white",
        boxShadow: "0px -2px 10px rgba(0,0,0,0.2)",
        padding: "10px",
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between" // updated to space-between to place items on the extremes
    };

    const buttonStyle: React.CSSProperties = {
        backgroundColor: "transparent", // transparent background
        border: "none",
        color: "red", // color of the icon
        padding: "10px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: 16,
        margin: "4px 2px",
        cursor: "pointer"
    };

    const countLabelStyle: React.CSSProperties = {
        padding: "10px",
        fontSize: "20px",
        color: "#333"
    };

    return (
        <div style={actionBarStyle}>
            {
                options && options?.count && (
                    <div style={countLabelStyle}><span>{options?.count}</span>
                        {options.labelCount
                            ? <span style={{ fontSize: 14, marginLeft: "3px" }}> {options.labelCount}</span>
                            : <span style={{ fontSize: 14, marginLeft: "3px" }}> {"Registros seleccionados"}</span>}
                    </div>)
            }
            {generateButtons(options)}
            {options?.delete && (
                <DeleteModal
                    table={options?.table}
                    items={options?.items}
                    count={options.count}
                    show={showDelete}
                    handleModalDelete={handleModalDelete}
                    title={'Delete Modal from ActionBar'} />
            )}
        </div>
    );
};

export default ActionBar;




