import * as Yup from 'yup';


const validationRoleSchema = (values, typeAction, touched) => Yup.object({
    title: Yup
        .string()
        .required("Es necesario un título"),
    description: Yup
        .string()
});

export default validationRoleSchema;
