function getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
}

export function isImage(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'bmp':
        case 'png':
            //etc
            return true;
    }
    return false;
}

export function isVideo(filename) {
    var ext = getExtension(filename);
    console.log("mira la extensión", ext)
    switch (ext.toLowerCase()) {
        case 'm4v':
        case 'avi':
        case 'mpg':
        case 'mp4':
        case "ogg":
        case "webm":
        case "avi":
        case "x-ms-wmv":
        case "mov":
            // etc
            return true;
    }
    return false;
}