
import { Route } from '../../../core/interfaces/routes/interfaces';
import LoginPage from './login/Login';



const ExternalPagesRoute: Route[] = [
    { path: "/login", to: "/login", name: "Login", Component: LoginPage }
]

export default ExternalPagesRoute;
