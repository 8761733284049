import React, { useContext, useEffect, useState } from 'react'
import { CCard, CCardBody, CCardFooter, CCardHeader, CCol, CProgress, CRow, CSmartTable } from '@coreui/react-pro'
import { useUser } from '../../../core/hooks/db/user/useUser';
import { LoginService } from '../../../core/services/login/login-service';
import { LoaderContext } from '../../components/loader/context/LoaderContext';
import { cilArrowLeft } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { User } from '../../../core/models/db/user';
import UserForm from './UserForm';
import { useSnackbar } from '../../components/snackbar/hook/useSnackbar';
import Snackbar from '../../components/snackbar/Snackbar';
import { BASE_ROUTE, ROUTE_NAME } from '../../../core/constants/route_name';


const LABEL_PAGE = "Usuarios del sistema"


const UserFormDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateLocation = location.state;
  const { id } = useParams();

  const { getUserById, addUser, updateUser, checkPassword, checkUsername } = useUser();

  const [user, setUser] = useState<User>({
    username: "",
    password: "",
    priv_confirmPassword: "",
    image: "",
    email: "",
    blocked: false,
    idRoleFk: undefined,
  })

  const { handleLoading, changeStyle } = useContext(LoaderContext);
  const [loadingButton, setLoadingButton] = useState(false);
  const [typeAction, setTypeAction] = useState<"add" | "update">(null);

  const [token, setToken] = useState(null);

  const { state, handleSnackbar } = useSnackbar({
    color: "",
    wait: 3000,
    message: "",
    changeState: false,
  })

  /**
* Se usa al comienzo de cargar la página para rellenar la tabla
*/
  useEffect(() => {
    (async () => {
      changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
      handleLoading(true);
      const token = await LoginService.getTokenAsync();
      setToken(token)
      const path = location.pathname;

      if (path.includes("actualizar") && stateLocation && stateLocation.row) {
        let row: User = { ...stateLocation.row };
        row.password = "";
        row.priv_confirmPassword = "";
        delete row.token;
        delete row.roles;
        delete row.priv_role;
        delete row.createdDate;
        delete row.deletedDate;
        delete row.updatedDate;

        setUser(row);
        setTypeAction("update");
      } else if (path.includes("actualizar") && id) {
        let result = await getUserById(token, id)
        if (result && result.ok) {
          console.log("what is the result", result)
          let user = result.item;
          user.password = "";
          user.priv_confirmPassword = "";
          delete user.token;
          delete user.roles;
          delete user.createdDate;
          delete user.deletedDate;
          delete user.updatedDate;
          setUser(user);
          setTypeAction("update");
        } else {
          console.warn("No se encontró ningún id");
          navigate(BASE_ROUTE + ROUTE_NAME.usuariosCrear);
        }
      } else {
        setTypeAction("add");
      }

      handleLoading(false);
    })()
  }, []);

  /**
   * Comprueba que el username está libre
   * @param id 
   * @param password 
   * @returns 
   */
  const handleCheckUsername = async (id, username) => {
    if (!token) {
      console.warn("No se ha proporcionado token");
      return;
    };

    const result = await checkUsername(token, id, username);
    if (result && result.ok) {
      return result.item;
    } else {
      return false;
    }
  }


  const handleCheckPassword = async (id, password) => {
    if (!token) {
      console.warn("No se ha proporcionado token");
      return;
    };
    const result = await checkPassword(token, id, password);
    if (result && result.ok) {
      return result.item;
    } else {
      return false;
    }
  }


  /**
   * Crea el usuario
   * @param user 
   */
  const add = async (user: User) => {
    try {
      let u = { ...user };
      delete u.priv_confirmPassword;
      setLoadingButton(true);
      if (!u.password || u.password === '') {
        u.password = "gk13!12";
      }

      const result = await addUser(token, u);
      if (result && result.ok) {
        handleSnackbar("El registro se ha creado", "success");

        setTimeout(() => {
          setLoadingButton(false);
          // setUser({
          //   ...result.item,
          //   password: "",
          //   priv_confirmPassword: ''
          // })
          navigate("/dashboard/usuarios");
        }, 1600)
      }

    } catch (e) {
      handleSnackbar("Ha habido un error al crear el registro", "success");
      setLoadingButton(false);
      console.error(e)
    }
  }

  /**
  * Crea el usuario
  * @param user 
  */
  const update = async (user: User) => {
    try {
      let u = { ...user };
      delete u.priv_confirmPassword;
      setLoadingButton(true);
      if (!u.password || u.password === '') {
        delete u.password;
      }

      const result = await updateUser(token, u);
      if (result && result.ok) {
        handleSnackbar("El registro se ha actualizado", "success");

        let identity = await LoginService.getIdentityAsync();
        if (u.id === identity.idUser) {
          localStorage.setItem("identity", JSON.stringify({
            ...identity,
            username: u.username,
            idRole: u.idRoleFk,
            avatar: u.image,
          }))
        }


        setTimeout(() => {
          setLoadingButton(false);
          // setUser({
          //   ...u,
          //   priv_confirmPassword: ''
          // })
          navigate("/dashboard/usuarios");
        }, 1600)
      }

    } catch (e) {
      handleSnackbar("Ha habido un error al crear el registro", "success");
      setLoadingButton(false);
      console.error(e)
    }
  }

  return (
    <CCard className="mb-4">
      <Snackbar {...state} />

      <CCardHeader>
        <CCol sm={5} >
          <h4 id="title-page" className="mb-0">
            <span className='me-1'>
              <Link to={'/dashboard/usuarios'} style={{ color: 'black' }}>
                <CIcon icon={cilArrowLeft} />
              </Link>
            </span>  {LABEL_PAGE}
          </h4>
          <div className="small text-medium-emphasis mx-4">{typeAction === "add" ? "Crear" : "Actualizar"}</div>
        </CCol>
      </CCardHeader>

      <CCardBody>
        <CRow>
          {user && typeAction && (
            <UserForm
              loadingButton={loadingButton}
              user={user}
              typeAction={typeAction}
              add={add}
              update={update}
              checkPassword={handleCheckPassword}
              checkUsername={handleCheckUsername}
            />
          )}

        </CRow>
      </CCardBody>
      {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            Footer
          </CRow>
        </CCardFooter> */}
      <CRow>

      </CRow>
    </CCard>
  )
}

export default UserFormDetail



