import React, { useState } from 'react'
import { Pagination } from '../../../models/pagination';
import { MyNotification } from '../../../models/db/notification';
import { NotificationService } from '../../../services/db/notification/notification.service';


export const useNotification = () => {


    const getNotification = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            NotificationService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addNotification = (token, user: MyNotification) => {
        return new Promise<any>((res, rej) => {
            NotificationService.add(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateNotification = (token, user: MyNotification) => {
        return new Promise<any>((res, rej) => {
            NotificationService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }




    return {

        getNotification,
        addNotification,
        updateNotification,

        get: getNotification,
        add: addNotification,
        update: updateNotification,
        autocomplete: getNotification,

    }
}