

import { CSpinner } from '@coreui/react-pro';
import React, { CSSProperties } from 'react'

interface LoaderProps {
  className?: string;
  style?: CSSProperties;
}


const Loader = ({ style, className }: LoaderProps) => {
  return (
    // <div style={style} className={`${className} loader-item`} >
    //   <h1>LOADER</h1>
    // </div >
    <div className={`loader-item`} >
      <CSpinner color="dark" />
    </div >

  )
}

export default Loader