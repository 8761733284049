import { Util } from "../../../../core/util/util";



export class FileItem {

    public readonly id?: string;
    public file?: File;
    public name?: string;
    public type?: string;
    /** Usado para mostrar el archivo en el programa */
    public pathAbsolute?: string;
    /** Usado para ser guardado en la base de datos */
    public pathRelative?: string;


    /**
     * Para saber si el archivo proviene de fuera del programa
     */
    public externalURL?: string;

    constructor(file?: File) {
        this.id = Util.getUniqueId(2);
        if (file) {
            this.file = file;
            this.name = file.name;
            this.type = file.type;
        }

    }

}