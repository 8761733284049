import { Suspense, useEffect, useMemo, useState, } from 'react'
/**
 * Antes usava BrowserRouter, pero se cambio por HashRouter para incoporar un # en las rutas
 */
import { BrowserRouter, Navigate, Route, Routes, HashRouter } from "react-router-dom";
import { AuthContext } from '../../core/contexts/AuthContext';
import { useAuth } from '../../core/hooks/useAuth';
import { LoaderContext } from '../components/loader/context/LoaderContext';
import { useLoader } from '../components/loader/hook/useLoader';
import Loader from '../components/loader/Loader';
// import LayoutPrincipal from '../layout/layout-principal/LayoutPrincipal';
import ExternalPagesRoute from '../pages/@external-pages/external-pages.routes';
import PagesRoute from '../pages/pages.routes';
import GuardRoute from '../utils/GuardRoute/GuardRoute';
import DefaultLayout from '../layout/DefaultLayout';
import { LoginService } from '../../core/services/login/login-service';
import { identity } from 'rxjs';

const BASE_ROUTE_DASHBOARD = "/dashboard";

function Navigation() {

    const { auth, identity, checkToken, logout } = useAuth({
        isAuth: (
            !!localStorage.getItem("token")
            && !!localStorage.getItem("identity")
        )
    });


    const { loading, style, className, handleLoading, changeStyle, changeClassName } = useLoader({
        isLoading: false
    })

    const valueAuthMemo = useMemo(
        () => ({
            auth,
            logout,
            checkToken
        }), [auth]);

    const valueLoaderMemo = useMemo(
        () => ({
            loading,
            handleLoading,
            changeStyle,
            changeClassName,
        }), [loading]);

    return (

        <AuthContext.Provider value={valueAuthMemo}>
            <LoaderContext.Provider value={valueLoaderMemo}>

                <HashRouter>

                    {loading && <Loader style={style} className={className} />}

                    {/* Rutas hijas de las páginas externas */}
                    <Routes>
                        <Route index element={<Navigate to={`${ExternalPagesRoute[0].to}`} replace />} />
                        {
                            ExternalPagesRoute.map((route, index) =>
                                <Route key={index} path={route.path} element={<route.Component />}
                                />
                            )
                        }
                    </Routes>

                    {/* Mientras carga las rutas lazy se mostrará este loading
                            Actualmente se está probando esta funcionalidad...
                        */}
                    <Suspense fallback={<div>Loading...</div>}>
                        {/* Rutas hijas del Dashboard */}
                        <Routes>

                            {/* <Route path={BASE_ROUTE_DASHBOARD} element={<LayoutPrincipal />}> */}
                            <Route path={BASE_ROUTE_DASHBOARD} element={<DefaultLayout />}>

                                <Route index element={<Navigate to={`${BASE_ROUTE_DASHBOARD}${PagesRoute[0].to}`} replace />} />
                                <Route path="/dashboard/*" element={<Navigate to={`${BASE_ROUTE_DASHBOARD}${PagesRoute[0].to}`} replace />} />
                                {
                                    PagesRoute.map((route, ind) => {
                                        // console.log("what is route?", route);
                                        return <Route key={ind} path={`${BASE_ROUTE_DASHBOARD}${route.path}`}
                                            element={
                                                <GuardRoute roles={route.role} hasAccess={auth} isAllowed={!!route.role.find(role => {
                                                    let item = localStorage.getItem("identity");
                                                    if (item) {
                                                        let identity = JSON.parse(item);
                                                        return role == identity.idRole
                                                    } else {
                                                        return null;
                                                    }
                                                })}>
                                                    <route.Component />
                                                </GuardRoute>
                                            } />
                                    })
                                }
                            </Route>

                        </Routes>
                    </Suspense>

                </HashRouter>


            </LoaderContext.Provider>
        </AuthContext.Provider>

    )
}

export default Navigation


