import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { Conversation } from '../../../models/db/conversation';
import { ConversationService } from '../../../services/db/conversation/conversation.service';
import { ConversationSearch } from '../../../models/interfaces/conversation-search';



export const useConversation = () => {

    const getAllIdConversation = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            ConversationService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }



    const getConversationById = (token, id) => {
        return new Promise<any>((res, rej) => {
            ConversationService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getConversation = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            ConversationService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getConversationSpecific = (token, search: ConversationSearch = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            ConversationService.getSpecific(token, search)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getCountChat = (token, date: { startDate: any, endDate: any }) => {
        return new Promise<any>((res, rej) => {
            ConversationService.getCountChat(token, date)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getCountByPlatform = (token, date: { startDate: any, endDate: any }, platforms: any[] = []) => {
        return new Promise<any>((res, rej) => {
            ConversationService.getCountChatByPlatform(token, date, platforms)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }



    const addConversation = (token, user: Conversation) => {
        return new Promise<any>((res, rej) => {
            ConversationService.add(token, { ...user })
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateConversation = (token, user: Conversation) => {
        return new Promise<any>((res, rej) => {
            ConversationService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const deleteConversation = (token, idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            ConversationService.delete(token, idList, action)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    return {
        getAllIdConversation,
        getConversationById,
        getConversationSpecific,
        getConversation,
        getCountChat,
        getCountByPlatform,
        addConversation,
        updateConversation,
        deleteConversation,

        get: getConversation,
        add: addConversation,
        update: updateConversation,
        delete: deleteConversation
    }
}