import React from 'react';
import { Util } from '../../../../core/util/util';

// Declare the type for chartData explicitly
type Action = {
    total: number,
    notifications: number
};

type ChartData = {
    [platform: string]: {
        total: number,
        notifications: number,
        actions: {
            [action: string]: Action
        }
    }
};

type Totals = {
    notifications: number,
    actions: {
        [action: string]: number
    },
    total: number
};

function calculateTotals(chartData: ChartData): Totals {
    const totals: Totals = {
        notifications: 0,
        actions: {},
        total: 0,
    };

    for (const platform of Object.values(chartData)) {
        totals.notifications += platform.notifications;
        totals.total += platform.total;

        for (const [action, actionData] of Object.entries(platform.actions)) {
            if (totals.actions[action]) {
                totals.actions[action] += actionData.total;
            } else {
                totals.actions[action] = actionData.total;
            }
        }
    }

    return totals;
}



export const TotalCard = ({ chartData, onlyChats }: { chartData: ChartData, onlyChats: boolean }) => {
    const totals = calculateTotals(chartData);
    let hasPercentage = true;

    return (
        <div className="d-flex flex-column flex-md-row justify-content-around align-items-center border rounded p-3 shadow-sm user-select-none">
            {!onlyChats && (
                <>
                    <div className="text-center">
                        <h3 className="h5">Chats totales</h3>
                        <div>{totals.total}</div>
                    </div>
                    <div style={{ borderLeft: '1px solid lightgrey', height: '70%' }}></div> {/* Separator line */}

                    <div className="text-center">
                        <h3 className="h5">Notificaciones</h3>
                        <div>{totals.notifications}</div>

                        {/* {hasPercentage && <div className="fs-6">{((totals.notifications / totals.total) * 100).toFixed(2)}%</div>} */}
                    </div>
                </>
            )}

            {Object.entries(totals.actions).map(([action, count], index) => {
                if (onlyChats) {
                    return (
                        <React.Fragment key={action}>
                            {index > 0 && (
                                <div style={{ borderLeft: '1px solid lightgrey', height: '70%' }}></div>
                            )}
                            <div className="text-center">
                                <h4 className="h6">{Util.capitalize(action)}</h4>
                                <div className="fs-6">{count}</div>
                                {hasPercentage && <div className="fs-6">{((count / totals.total) * 100).toFixed(2)}%</div>}
                            </div>
                        </React.Fragment>
                    )
                }

            })}
        </div>
    );
};