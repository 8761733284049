import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';

const isColorLight = (hexColor) => {
    const c = hexColor.substring(1);  // strip #
    const rgb = parseInt(c, 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >> 8) & 0xff;  // extract green
    const b = (rgb >> 0) & 0xff;  // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma > 160;
}

const FlashCard = ({ label, total, partialTotal, percentage, color = '#3d94f6' }) => {
    const isLight = isColorLight(color);
    const gradientColor = isLight ? 'white' : color;
    const barColor = isLight ? 'darkgray' : 'white';

    const id = `${label}`

    return (
        <div style={{
            userSelect: 'none',
            border: '1px solid lightgray',
            borderRadius: '10px',
            padding: '20px',
            marginBottom: '15px',
            minWidth: '340px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            background: `linear-gradient(${gradientColor}, ${color})`
        }}>
            <div
                style={{ display: 'flex', justifyContent: 'space-between', color: isLight ? 'black' : 'white' }}>
                <h2>{label}</h2>
                <h2>{partialTotal}</h2>
            </div>
            <p style={{ color: isLight ? 'black' : 'white' }}>Total: {total}</p>
            <div
                id={id}

                style={{
                    height: '10px',
                    width: '100%',
                    backgroundColor: '#e0e0de',
                    borderRadius: '50px',
                    position: 'relative'
                }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: `${percentage}%`,
                        backgroundColor: barColor,
                        borderRadius: 'inherit',
                        transition: 'width 2s'
                    }}>
                    <span style={{
                        color: 'black',
                        fontSize: '0.7rem',
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}>
                        {percentage}%
                    </span>
                </div>
            </div>

            <Tooltip style={{ width: 210, }} anchorSelect={`#${id}`}
                content={`${percentage}%`} place="bottom" />
        </div>
    );
};

export default FlashCard;
