import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { CButton, CButtonGroup, CCard, CCardBody, CCardFooter, CCardHeader, CCol, CContainer, CRow, CSmartTable } from '@coreui/react-pro'
import STableCUI from '../../components/sTableCUI/STableCUI'

import { useUser } from '../../../core/hooks/db/user/useUser';
import { Invoicehead } from '../../../core/models/db/invoicehead';
import { User } from '../../../core/models/db/user';
import { LoginService } from '../../../core/services/login/login-service';
import { LoaderContext } from '../../components/loader/context/LoaderContext';
import SearchTable from '../../components/@filters/searchTable/SearchTable';
import { Pagination } from '../../../core/models/pagination';
import { cilMoodBad, cilPlus } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Item } from '@coreui/react-pro/dist/components/table/types';
import { BASE_ROUTE, ROUTE_NAME } from '../../../core/constants/route_name';
import { useCheckTable } from '../../../core/hooks/check-table/useCheckTable';
import { Util } from '../../../core/util/util';
import { loadActionBar } from '../../components/actionBar/actions/actionBar-action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store/store';
import useWindowDimensions from '../../../core/hooks/window-dimensions/userWindowDimensions';
import { COLORS } from '../../../core/constants/colors';


const INIT_PAGINATION: Pagination = { page: 1, itemsPerPage: 10 };
const INIT_ALL_ROW: Pagination = { page: 1, itemsPerPage: 0 };

const CLASS_REGULAR_ROW = "check-reg-" + Util.getUniqueId(1);
const CLASS_PRINCIPAL_ROW = "check-prin-" + Util.getUniqueId(1);




const Users = () => {
  const dispatch = useDispatch();
  const reset: boolean = useSelector((state: RootState) => state.actionBar.reset);


  const { getUser, getAllIdUser } = useUser();
  const { width, height } = useWindowDimensions();

  const navigate = useNavigate();


  const [body, setBody] = useState<User[]>([]);
  const [reload, setRealod] = useState<boolean>(true);

  const { handleLoading, changeStyle } = useContext(LoaderContext);

  const [token, setToken] = useState(null);
  const [identity, setIdentity] = useState(undefined);


  const [pagination, setPagination] = useState<Pagination>(INIT_PAGINATION);

  const { selected, handleSingleCheck, cleanSelected } = useCheckTable(
    {
      body,
      // itemsPerPage: pagination.itemsPerPage,
      pagination: pagination,

      reasonForDeactivation: [

      ],
      classToFindPrincipalRow: CLASS_PRINCIPAL_ROW,
      classToFindRegularRow: CLASS_REGULAR_ROW,
      getAllId: getAllIdUser
    });


  useLayoutEffect(() => {

    setRealod(false);

    if (reload) {
      /**
       * Cuando se inicia la página o se hace algún cambio en el
       * paginado
       */
      initPage();
    } else if (reset) {
      // Cuando se usa el ActionBar
      // Reiniciamos, dejamos los filtros tal como estaba exceptuando la página
      cleanSelected();
      initPage({
        ...pagination,
        page: 1,
      });
    }
  }, [reload, reset]);


  const initPage = (paginationDefault = pagination) => {
    (async () => {
      changeStyle({ backgroundColor: "#fff" })
      handleLoading(true);
      // Cargamos las acciones para el ActionBar



      const token = await LoginService.getTokenAsync();
      const id = await LoginService.getIdentityAsync();
      setIdentity(id);
      setToken(token)

      const response = await getUser(token, paginationDefault);
      if (response && response.ok) {
        let rows = response.item.rows;
        for (let i = 0; i < response.item.rows.length; i++) {
          rows[i].priv_role = rows[i]?.roles ? rows[i]?.roles.title : '';

          // Hay que agregar estos dos para añadir los checks
          rows[i].priv_check = "";
          rows[i]._props = { className: CLASS_REGULAR_ROW };
        }

        setBody(rows);
        setPagination({
          ...paginationDefault,
          totalItems: response.item.totalItems,
          totalPages: response.item.totalPages,
        })

        setTimeout(() => {
          dispatch(loadActionBar({ delete: id.idRole == 1, table: "users", count: selected.length, items: selected }));
          handleLoading(false)
        }, 200)
      } else {
        console.warn("Ha ocurrido un problema al devolver los usuarios")
      }
    })()
  }

  /**
   * Buscador
   * @param value 
   */
  const handleMessage = (value: string) => {
    setPagination({
      ...pagination,
      page: 1,
      wordList: value
    })
    setRealod(true);
  }

  /**
  * Buscador con chips
  * @param value 
  */
  const handleMessageList = (value: string[]) => {
    setPagination({
      ...pagination,
      page: 1,
      wordList: value.join(",")
    })
    setRealod(true);
  }

  const goToUpdate = (row: User) => {
    // Al editar solamente puede ir el administrador o el usuario
    if (identity && (identity.idRole == 1 || identity.id === row.id)) {
      navigate(BASE_ROUTE + ROUTE_NAME.usuariosActualizar + `/${row.id}`, { state: { row } })
    }
  }

  return (
    <CCard className="mb-4">
      <CCardHeader>
        <CRow>
          <CCol xs={6} sm={12} md={6} xl={6}>
            <h4 id="title-page" className="card-title mb-0">
              Usuarios del sistema
            </h4>
            <div className="small text-medium-emphasis">Listado</div>
          </CCol>
          <CCol sm={6} className="d-none d-md-block">
            <Link to={BASE_ROUTE + ROUTE_NAME.usuariosCrear} >
              <CButton color="primary" className="float-end" style={{ backgroundColor: COLORS.NIMO_CLARO }} >
                <CIcon icon={cilPlus} />
              </CButton>
            </Link>
            <div className="col-8 float-end me-3">
              <SearchTable
                handleMessageList={handleMessageList}
                handleMessage={handleMessage}
                disabled={false}
                multiWord={true} />
            </div>
          </CCol>


        </CRow>
      </CCardHeader>

      <CCardBody>
        <CRow>
          <CCol>

            {/* No hay información */}
            {!body || body.length === 0 && (
              <CContainer>
                <div className="row justify-content-md-center">
                  <CCol md="auto">
                    <div style={{ fontSize: 20 }} className='d-flex justify-content-center align-items-center'>
                      <span>No hay información <CIcon size='lg' icon={cilMoodBad} /></span>
                    </div>
                  </CCol>
                </div>

              </CContainer>
            )}



            {body && body.length > 0 && pagination && pagination.totalPages && (
              <div className={width < 780 ? 'table-responsive' : ''}>
                <CSmartTable
                  items={body}
                  columns={[
                    {
                      key: "priv_check",
                      label: "",
                      sorter: false,
                      filter: false,
                      _props: { className: CLASS_PRINCIPAL_ROW }
                    },
                    {
                      key: "username",
                      label: "Usuario",
                    },
                    {
                      key: "priv_role",
                      label: "Rol"
                    }]}

                  columnSorter
                  // selectable={true}
                  // selectAll={true}
                  // selected={[...body]}
                  pagination
                  clickableRows
                  itemsPerPageSelect
                  itemsPerPageLabel={"Registros por página"}
                  onRowClick={(item: Item, index: number, columnName: string, event) => {
                    let target = typeof event !== 'boolean' ? event.nativeEvent.target as HTMLElement : event;
                    let value = typeof target === 'boolean' ? target : target.tagName;

                    if (typeof target !== 'boolean' && value === "INPUT") {
                      //    Clica al check

                    } else if (typeof target !== 'boolean' && value === "TD") {
                      //    Console ir al detalle
                      if (selected && selected.length > 0) {
                        handleSingleCheck(null, item.id, item);
                      } else {
                        goToUpdate(item as User)
                      }
                    }
                  }}

                  onSelectAll={() => {
                    console.log("calling all of ids")
                  }}
                  onSelectedItemsChange={(items) => {
                    console.log("items that you selected", items)
                  }}


                  onSorterChange={(value) => {
                    console.log("what is value?", value)
                    if (value.state === 0) {
                      setPagination({
                        ...pagination,
                        relatedTableOrder: null,
                        order: null,
                        orderBy: null
                      })
                    } else if (value.column === "priv_role") {
                      setPagination({
                        ...pagination,
                        relatedTableOrder: "roles",
                        order: value.state,
                        orderBy: "title"
                      })
                    } else {
                      setPagination({
                        ...pagination,
                        relatedTableOrder: null,
                        order: value.state,
                        orderBy: value.column
                      })
                    }

                    setTimeout(() => {
                      setRealod(true);
                    }, 100)

                  }}


                  paginationProps={{
                    activePage: pagination.page,
                    pages: pagination.totalPages,
                    limit: 4,
                    onActivePageChange: (activePage: number) => {
                      console.log(activePage)
                      setPagination({
                        ...pagination,
                        page: activePage,
                      })

                      setRealod(true);
                    }
                  }}

                  onItemsPerPageChange={(currItemsPerPage) => {
                    const { page, totalItems } = pagination;

                    const totalPages = Math.floor(totalItems / currItemsPerPage) + 1;
                    const newPositionPage = Math.min(totalPages, page);
                    setPagination({
                      ...pagination,
                      page: newPositionPage,
                      itemsPerPage: currItemsPerPage
                    })
                    setRealod(true);
                  }}
                />
              </div>
            )}
          </CCol>



        </CRow>

      </CCardBody>
      {/* <CCardFooter>
        <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
          Footer
        </CRow>
      </CCardFooter> */}
    </CCard >

  )
}

export default Users



