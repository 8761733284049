export const customRangesDate = {
    "Para hoy": [
        new Date(new Date().setHours(0, 0, 0, 0)), 
        new Date(new Date().setHours(23, 59, 59, 999))],
    "Ayer": [
        new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0)),
        new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 999)),
    ].map(d => new Date(d)),
    'Últimos 7 días': [
        new Date(new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0, 0)),
        new Date(new Date().setHours(23, 59, 59, 999)),
    ].map(d => new Date(d)),
    'Últimos 30 días': [
        new Date(new Date(new Date().setDate(new Date().getDate() - 29)).setHours(0, 0, 0, 0)),
        new Date(new Date().setHours(23, 59, 59, 999)),
    ].map(d => new Date(d)),
    'Este mes': [
        new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)),
        new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 999)),
    ].map(d => new Date(d)),
    'Anterior mes': [
        new Date(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).setHours(0, 0, 0, 0)),
        new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 0).setHours(23, 59, 59, 999)),
    ].map(d => new Date(d)),
    'Último año': [
        new Date(new Date(new Date().setDate(new Date().getDate() - 364)).setHours(0, 0, 0, 0)),
        new Date(new Date().setHours(23, 59, 59, 999)),
    ].map(d => new Date(d)),
    'Este año': [
        new Date(new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)),
        new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).setHours(23, 59, 59, 999)),
    ].map(d => new Date(d)),

}

export const START_DATE_DEFAULT = new Date(customRangesDate['Este mes'][0]);
export const END_DATE_DEFAULT = new Date(customRangesDate['Este mes'][1]);
