import React from "react"

import { ROUTE_NAME } from "../../core/constants/route_name"
import { Auth, Route } from "../../core/interfaces/routes/interfaces"
import Appointments from "./appointment/Appointment"
import ConfigurationFormDetail from "./configuration/ConfigurationFormDetail"
import Notifications from "./notification/Notifications"
import RoleFormDetail from "./role/RoleFormDetail"
import Roles from "./role/Roles"
import SmtpConfigurationFormDetail from "./smtp-configuration/SmtpConfigurationFormDetail"
import UserFormDetail from "./user/UserFormDetail"
import Users from "./user/Users"
import UsuariosSocialMedia from "./usuario-social-media/UsuarioSocialMedia"
import ConversationPage from "./conversation/ConversationPage"
import HomePage from "./HomePage"

// import Conversation from "./conversation/Conversation"
// const Conversation = React.lazy(() => import('./conversation/ConversationPage'));


const PagesRoute: Route[] = [
    { path: ROUTE_NAME.home, to: ROUTE_NAME.home, name: "Home", Component: HomePage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: ROUTE_NAME.usuarios, to: ROUTE_NAME.usuarios, name: "Usuarios del sistema", Component: Users, role: [Auth.ROLE_ADMIN] },
    { path: ROUTE_NAME.usuariosCrear, to: ROUTE_NAME.usuariosCrear, name: "Crear Usuario del sistema", Component: UserFormDetail, role: [Auth.ROLE_ADMIN] },
    {
        path: ROUTE_NAME.usuariosActualizar + "/:id",
        to: ROUTE_NAME.usuariosActualizar + "/:id",
        name: "Actualizar usuario del sistema",
        Component: UserFormDetail,
        role: [Auth.ROLE_ADMIN],
    },


    { path: ROUTE_NAME.notificaciones, to: ROUTE_NAME.notificaciones, name: "Notificaciones", Component: Notifications, role: [Auth.ROLE_USER, Auth.ROLE_ADMIN] },
    { path: ROUTE_NAME.citas, to: ROUTE_NAME.citas, name: "Citas", Component: Appointments, role: [Auth.ROLE_USER, Auth.ROLE_ADMIN] },

    { path: ROUTE_NAME.conversaciones, to: ROUTE_NAME.conversaciones, name: "Conversaciones", Component: ConversationPage, role: [Auth.ROLE_USER, Auth.ROLE_ADMIN] },
    { path: ROUTE_NAME.usuariosSocialMedia, to: ROUTE_NAME.usuariosSocialMedia, name: "Usuarios del bot", Component: UsuariosSocialMedia, role: [Auth.ROLE_USER, Auth.ROLE_ADMIN] },


    { path: ROUTE_NAME.smtp, to: ROUTE_NAME.smtp, name: "Configuración SMTP", Component: SmtpConfigurationFormDetail, role: [Auth.ROLE_ADMIN] },

    { path: ROUTE_NAME.configuration, to: ROUTE_NAME.configuration, name: "Configuración", Component: ConfigurationFormDetail, role: [Auth.ROLE_ADMIN] },


    { path: ROUTE_NAME.rol, to: ROUTE_NAME.rol, name: "Roles", Component: Roles, role: [Auth.ROLE_USER, Auth.ROLE_ADMIN] },
    { path: ROUTE_NAME.rolCrear, to: ROUTE_NAME.rolCrear, name: "Crear Rol", Component: RoleFormDetail, role: [Auth.ROLE_USER, Auth.ROLE_ADMIN] },
    {
        path: ROUTE_NAME.rolActualizar + "/:id",
        to: ROUTE_NAME.rolActualizar + "/:id",
        name: "Actualizar rol",
        Component: RoleFormDetail,
        role: [Auth.ROLE_ADMIN],
    },
]


export default PagesRoute