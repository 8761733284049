import React, { useEffect, useState } from 'react'
import SearchTable from '../../../components/@filters/searchTable/SearchTable'
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CButton, CCard, CCardBody, CCol, CCollapse, CForm, CFormCheck, CFormInput, CInputGroup, CMultiSelect, CRow } from '@coreui/react-pro'
import { cilListFilter } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import GKDateRangePicker from '../../../components/@filters/dateRangePicker/DateRangePicker'
import ModalCover from '../../../components/modalCover/ModalCover'
import Autocomplete from '../../../components/autocomplete/Autocomplete'
import { useUserSocialMedia } from '../../../../core/hooks/db/user-social-media/useUserSocialMedia'
import { LoginService } from '../../../../core/services/login/login-service'
import { UserSocialMedia } from '../../../../core/models/db/user-social-media'
import { Pagination } from '../../../../core/models/pagination'
import { ConversationSearch } from '../../../../core/models/interfaces/conversation-search'
import moment from 'moment'


const actionOptions = [
  {
    value: 'compra',
    text: 'Compra',
    selected: false
  },
  {
    value: 'cita',
    text: 'Cita',
    selected: false
  },
  {
    value: 'renting',
    text: 'Renting',
    selected: false
  },
  {
    value: 'alquiler diario',
    text: 'Alquiler diario',
    selected: false
  },
  {
    value: 'horario y contacto',
    text: 'Horario y Contacto',
    selected: false
  },
  {
    value: 'tasar',
    text: 'Tasar vehículo',
    selected: false
  }
]


const brandOptions = [
  {
    value: 'Lexus',
    text: 'Lexus',
    selected: false
  },
  {
    value: 'Toyota',
    text: 'Toyota',
    selected: false
  }
]

const typeVehicleOptions = [
  {
    value: '"Nuevo',
    text: 'Nuevo',
    selected: false
  },
  {
    value: 'Seminuevo',
    text: 'Seminuevo',
    selected: false
  },
  {
    value: 'Ocasión',
    text: 'Ocasion',
    selected: false
  }
]

const attClientOptions = [
  {
    value: 'Llamada',
    text: 'Llamada',
    selected: false
  },
  {
    value: 'Videollamada',
    text: 'Videollamada',
    selected: false
  },
  {
    value: 'Whatsapp',
    text: 'Whatsapp',
    selected: false
  }
]

const carDealerOptions = [
  {
    value: 'Huelva',
    text: 'Huelva',
    selected: false
  },
  {
    value: 'Carretera Amarilla',
    text: 'Carretera Amarilla',
    selected: false
  },
  {
    value: 'Su Eminencia',
    text: 'Su Eminencia ',
    selected: false
  },
  {
    value: 'Jerez',
    text: 'Jerez',
    selected: false
  },
  {
    value: 'Puerto de Santa María',
    text: 'Puerto de Santa María',
    selected: false
  },
]

const optionsSocialMedia = [
  {
    value: 'instagram',
    text: 'Instagram',
    selected: false
  },
  {
    value: 'whatsapp',
    text: 'Whatsapp',
    selected: false
  },
  {
    value: 'facebook',
    text: 'Facebook',
    selected: false
  },
  {
    value: 'http',
    text: 'Web',
    selected: false
  },
  {
    value: 'telegram',
    text: 'Telegram',
    selected: false
  }
]



export interface ConversationFilterProps {
  search: (pagination2: ConversationSearch, reset: boolean) => void;
}

const ConversationFilter = (props: ConversationFilterProps) => {

  const { search } = props;

  const [visibleA, setVisibleA] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // const [date, setDate] = useState<{ startDate: string, endDate: string }>(undefined);
  const [date, setDate] = useState<{ startDate: any, endDate: any }>({
    startDate: new Date(new Date(new Date().setDate(new Date().getDate() - 29)).setHours(0, 0, 0, 0)),
    endDate: new Date(new Date().setHours(23, 59, 59, 999))
  });

  const [userSocialList, setUserSocialList] = useState<{ value: any, text: any, platform: string, selected: boolean, disabled: boolean }[]>([]);

  const [socialMediaList, setSocialMediaList] = useState<{ value: any, text: any, selected: boolean }[]>(optionsSocialMedia);
  const [actionList, setActionList] = useState<{ value: any, text: any, selected: boolean }[]>(actionOptions);
  const [carDealerList, setCarDealerList] = useState<{ value: any, text: any, selected: boolean }[]>(carDealerOptions);
  const [attClientList, setAttClientList] = useState<{ value: any, text: any, selected: boolean }[]>(attClientOptions);
  const [brandList, setBrandList] = useState<{ value: any, text: any, selected: boolean }[]>(brandOptions);
  const [typeVehicleList, setTypeVehiclesList] = useState<{ value: any, text: any, selected: boolean }[]>(typeVehicleOptions);
  const [text, setText] = useState<string>("");


  const [hasNotification, setHasNotification] = useState<boolean>(false);

  /**
   * Todos los usuarios
   */
  const [usmCompleteList, setUSMCompleteList] = useState<{ value: any, text: any, platform: string, selected: boolean, disabled: boolean }[]>([]);


  const { autocomplete: userSocialAutocomplete } = useUserSocialMedia();

  useEffect(() => {
    (async () => {
      let token = await LoginService.getTokenAsync();
      let result = await userSocialAutocomplete(token, { order: "createdDate", orderBy: "asc", page: 1, itemsPerPage: 10000 });
      if (result && result.ok) {
        // Este array no se puede modificar
        setUSMCompleteList(result.item.map(usm => {
          let textLabel = usm.platform ? usm.platform.substring(0, 3) + " - " + usm.idUserPlatform : usm?.idUserPlatform;
          return {
            value: usm.id,
            text: textLabel,
            platform: usm.platform,
            selected: false,
            disabled: false
          }
        }))

        // Este array es el que se modificará
        setUserSocialList(result.item.map(usm => {
          let textLabel = usm.platform ? usm.platform.substring(0, 3) + " - " + usm.idUserPlatform : usm?.idUserPlatform;
          return {
            value: usm.id,
            text: textLabel,
            platform: usm.platform,
            selected: false,
            disabled: false
          }
        }))
      } else {
        setUSMCompleteList([]);
        setUserSocialList([]);
      }

    })();
  }, [])

  function handleModal(value?: boolean): void {
    setShowModal(value);
  }


  const handleChangeSocialMedia = (selectedSocialMedias) => {
    // Nuevo
    // setSocialMedia(selectedSocialMedias)
    setSocialMediaList(prevList => {
      return prevList.map(rrss => {
        const isSelected = selectedSocialMedias.some(selected => selected.value === rrss.value);
        return {
          ...rrss,
          selected: isSelected
        }
      });
    });

    const selectedSocialMediaValues = selectedSocialMedias.map(socialMedia => socialMedia.value);

    setUserSocialList(prevList => {
      // Si no hay ninguna red social seleccionada, todos los usuarios están habilitados
      if (selectedSocialMedias.length === 0) {
        return prevList.map(userSocial => {
          return { ...userSocial, disabled: false };
        });
      }

      // Si hay redes sociales seleccionadas, solo los usuarios con esa plataforma están habilitados
      return prevList.map(userSocial => {
        if (selectedSocialMediaValues.includes(userSocial.platform.toLowerCase())) {
          return { ...userSocial, selected: userSocial.selected, disabled: false };
        } else {
          return { ...userSocial, selected: false, disabled: true };
        }
      });
    });
  }


  const handleChangeUserSocialMedia = (event) => {

    setUserSocialList(prevList => {
      return prevList.map(userSocial => {
        if (event.find(e => e.value === userSocial.value)) {
          return { ...userSocial, selected: true };
        } else {
          return { ...userSocial, selected: false };
        }
      });
    });
  }


  const handleChangeAction = (selectedActions) => {
    setActionList(prevList => {
      return prevList.map(act => {
        const isSelected = selectedActions.some(selected => selected.value === act.value);
        return {
          ...act,
          selected: isSelected
        }
      });
    });
  };

  const handleChangeCarDealer = (selectedLocations) => {
    setCarDealerList(prevList => {
      return prevList.map(act => {
        const isSelected = selectedLocations.some(selected => selected.value === act.value);
        return {
          ...act,
          selected: isSelected
        }
      });
    });
  };

  const handleChangeBrand = (selectedBrand) => {
    setBrandList(prevList => {
      return prevList.map(act => {
        const isSelected = selectedBrand.some(selected => selected.value === act.value);
        return {
          ...act,
          selected: isSelected
        }
      });
    });
  };

  const handleChangeAttClient = (selectedAttClients) => {
    setAttClientList(prevList => {
      return prevList.map(act => {
        const isSelected = selectedAttClients.some(selected => selected.value === act.value);
        return {
          ...act,
          selected: isSelected
        }
      });
    });
  };

  const handleChangeTypeVehicle = (selectedTypeVehicles) => {
    setTypeVehiclesList(prevList => {
      return prevList.map(act => {
        const isSelected = selectedTypeVehicles.some(selected => selected.value === act.value);
        return {
          ...act,
          selected: isSelected
        }
      });
    });
  };

  const handleDate = (startDate, endDate) => {
    if (startDate && endDate) {
      setDate({ startDate, endDate })
    } else {
      setDate(undefined)
    }
  }

  const [typingTimeout, setTypingTimeout] = useState(null);
  const handleChangeText = (event) => {
    // Si ya hay un timeout, se borra
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Se establece un nuevo timeout
    setTypingTimeout(
      setTimeout(() => {
        // save(event.target.value);
        setText(event.target.value)
      }, 250) // Se ejecuta tras 400 milisegundos de haber dejado de escribir
    );

  }

  useEffect(() => {
    save();
  }, [text])


  const save = () => {

    const selectedUsers = userSocialList.filter(item => item.selected);
    const selectedMedias = socialMediaList.filter(item => item.selected);
    const selectedActions = actionList.filter(item => item.selected);
    const selectedCarDealers = carDealerList.filter(item => item.selected);
    const selectedAttClients = attClientList.filter(item => item.selected);
    const selectedBrands = brandList.filter(item => item.selected);
    const selectedTypeVehicles = typeVehicleList.filter(item => item.selected);


    // console.log("Users selected", selectedUsers);
    // console.log("Medias selected", selectedMedias);
    // console.log("Action selected", selectedActions);
    // console.log("Action selected", selectedCarDealers);

    // console.log("Has notification", hasNotification);

    // console.log("Texto", text);


    let users = [],
      medias = [],
      actions = [],
      notification = [],
      carDealers = [],
      attClients = [],
      brands = [],
      typeVehicles = [];

    // Si no hay usuarios seleccionados, toma todos los usuarios. De lo contrario, toma solo los seleccionados.
    if (selectedUsers.length === 0) {
      // users = userSocialList.map(item => item.value);
    } else {
      users = selectedUsers.map(item => item.value);
    }

    // Si no hay medios seleccionados, toma todos los medios. De lo contrario, toma solo los seleccionados.
    if (selectedMedias.length === 0) {
      // medias = socialMediaList.map(item => item.value);
    } else {
      medias = selectedMedias.map(item => item.value);
    }

    // Si no hay acciones seleccionadas, toma todas las acciones. De lo contrario, toma solo las seleccionadas.
    if (selectedActions.length === 0) {
      // actions = actionList.map(item => item.value);
    } else {
      actions = selectedActions.map(item => item.value);
    }

    // Si no hay localizaciones seleccionadas, toma todas las localizaciones. De lo contrario, toma solo las seleccionadas.
    if (selectedCarDealers.length === 0) {
      // carDealers = carDealerList.map(item => item.value);
    } else {
      carDealers = selectedCarDealers.map(item => item.value);
    }

    // Similar a antes, pero para attClients, brands, y typeVehicles.
    if (selectedAttClients.length === 0) {
      // attClients = attClientList.map(item => item.value);
    } else {
      attClients = selectedAttClients.map(item => item.value);
    }

    if (selectedBrands.length === 0) {
      // brands = brandList.map(item => item.value);
    } else {
      brands = selectedBrands.map(item => item.value);
    }

    if (selectedTypeVehicles.length === 0) {
      // typeVehicles = typeVehicleList.map(item => item.value);
    } else {
      typeVehicles = selectedTypeVehicles.map(item => item.value);
    }

    // Si el checkbox de "hasNotification" está activado, añadir '"message":"notification"' a la lista de acciones.
    if (hasNotification) {
      notification.push('"message":"notification"');
    }

    // Proviene del setState Text. Lee del campo conversation, hasNotification
    if (text) {
      notification.push(text)
    }

    // Esto no se usa ya que sea parsea el objeto en el back
    // De ahi las propiedades "...Params"
    // let parameters = [...actions]

    // Crea el objeto para la búsqueda
    const searchParams: ConversationSearch = {
      page: 1,
      itemsPerPage: 15,
      idUserSocialMediaFk: users, // agrega los IDs de los usuarios seleccionados
      location: medias, // agrega las localizaciones de los medios seleccionados
      conversation: notification, // Usa el array de acciones para la conversación
      // parameters: parameters,
      actionParams: actions,
      locationParams: carDealers,
      attClientParams: attClients,
      brandParams: brands,
      vehicleTypeParams: typeVehicles,
      createdDate: date ? date : undefined
    };


    console.log("ENTRO AQUI")
    search(searchParams, true); // ejecuta la búsqueda con los parámetros creados

    setShowModal(false);
  }






  return (
    <CRow className='m-1' >

      <CCol xs={8} style={{ marginTop: 20 }}>

        <CForm>
          <CInputGroup className="mb-3">
            <CFormInput
              type="text"
              id="searchtable"
              placeholder="Buscador"
              onKeyUp={handleChangeText}
            />
            {/* {!multiWord && (
                                    <CButton type="button" color="secondary" variant="outline" id="button-addon2" onClick={() => handleMessage(message)}>   <CIcon icon={icon.cilSearch} /></CButton>
                                )} */}
          </CInputGroup>


        </CForm>

      </CCol>
      {/* <CCol xs={1}><CButton color="light" onClick={() => setVisibleA(!visibleA)} ><CIcon icon={cilListFilter} size='lg' /></CButton></CCol> */}
      <CCol xs={1} style={{ marginTop: 20 }}><CButton color="light" onClick={() => setShowModal(!showModal)} ><CIcon icon={cilListFilter} size='lg' /></CButton></CCol>

      <CCol xs={10}>

        <CCollapse visible={visibleA}>


          {/* <CCard className="mt-3">
            <CCardBody>
              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson
              ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
              sapiente ea proident.
            </CCardBody>
          </CCard> */}
        </CCollapse>
      </CCol>



      <ModalCover
        size='xl'
        title={'Filtrar chat'}
        hasSuccessButton={true}
        handleModal={handleModal}
        show={showModal}
        fnSuccess={save} 
        >
        {/* <pre>{JSON.stringify(usmCompleteList, null, 2)}</pre>*/}
        {/* <pre>{JSON.stringify(socialMediaList, null, 2)}</pre> */}







        <CRow>
          <CCol>

            <label style={{ marginBottom: 8 }}>Rango fechas</label>
            <GKDateRangePicker
              defaultStartDate={date?.startDate}
              defaultEndDate={date?.endDate}
              handleDate={handleDate} />
          </CCol>


          <CCol>
            <CMultiSelect
              placeholder="Seleccionar..."
              selectAllLabel="Seleccionar todos"
              options={actionList}
              label="Rama principal"
              onChange={handleChangeAction}
            />
          </CCol>


        </CRow>

        <div style={{ marginTop: 30 }}></div>

        <CRow>
          <CCol>
            <CMultiSelect
              placeholder="Seleccionar..."
              selectAllLabel="Seleccionar todos"
              options={socialMediaList}
              label="Redes sociales"
              onChange={handleChangeSocialMedia}
            />
          </CCol>

          <CCol>
            <CMultiSelect
              placeholder="Seleccionar..."
              selectAllLabel="Seleccionar todos"
              options={userSocialList}
              label="Usuarios Redes Sociales"
              onChange={handleChangeUserSocialMedia}
            />
          </CCol>

        </CRow>
        <div style={{ marginTop: 30 }}></div>
        <CRow>
          <CCol>
            <CMultiSelect
              placeholder="Seleccionar..."
              selectAllLabel="Seleccionar todos"
              options={brandList}
              label="Marca"
              onChange={handleChangeBrand}
            />
          </CCol>
          <CCol>

            <CMultiSelect
              placeholder="Seleccionar..."
              options={attClientList}
              label="Tipo contacto"
              onChange={handleChangeAttClient}
            />
          </CCol>
        </CRow>
        <div style={{ marginTop: 30 }}></div>

        <CRow>
          <CCol>
            <CMultiSelect
              placeholder="Seleccionar..."
              selectAllLabel="Seleccionar todos"
              options={typeVehicleList}
              label="Tipo de vehículo"
              onChange={handleChangeTypeVehicle}
            />
          </CCol>

          <CCol>

            <CMultiSelect
              placeholder="Seleccionar..."
              selectAllLabel="Seleccionar todos"
              options={carDealerList}
              label="Concecionarios"
              onChange={handleChangeCarDealer}
            />
          </CCol>
        </CRow>

        <div style={{ marginTop: 30 }}></div>
        <CFormCheck
          id="flexCheckDefault"
          label="Conversaciones con notificación"
          onChange={(e: any) => setHasNotification(e.target.checked)}
          checked={hasNotification}
        />


        {/* Tengo que usar un CMultiSelect en vez del autocomplete mío
          Esto es debido a que mi autocomplete no se puede marcar varios a la vez xD...
        */}
        {/* <Autocomplete table="userSocialMedias" name={''} onChange={handleChange} onBlur={handleBlur} /> */}
      </ModalCover>
    </CRow>

  )
}

export default ConversationFilter

