
import { CBadge, CButton, CCloseButton, CCol, CDateRangePicker, CForm, CFormInput, CInputGroup, CInputGroupText, CRow } from '@coreui/react-pro';
import * as React from 'react';
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Chip } from '@mui/material';
import { END_DATE_DEFAULT, START_DATE_DEFAULT, customRangesDate } from './custon-ranges';
import moment from 'moment';


interface SearchTableProps {
    disabled?: boolean;
    handleDate?: (startDate: string, endDate: string) => void;


    defaultStartDate?: Date | string,
    defaultEndDate?: Date | string
}

const GKDateRangePicker = (props: SearchTableProps) => {

    const { disabled,
        defaultStartDate,
        defaultEndDate,
        handleDate } = props;

    const [startDate, setStartDate] = useState(defaultStartDate || START_DATE_DEFAULT);
    const [endDate, setEndDate] = useState(defaultEndDate || END_DATE_DEFAULT);

    const drp = useRef(null)
    return (
        <div className="row">
            <CDateRangePicker
                ref={drp}
                placeholder={["Fecha comienzo", "Fecha final"]}
                firstDayOfWeek={1}

                locale='es-ES'

                startDate={startDate}
                endDate={endDate}

                ranges={customRangesDate}
                placement='bottom'
                // minDate={customRangesDate['Ayer'][0]}
                inputReadOnly={true}
                onStartDateChange={(date, formatedDate) => {
                    if (date) {
                        // let d = moment(date).format("YYYY-MM-DD HH:ss");
                        setStartDate(date);
                    } else {
                        setStartDate(undefined);
                    }
                }}
                onEndDateChange={(date, formatedDate) => {
                    if (date) {
                        // let d = moment(date).format("YYYY-MM-DD HH:ss");
                        setEndDate(date);
                    } else {
                        setEndDate(undefined);
                    }

                }}

                onHide={() => {
                    console.log("enteo aqui 0")

                    // Aqui tengo que detectar si tiene ambas fechas puesta, si falta alguna o las dos añado la de por defecto
                    if (startDate && endDate) {
                        console.log("enteo aqui 1")

                        if (handleDate) {
                            // let dstart = moment(startDate).format("YYYY-MM-DD HH:ss");
                            // let dend = moment(endDate).format("YYYY-MM-DD HH:ss");

                            /**
                             * TODO: El código de arriba funciona perfectamente, pero hay un pequeño bug y de
                             * ahí el cambio de abajo.
                             * 
                             * Se ha puesto los números en los minutos porque cuando elijes un mismo día clicando
                             * en el calendario (en los números) te ponía en esos casos la fecha a 0:00
                             */
                            let dstart = moment(startDate).format("YYYY-MM-DD 00:00");
                            let dend = moment(endDate).format("YYYY-MM-DD 23:59");
                            handleDate(dstart, dend);
                        }
                    } else {

                        console.log("enteo aqui 2")
                        if (handleDate) {
                            setStartDate(START_DATE_DEFAULT);
                            setEndDate(END_DATE_DEFAULT);

                            let dstart = moment(START_DATE_DEFAULT).format("YYYY-MM-DD HH:ss");
                            let dend = moment(END_DATE_DEFAULT).format("YYYY-MM-DD HH:ss");

                            handleDate(dstart, dend);

                        }
                    }
                }}



                onCancel={() => {
                    console.log("cnacelado")
                }}
                cleaner={false}

            />
        </div>
    )
};

export default GKDateRangePicker;


