import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
// import './scss/style.scss'
import './app/scss/style.scss';
import "./core/assets/styles/base.scss"

import Navigation from './app/routes/Navigation';

// Containers
import DefaultLayout from './app/layout/DefaultLayout'
import axios from 'axios';


// class App extends Component {
//   render(): JSX.Element {
//     return (
//       <HashRouter>
//         <Suspense fallback={<CSpinner color="primary" />}>
//           <Routes>
//             <Route path="*" element={<DefaultLayout />} />
//           </Routes>
//         </Suspense>
//       </HashRouter>
//     )
//   }
// }


// TODO: Quita console.log en production
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}

function App() {
  return (
    <>
      <Navigation />
    </>
  )
}

export default App
