export class Util {
    /**
     * Genera grupos de 4 caracteres aleatorios
     * @example getUniqueId(1) : 607f
     * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
     */
    static getUniqueId(parts: number): string {
        const stringArr = [];
        for (let i = 0; i < parts; i++) {
            // tslint:disable-next-line:no-bitwise
            const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            stringArr.push(S4);
        }
        return stringArr.join('-');
    }

    static getFileExtention = (fileUrl: any) => {
        return /[.]/.exec(fileUrl) ?
            /[^.]+$/.exec(fileUrl) : undefined;
    }

    /**
     * 
     * @param fileUrl 
     * @param extention Booleano que le indicas si quieres que el nombre venga con extensión (.jpg, .png, etc)
     * @returns 
     */
    static getFileName = (fileUrl: string, extention = true) => {
        if (fileUrl && extention) {
            const last = fileUrl.lastIndexOf("/");
            return fileUrl.substring(last + 1);
        } else if (fileUrl && !extention) {
            const last = fileUrl.lastIndexOf("/");
            const pathAux = fileUrl.substring(last + 1);

            const atPosition = pathAux.lastIndexOf(".");
            return pathAux.substring(0, atPosition);

        } else {
            return fileUrl;
        }
    }


    static formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    static getLetterAvatar(name: string, surname?: string) {
        let letterAvatar = "";
        if (name && name != "") letterAvatar += name.substring(0, 1);
        if (surname && surname != "") {
            letterAvatar += surname.substring(0, 1);
        } else {
            letterAvatar += name.substring(1, 2);
        }

        return letterAvatar;
    }

    static getAge(date: any) {
        let original: any = new Date(date);
        if (original != "Invalid Date") {
            var today = new Date();
            var age = today.getFullYear() - original.getFullYear();
            var m = today.getMonth() - original.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < original.getDate())) {
                age--;
            }
            return age;
        } else {
            return ""
        }
    }



    static isOdd(num: any) {
        return num % 2;
    }

    static monthStatement(date: any) {
        let d = new Date(date.replace(' ', 'T'))
        const month = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
        return month[d.getMonth()];
    }

    static isLeapYear(year: any) {
        return (year % 400 === 0) ? true :
            (year % 100 === 0) ? false :
                year % 4 === 0;
    }


    /**
     * Indica si el peso es superior al del límite
     * @param limitMb peso en mb
     * @param sizebyte peso en bytes
     * @returns 
     */
    static isSizeBigger(limitMb: number, sizebyte: number) {
        const sizeMb = sizebyte / Math.pow(1024, 2);
        return sizeMb >= limitMb;
    }

    /**
     * Recorta un texto y agrega puntos suspensivos
     * @param value 
     * @param limit 
     * @returns 
     */
    static cutWord(value: string, limit = 0) {
        if (limit == 0 || limit < 0) return value;
        if (value && value.length > limit) {
            return value.substring(0, limit) + "..."
        } else {
            return value;
        }
    }

    static fixDecimal(value) {
        let number: number = parseInt(value);
        let isNumber = !isNaN(number);
        if (isNumber) {
            let numberString = parseFloat(value).toFixed(2);
            let index = numberString.indexOf('.');
            let substring = numberString.slice(index);
            return (substring == '.00') ? parseFloat(numberString.slice(0, index)) : parseFloat(numberString);
        } else {
            return 0;
        }
    }


    static random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

    static capitalize(str) {
        if (str === undefined || str === null) return ""
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

}


