import axios, { AxiosProgressEvent } from "axios";
import { environment } from "../../../environments";
import { UploadFile } from "../model/UploadFile";


export class FileService {

    static add(token: string, object: UploadFile, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) {

        if (object.file == undefined) throw new Error("No existe fichero para ser guardado");
        let formData: FormData = new FormData();
        let directList = JSON.stringify(object.directoryNameList);
        formData.append("directories", directList);
        formData.append("file", object.file)
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(
            `${environment.urlApi}/api/upload-file`,
            formData,
            {
                headers,
                onUploadProgress
            },


        );
    }
}