import React, { createElement, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { CButton, CButtonGroup, CCard, CCardBody, CCardFooter, CCardHeader, CCol, CCollapse, CContainer, CFormCheck, CImage, CMultiSelect, CRow, CSmartTable } from '@coreui/react-pro'
import STableCUI from '../../components/sTableCUI/STableCUI'


import { Invoicehead } from '../../../core/models/db/invoicehead';
import { User as MyNotifification } from '../../../core/models/db/user';
import { LoginService } from '../../../core/services/login/login-service';
import { LoaderContext } from '../../components/loader/context/LoaderContext';
import SearchTable from '../../components/@filters/searchTable/SearchTable';
import { Pagination } from '../../../core/models/pagination';
import { useNavigate } from 'react-router-dom';
import { Item } from '@coreui/react-pro/dist/components/table/types';


import moment from 'moment';
import GKDateRangePicker from '../../components/@filters/dateRangePicker/DateRangePicker';
import { START_DATE_DEFAULT, END_DATE_DEFAULT } from '../../components/@filters/dateRangePicker/custon-ranges';
import { useAppointment } from '../../../core/hooks/db/appointment/useAppointment';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { useCheckSelected } from '../../components/table/hooks/useCheckSelected';
import { useCheckTable } from '../../../core/hooks/check-table/useCheckTable';
import { Util } from '../../../core/util/util';
import CustomerInformation from '../../components/customerInformation/CustomerInformation';
import { cilMoodBad } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import useWindowDimensions from '../../../core/hooks/window-dimensions/userWindowDimensions';




const INIT_PAGINATION: Pagination = { page: 1, itemsPerPage: 10 };
const INIT_ALL_ROW: Pagination = { page: 1, itemsPerPage: 0 };

const CLASS_REGULAR_ROW = "check-reg-" + Util.getUniqueId(1);
const CLASS_PRINCIPAL_ROW = "check-prin-" + Util.getUniqueId(1);

const LABEL_PAGE = "Citas"

const Appointments = () => {

    const { getAppointment, getAllIdAppointment } = useAppointment();
    const { width, height } = useWindowDimensions();

    const navigate = useNavigate();

    const [body, setBody] = useState<MyNotifification[]>([]);
    const [reload, setRealod] = useState<boolean>(true);

    const { handleLoading, changeStyle } = useContext(LoaderContext);

    const [token, setToken] = useState(null);

    const [pagination, setPagination] = useState<Pagination>({
        ...INIT_PAGINATION,
        startDate: moment(START_DATE_DEFAULT).format("YYYY-MM-DD HH:mm"),
        endDate: moment(END_DATE_DEFAULT).format("YYYY-MM-DD HH:mm")
    });




    // const { selected, handleSingleCheck } = useCheckTable(
    //     {
    //         body,
    //         pagination: pagination,
    //         reasonForDeactivation: [
    //             {
    //                 value: "Madrid",
    //                 key: "carDealer",
    //                 typeField: "string"
    //             },
    //             {
    //                 value: "Sevilla",
    //                 key: "carDealer",
    //                 typeField: "string"
    //             },
    //             {
    //                 value: "málaga",
    //                 key: "carDealer",
    //                 typeField: "string"
    //             }
    //         ],
    //         classToFindPrincipalRow: CLASS_PRINCIPAL_ROW,
    //         classToFindRegularRow: CLASS_REGULAR_ROW,
    //         getAllId: getAllIdAppointment
    //     });



    const initPage = () => {
        (async () => {
            changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
            handleLoading(true);
            let t;
            if (token) {
                t = token;
                setToken(token)
            } else {
                t = await LoginService.getTokenAsync();
                setToken(t)
            }

            //console.log(pagination)
            const response = await getAppointment(t, pagination);
            if (response.ok) {
                let rows = response.item.rows;
                for (let i = 0; i < response.item.rows.length; i++) {

                    // Hay que agregar estos dos para añadir los checks
                    // rows[i].priv_check = "";
                    // rows[i]._props = { className: CLASS_REGULAR_ROW };
                }

                setBody(rows);
                setPagination({
                    ...pagination,
                    totalItems: response.item.totalItems,
                    totalPages: response.item.totalPages,
                })

                setTimeout(() => {
                    handleLoading(false)
                }, 200)
            }
        })()
    }

    useLayoutEffect(() => {
        setRealod(false);
        if (reload) {
            /**
             * Cuando se inicia la página o se hace algún cambio en el
             * paginado
             */
            initPage();
        }
    }, [reload]);


    const handleDate = (startDate: string, endDate: string) => {
        if (startDate && endDate) {
            setPagination({
                ...pagination,
                page: 1,
                startDate,
                endDate
            });
            setRealod(true);

        }
    }

    /**
     * Buscador
     * @param value 
     */
    const handleMessage = (value: string) => {
        setPagination({
            ...pagination,
            page: 1,
            wordList: value
        });
        setRealod(true);

    }

    /**
    * Buscador con chips
    * @param value 
    */
    const handleMessageList = (value: string[]) => {
        setPagination({
            ...pagination,
            page: 1,
            wordList: value.join(",")
        });
        setRealod(true);

    }

    const [details, setDetails] = useState([])
    const toggleDetails = (index) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
            newDetails.splice(position, 1)
        } else {
            newDetails = [...details, index]
        }
        setDetails(newDetails)
    }

    const [elementAdded, setElementAdded] = useState<boolean>(false);


    return (
        <CCard className="mb-4">
            <CCardHeader>
                {/* <pre>{JSON.stringify(selected, null, 2)}</pre> */}
                <CRow>
                    <CCol xs={12} sm={4}>
                        <h4 id="title-page" className="card-title mb-0">
                            {LABEL_PAGE}
                        </h4>
                        <div className="small text-medium-emphasis">Listado</div>
                    </CCol>
                    <CCol xs={8} md={8} xl={8} className="d-none d-md-block">
                        {/* <Link to={'/dashboard/usuarios/crear'} >
                            <CButton color="primary" className="float-end"  >
                                <CIcon icon={cilPlus} />
                            </CButton>
                        </Link> */}
                        <div className="col-4 float-end me-3">
                            <SearchTable
                                handleMessageList={handleMessageList}
                                handleMessage={handleMessage}
                                disabled={false}
                                multiWord={true} />
                        </div>
                        <div className="col-6 float-end me-3">
                            <GKDateRangePicker handleDate={handleDate} />
                        </div>
                    </CCol>


                </CRow>
            </CCardHeader>

            <CCardBody >

                <CRow>
                    <CCol>

                        {/* No hay información */}
                        {!body || body.length === 0 && (
                            <CContainer>
                                <div className="row justify-content-md-center">
                                    <CCol md="auto">
                                        <div style={{ fontSize: 20 }} className='d-flex justify-content-center align-items-center'>
                                            <span>No hay información <CIcon size='lg' icon={cilMoodBad} /></span>
                                        </div>
                                    </CCol>
                                </div>

                            </CContainer>
                        )}

                        {body && body.length > 0 && pagination && pagination.totalPages && (
                            <div className={width < 780 ? 'table-responsive' : ''}>
                                <CSmartTable
                                    items={body}
                                    tableProps={{
                                        // striped: true,
                                        hover: true,
                                    }}
                                    columns={[
                                        // {
                                        //     key: "priv_check",
                                        //     label: "",
                                        //     sorter: false,
                                        //     filter: false,
                                        //     _props: { className: CLASS_PRINCIPAL_ROW }
                                        // },
                                        {
                                            key: "carDealer",
                                            label: "Concesionario",

                                        },
                                        {
                                            key: "reason",
                                            label: "Razón"
                                        },
                                        {
                                            key: "appointmentDate",
                                            label: "Fecha cita",

                                        },
                                        {
                                            key: "createdDate",
                                            label: "Creado",

                                        },
                                        {
                                            key: 'show_details',
                                            label: '',
                                            _style: { width: '5%' },
                                            filter: false,
                                            sorter: false,
                                            _props: { className: 'fw-semibold' },
                                        },
                                    ]}

                                    scopedColumns={{
                                        carDealer: (item) => {
                                            let value = item.carDealer ? item.carDealer : "Lexus";
                                            return (
                                                (<td> {value} </td>)
                                            )
                                        },
                                        reason: (item) => {
                                            let value = item.reason ? Util.capitalize(item.reason) : "";
                                            return (
                                                (<td> {value} </td>)
                                            )
                                        },
                                        appointmentDate: (item) =>
                                        (<td> {item.appointmentDate
                                            ? item.appointmentDate.startsWith('1900-01-01')
                                                ? moment.utc(item.appointmentDate).format('HH:mm')
                                                : moment.utc(item.appointmentDate).format('DD/MM/YYYY HH:mm')
                                            : 'Fecha no registrada'}
                                        </td>),
                                        createdDate: (item) =>
                                            (<td> {item.createdDate ? moment.utc(item.createdDate).format("DD/MM/YYYY") : 'Fecha no registrada'}</td>),
                                        show_details: (item) => {
                                            return (
                                                <td className="py-2">
                                                    <CButton
                                                        color="dark"
                                                        variant="outline"
                                                        shape="square"
                                                        size="sm"
                                                        onClick={() => {
                                                            toggleDetails(item.id)
                                                        }}
                                                    >
                                                        {details.includes(item.id) ? 'Ocultar' : 'Mostrar'}
                                                    </CButton>
                                                </td>
                                            )
                                        },
                                        details: (item, number) => {
                                            const notification = item.notifications;
                                            const userSocialMedia = notification ? notification.userSocialMedias : null;
                                            return (
                                                <CCollapse visible={details.includes(item.id)}>
                                                    <CCardBody className="p-3">
                                                        {/* <h4>{item.title}</h4>
                                                    <p className="text-title">
                                                        {moment(item.createdDate).format("DD/MM/YYYY")}
                                                    </p> */}
                                                        {userSocialMedia && (
                                                            <table style={{ width: '50%', borderCollapse: 'collapse' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        {/* <td style={{ fontWeight: 'bold' }}>Título email</td> */}
                                                                        <td style={{ fontWeight: 'bold' }}>Plataforma</td>
                                                                        <td style={{ fontWeight: 'bold' }}>Usuario</td>
                                                                    </tr>
                                                                    <tr>
                                                                        {/* {item && item.title &&
                                                                    <td style={{ color: '#999' }}>{item.title}</td>
                                                                } */}
                                                                        {userSocialMedia.platform &&
                                                                            <td style={{ color: '#999' }}>{userSocialMedia.platform}</td>
                                                                        }
                                                                        {userSocialMedia.idUserPlatform &&
                                                                            <td style={{ color: '#999' }}>{userSocialMedia.idUserPlatform}</td>
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        )}

                                                        <div style={{ marginTop: 50 }}>
                                                            <div className="clearfix">
                                                                <CustomerInformation jsonLead={notification.jsonLead} />
                                                            </div>

                                                        </div>
                                                    </CCardBody>
                                                </CCollapse>
                                            )
                                        },
                                    }}

                                    // columnFilter={{
                                    //     external: true
                                    // }}

                                    columnSorter={{
                                        external: true
                                    }}
                                    // selectable={true}
                                    selectAll={{
                                        external: true
                                    }}
                                    // selected={[...body]}
                                    pagination
                                    clickableRows
                                    itemsPerPageSelect
                                    itemsPerPageLabel={"Registros por página"}
                                    itemsPerPageOptions={[1, 5, 10]}
                                    onRowClick={(item: Item, index: number, columnName: string, event) => {
                                        let target = typeof event !== 'boolean' ? event.nativeEvent.target as HTMLElement : event;
                                        let value = typeof target === 'boolean' ? target : target.tagName;

                                        // console.log(columnName, index)
                                        /** Significa que se ha clicado en el check (INPUT) */
                                        if (typeof target !== 'boolean' && value === "INPUT") {
                                            //    Clica al check

                                        } else if (typeof target !== 'boolean' && value === "TD") {
                                            //    Console ir al detalle
                                            // if (selected && selected.length > 0) {
                                            //     handleSingleCheck(null, item.id, item);
                                            // } else {
                                            //     // Va al formulario
                                            // }
                                        }
                                    }}

                                    onSelectAll={() => {
                                        //console.log("calling all of ids")
                                    }}
                                    onSelectedItemsChange={(items) => {
                                        // console.log("items that you selected", items)
                                    }}


                                    onSorterChange={(value) => {
                                        // console.log("what is value?", value)
                                        if (value.state === 0) {
                                            setPagination({
                                                ...pagination,
                                                relatedTableOrder: null,
                                                order: null,
                                                orderBy: null
                                            })
                                        } else if (value.column === "priv_role") {
                                            setPagination({
                                                ...pagination,
                                                relatedTableOrder: "roles",
                                                order: value.state,
                                                orderBy: "title"
                                            })
                                        } else {
                                            setPagination({
                                                ...pagination,
                                                relatedTableOrder: null,
                                                order: value.state,
                                                orderBy: value.column
                                            })
                                        }

                                        setTimeout(() => {
                                            setRealod(true);
                                        }, 100)

                                    }}


                                    paginationProps={{
                                        activePage: pagination.page,
                                        pages: pagination.totalPages,
                                        limit: 4,
                                        onActivePageChange: (activePage: number) => {
                                            // console.log(activePage)
                                            setPagination({
                                                ...pagination,
                                                page: activePage,
                                            });
                                            setRealod(true);

                                        }
                                    }}

                                    onItemsPerPageChange={(currItemsPerPage) => {
                                        const { page, totalItems } = pagination;

                                        const totalPages = Math.floor(totalItems / currItemsPerPage) + 1;
                                        const newPositionPage = Math.min(totalPages, page);
                                        setPagination({
                                            ...pagination,
                                            page: newPositionPage,
                                            itemsPerPage: currItemsPerPage
                                        });
                                        setRealod(true);

                                    }}
                                />
                            </div>
                        )}
                    </CCol>



                </CRow>

            </CCardBody>
            {/* <CCardFooter>
        <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
          Footer
        </CRow>
      </CCardFooter> */}
        </CCard >

    )
}

export default Appointments



