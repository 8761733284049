import axios from "axios";
import { environment } from "../../../../app/environments";

import { Pagination } from "../../../models/pagination";

import { ReasonForDeactivationProps } from "../../../hooks/check-table/useCheckTable";
import { Conversation } from "../../../models/db/conversation";
import { ConversationSearch } from "../../../models/interfaces/conversation-search";



export class ConversationService {

  constructor() { }

  static get(token: any, id: any) {
    let headers = { 'Authorization': `Bearer ${token}` };
    return axios.get(`${environment.urlApi}/api/conversations/${id}`, { headers });
  }

  static getAll(token: any, paginated?: Pagination) {

    let headers = { 'Authorization': `Bearer ${token}` };
    // let completePath = (paginated) ? this._paginate(paginated) : `${environment.urlApi}/api/appointments`;
    return axios.post(`${environment.urlApi}/api/conversations/pagination`, this._paginate2(paginated), { headers });
  }


  static getSpecific(token: any, search: ConversationSearch) {
    let headers = { 'Authorization': `Bearer ${token}` };
    let aux = JSON.parse(JSON.stringify(search));
    if (aux.page > 0) aux.page -= 1;
    delete aux.totalItems;
    delete aux.totalPages;

    return axios.post(`${environment.urlApi}/api/conversations/specific/pagination`, aux, { headers });
  }


  static getAllId(token, reasonForDeactivation: ReasonForDeactivationProps[] = []) {
    let object = { reasonForDeactivation };

    let headers = { 'Authorization': `Bearer ${token}` };
    return axios.post(`${environment.urlApi}/api/conversations/all-id`, object, { headers });
  }


  static _paginate2(pagination2: Pagination) {

    const { itemsPerPage, page, orderBy, order, wordList, relatedTableOrder, startDate, endDate } = pagination2;

    let obj: any = {};
    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      obj.itemsPerPage = itemsPerPage;
    }

    if (page !== null && page !== undefined) {
      /**
           * En el backend el paginado empieza por 0, por eso hay que restar
           * un número
           */
      if (page == 0) obj.page = page;
      else if (page > 0) obj.page = page - 1;
    }

    if (orderBy !== null && orderBy !== undefined) {
      obj.orderBy = orderBy;
    }

    if (order !== null && order !== undefined) {
      obj.order = order;
    }

    if (relatedTableOrder !== null && relatedTableOrder !== undefined) {
      obj.relatedTableOrder = relatedTableOrder;
    }

    if (wordList !== null && wordList !== undefined) {
      // obj.wordList = wordList.split(",");
      obj.wordList = wordList;
      // url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
      // url += `wordList=${wordList}`;
    }

    if ((startDate !== null && startDate !== undefined)
      && (endDate !== null && endDate !== undefined)) {
      obj.startDate = startDate;
      obj.endDate = endDate;
    }


    // console.log("Obj completed", obj)

    // console.log(encodeURI(url));
    return obj;
  }


  static add(token, body: Conversation) {
    let headers = {
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/conversations/add`, body, { headers });
  }


  static update(token, body: Conversation) {
    let headers = {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/conversations/update`, body, { headers });
  }

  static delete(token, idList: string[] | number[], action: boolean) {

    let params = { list: idList, action };
    let headers = {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/conversations/delete`, params, { headers });
  }

  /**
   * Usado en Home
   * @param token 
   * @param idList 
   * @param action 
   * @returns 
   */
  static getCountChat(token, date: { startDate: any, endDate: any }) {
    let headers = {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/home/conversations/count-social`, { date }, { headers });
  }

  /**
  * Usado en Home
  * @param token 
  * @param idList 
  * @param action 
  * @returns 
  */
  static getCountChatByPlatform(token, date: { startDate: any, endDate: any }, platforms: any[] = []) {
    let headers = {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/home/conversations/count-action`, { date, platforms }, { headers });
  }






}
