
import { CBadge, CButton, CCloseButton, CCol, CForm, CFormInput, CInputGroup, CInputGroupText, CRow } from '@coreui/react-pro';
import * as React from 'react';
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Chip } from '@mui/material';
import { useTableSearch } from './hook/useSearchTable';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

interface SearchTableProps {
    handleMessage?: (value: string) => void;
    handleMessageList?: (value: string[]) => void;
    multiWord?: boolean;
    disabled?: boolean;
}

const SearchTable = (props: SearchTableProps) => {

    const {
        handleMessage,
        handleMessageList,
        multiWord,
        disabled } = props;

    const {
        message,
        updated,

        handleChange,
        handleKeyDown,
        handleDelete } = useTableSearch();

    const isInitialMount = useRef(true); // Referencia para verificar si es la inicialización del componente

    useEffect(() => {
        console.log(message)
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (multiWord && handleMessageList) {
                handleMessageList(updated)
            } else if (!multiWord && handleMessage) {
                const typingTimeout = setTimeout(() => {
                    handleMessage(message);
                }, 300);
                return () => {
                    clearTimeout(typingTimeout);
                };
            }
        }
    }, [updated])
    // Si se quiere que se use el modo de una palabra poner lo de abajo
    // [message, updated])
    return (
        <div>
            {multiWord ?
                (
                    <div>
                        <CForm>
                            <CInputGroup className="mb-3">
                                <CFormInput
                                    type="text"
                                    id="searchtable"
                                    placeholder="Buscador"
                                    value={message}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}

                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                            </CInputGroup>

                        </CForm>
                        <div>
                            {multiWord && updated && updated.length > 0 && (
                                updated.map((msg, i) => {
                                    return <Chip className='m-1' key={i} label={msg} variant="outlined" onDelete={disabled ? null : event => handleDelete(i)} />
                                })
                            )}
                        </div>
                    </div>
                )
                : (
                    <div>
                        <CForm>
                            <CInputGroup className="mb-3">
                                <CFormInput
                                    type="text"
                                    id="searchtable"
                                    placeholder="Buscador"
                                    onKeyUp={handleChange}
                                    disabled={disabled}
                                    readOnly={disabled}
                                />
                                {/* {!multiWord && (
                                    <CButton type="button" color="secondary" variant="outline" id="button-addon2" onClick={() => handleMessage(message)}>   <CIcon icon={icon.cilSearch} /></CButton>
                                )} */}
                            </CInputGroup>


                        </CForm>

                    </div>
                )
            }
        </div>
    );
};

export default SearchTable;


