import React, { useContext, useEffect } from 'react'
import {
  AppAside,
  AppSidebar,
  AppFooter,
  AppHeader,
} from '../components/template/index'
import { CContainer, CDatePicker } from '@coreui/react-pro'
import { Outlet, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { LoaderContext } from '../components/loader/context/LoaderContext'
import ActionBar from '../components/actionBar/ActionBar';
import { Provider } from 'react-redux';
import store from '../../core/store/store'


const DefaultLayout = (): JSX.Element => {

  const navigate = useNavigate();
  const { handleLoading } = useContext(LoaderContext);

  // Interceptor
  // useEffect(() => {
  //   const interceptor = axios.interceptors.response.use(
  //     response => response,
  //     error => {
  //       handleLoading(false);
  //       // loguea el error y luego redirige al usuario a la página de login
  //       console.error(error);
  //       navigate('/login');
  //       // return Promise.reject(error);
  //     }
  //   );

  //   // cuando el componente App se desmonte, eliminamos el interceptor
  //   return () => {
  //     axios.interceptors.response.eject(interceptor);
  //   };
  // }, [navigate]);

  /**
   * Se ha hecho porque en el back cuando se devuelve el mensaje de token erróneo no tira
   * el usuario.
   */
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => {
        // Comprueba si el mensaje es "Token erróneo" en una respuesta exitosa
        if (response.data.message === "Token erróneo") {
          handleLoading(false);
          // loguea el error y luego redirige al usuario a la página de login
          console.error(response);
          navigate('/login');
        }
        return response;
      },
      error => {
        handleLoading(false);
        // loguea el error y luego redirige al usuario a la página de login
        console.error(error);
        navigate('/login');
        // return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);


  return (
    <>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <AppHeader />
        <div className="body flex-grow-1 px-3" style={{ position: 'relative' }}>
          <Provider store={store}>
            <CContainer>
              <Outlet />
              <ActionBar />
            </CContainer>
          </Provider>
        </div>
        <AppFooter />
      </div>
      <AppAside />
    </>
  )
}

export default DefaultLayout
