import { CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react-pro';
import * as React from 'react';
import { ReactElement } from 'react';
import { COLORS } from '../../../core/constants/colors';


export interface ModalCoverProps {
    show?: boolean;
    size?: 'sm' | 'lg' | 'xl';
    title: string;
    children?: ReactElement | ReactElement[];
    hasCloseButton?: boolean;
    hasCloseHeaderButton?: boolean;
    hasSuccessButton?: boolean;

    handleModal?: (value?: boolean) => void;
    fnSuccess?: () => void;
}

const ModalCover = (props: ModalCoverProps) => {
    const {
        show,
        children,
        title,

        size,

        hasCloseButton = true,

        hasSuccessButton = false,
        fnSuccess: fnSucess,

        hasCloseHeaderButton = true,

        handleModal
    } = props;

    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        setVisible(show)
    }, [show])

    return (
        <>
            {/* <CButton onClick={() => setVisible(!visible)}>Launch static backdrop modal</CButton> */}
            <CModal visible={visible} onClose={() => handleModal(false)} backdrop={'static'} size={size || null}>
                <CModalHeader closeButton={hasCloseHeaderButton}>
                    <CModalTitle>{title}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    {children}
                </CModalBody>
                {(hasCloseButton || hasSuccessButton) && (
                    <CModalFooter>
                        {hasCloseButton && (
                            <CButton color="secondary" onClick={() => handleModal(false)}>
                                Cerrar
                            </CButton>
                        )}
                        {hasSuccessButton && (
                            <CButton onClick={() => {
                                if (fnSucess) {
                                    fnSucess()
                                }
                            }} color="primary" style={{ backgroundColor: COLORS.NIMO_CLARO }}>Aceptar</CButton>
                        )}
                    </CModalFooter>
                )}

            </CModal>
        </>
    )
};

export default ModalCover;


