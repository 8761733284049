import React, { useState } from 'react'
import { Pagination } from '../../../models/pagination';
import { AppointmentService } from '../../../services/db/appointment/appointment.service';
import { Appointment } from '../../../models/db/appointment';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';



export const useAppointment = () => {

    const getAllIdAppointment = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            AppointmentService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getAppointment = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            AppointmentService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addAppointment = (token, user: Appointment) => {
        return new Promise<any>((res, rej) => {
            AppointmentService.add(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateAppointment = (token, user: Appointment) => {
        return new Promise<any>((res, rej) => {
            AppointmentService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }




    return {

        getAllIdAppointment,
        getAppointment,
        addAppointment,
        updateAppointment,

        get: getAppointment,
        add: addAppointment,
        update: updateAppointment,
        autocomplete: getAppointment,

    }
}