


import React, { useEffect, useState } from 'react'
import { ErrorDragDropProps } from '../DragDrop';
import { FileItem } from '../model/FileItem';
import { TypeAlloweds } from '../util/format-types';



interface Props {
    reference: React.MutableRefObject<HTMLInputElement>,
    fileItemList: FileItem[];
    maxSizeMB: number;
    handleFileItem: (files: FileItem[]) => void;
    handleError: (key: string, value: boolean) => void;

}

export const useAddFileInput = ({
    reference,
    maxSizeMB,
    fileItemList,

    handleFileItem,
    handleError,
}: Props) => {

    useEffect(() => { }, []);



    const handleClick = (e) => {
        reference.current.click();
        e.preventDefault();
        e.stopPropagation();
    }

    const onAddFileInput = (e) => {
        let fileFromInputList = e.target.files;
        let fiAuxiliar = [...fileItemList];

        let count = 0
        for (const file of fileFromInputList) {
            if (_canAdd(file)) {
                let fi = new FileItem(file);
                fiAuxiliar.push(fi);
            }

            if (fileFromInputList.length - 1 === count) {
                setTimeout(() => handleFileItem(fiAuxiliar), 50);
            }

            count += 1;
        }

        // Eliminamos el valor del input para que se pueda subir la imagen de nuevo
        reference.current.value = "";
    }

    /**
  * Si el archivo no existe y es menor del tamaño permitido devolverá "true"
  * @param file
  */
    const _canAdd = (file: File): boolean => {
        return (!_exist(file) && !_isBiggerSize(file))
    }

    // Comprueba si el archivo ya existe
    const _exist = (file: File): any => {
        let exist = fileItemList.some(fileItem => fileItem.name === file.name);
        if (exist) {
            // Codigo de Error. Ha encontrado un archivo que ya estaba añadido
            console.error("Codigo de Error. Ha encontrado un archivo que ya estaba añadido");
        }

        return exist;
    }

    /**
     * Comprueba si el archivo es mayhor del permitido u da error en tal caso
     * @param file
     */
    const _isBiggerSize = (file: File): boolean => {
        let isBigger = file.size > (maxSizeMB * 1204) * 1024;
        if (isBigger) {
            // Codigo de Error. El archivo pesa más de lo permitido
            console.error("Codigo de Error. El archivo pesa más de lo permitido");

        }
        return isBigger;
    }


    return {

        onAddFileInput
    }
}
