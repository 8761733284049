import axios from "axios";
import { environment } from "../../../app/environments";


export interface AccessLoginArgs {
    username: string;
    password: string;
}


export class LoginService {


    constructor() { }

    /**
     * Acceso a la app
     */
    static login = ({ username, password }: AccessLoginArgs) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        return axios.post(`${environment.urlApi}/login`, { username, password }, { headers });
    }

    /**
     * Registro a la app
     */
    static register = () => {

    }

    /**
     * Cerrar sessión y vuelta al login
     */
    // static logOut = () => {
    //     localStorage.clear();
    // }

    /**
     * Recibir token
     */
    static getTokenAsync = () => {
        return new Promise<any>((res, rej) => {
            let token = localStorage.getItem("token");
            if (!token) rej("No existe token");
            res(token);
        });
    }

    /**
     * Recibir identity
     */
    static getIdentityAsync = (): Promise<any> => {
        return new Promise<any>((res, rej) => {
            let identity = localStorage.getItem("identity");
            if (!identity) rej("No existe identity");
            res(JSON.parse(identity!));
        });
    }

}