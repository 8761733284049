import axios from "axios";
import { environment } from "../../../../app/environments";
import { Pagination } from "../../../models/pagination";
import { Configuration } from "../../../models/db/configuration";



export class ConfigurationService {

    constructor() { }

    static get(token: any, id: any) {
        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.get(`${environment.urlApi}/api/configurations/${id}`, { headers });
    }

    static getAll(token: any, paginated?: Pagination) {

        let headers = { 'Authorization': `Bearer ${token}` };
        // let completePath = (paginated) ? this._paginate(paginated) : `${environment.urlApi}/api/configurations`;
        return axios.post(`${environment.urlApi}/api/configurations/pagination`, this._paginate2(paginated), { headers });
    }

    static getAllId(token) {

        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.get(`${environment.urlApi}/api/configurations/all-id`, { headers });
    }


    static _paginate(pagination2: Pagination) {

        const { itemsPerPage, page, orderBy, order, wordList, relatedTableOrder } = pagination2;

        let url = `${environment.urlApi}/api/configurations/pagination`;
        if (itemsPerPage !== null && itemsPerPage !== undefined) {
            url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
            url += `itemsPerPage=${itemsPerPage}`;
        }

        if (page !== null && page !== undefined) {
            url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
            /**
             * En el backend el paginado empieza por 0, por eso hay que restar
             * un número
             */
            if (page == 0) url += `page=${page}`;
            else if (page > 0) url += `page=${page - 1}`;

        }

        if (orderBy !== null && orderBy !== undefined) {
            url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
            url += `orderBy=${orderBy}`;
        }

        if (order !== null && order !== undefined) {
            url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
            url += `order=${order}`;
        }

        if (relatedTableOrder !== null && relatedTableOrder !== undefined) {
            url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
            url += `relatedTableOrder=${relatedTableOrder}`;
        }

        if (wordList !== null && wordList !== undefined) {
            url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
            url += `wordList=${wordList}`;
        }

        // console.log(encodeURI(url));
        return encodeURI(url);
    }


    static _paginate2(pagination2: Pagination) {

        const { itemsPerPage, page, orderBy, order, wordList, relatedTableOrder, startDate, endDate } = pagination2;

        let obj: any = {};
        if (itemsPerPage !== null && itemsPerPage !== undefined) {
            obj.itemsPerPage = itemsPerPage;
        }

        if (page !== null && page !== undefined) {
            /**
                 * En el backend el paginado empieza por 0, por eso hay que restar
                 * un número
                 */
            if (page == 0) obj.page = page;
            else if (page > 0) obj.page = page - 1;
        }

        if (orderBy !== null && orderBy !== undefined) {
            obj.orderBy = orderBy;
        }

        if (order !== null && order !== undefined) {
            obj.order = order;
        }

        if (relatedTableOrder !== null && relatedTableOrder !== undefined) {
            obj.relatedTableOrder = relatedTableOrder;
        }

        if (wordList !== null && wordList !== undefined) {
            // obj.wordList = wordList.split(",");
            obj.wordList = wordList;
            // url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
            // url += `wordList=${wordList}`;
        }

        if ((startDate !== null && startDate !== undefined)
            && (endDate !== null && endDate !== undefined)) {
            obj.startDate = startDate;
            obj.endDate = endDate;
        }


        console.log("Obj completed", obj)

        // console.log(encodeURI(url));
        return obj;
    }

    static add(token, body: Configuration) {
        // let json = JSON.stringify(body);
        // let params = `json=${json}`;
        let headers = {
            // 'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/configurations/add`, body, { headers });
    }

    static update(token, body: Configuration) {

        let headers = {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/configurations/update`, body, { headers });
    }

    static delete(token, idList: string | number[], action: boolean) {

        let params = { list: idList, action };
        let headers = {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/configurations/delete`, params, { headers });
    }



}
