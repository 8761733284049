import { CCarousel, CCarouselItem, CImage } from '@coreui/react-pro'
import React from 'react';

export class CardPayload {
    message?: string; // Es "card" siempre
    items: {
        title?: string;
        subtitle?: string;
        imageUri?: string;
        buttons?: {
            value: string;
            text: string;
            isLink?: boolean;
            /** 
             * Activa un intent mediante un texto.
             * El mensaje se manda por debajo sin que lo vea el usuario
             */
            shadowMessage?: string
        }[];
    }[]
}

export const CardBot = (card: CardPayload & { userType: "bot" | "human", bannerColor: string }) => {
    const { items, userType, bannerColor } = card;

    const styles = {
        botBubble: {
            margin: "5px",
            alignSelf: userType === 'bot' ? 'flex-start' : 'flex-end',
        },
        link: {
            color: '#007bff',
            textDecoration: 'underline',
        }
    };

    // Función para verificar si la URL es válida
    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    }

    return (
        <div style={styles.botBubble}>
            <CCarousel controls>
                {items && items.map((item, i) => (
                    <CCarouselItem key={i}>
                        {item.imageUri &&
                            <CImage className="d-block w-100" src={item.imageUri} alt={item.title || 'Slide'} />
                        }
                        {item.title &&
                            <h5>{item.title}</h5>
                        }
                        {item.subtitle &&
                            <p>{item.subtitle}</p>
                        }
                        {item.buttons && item.buttons.map((button, j) =>
                            button.isLink && isValidUrl(button.value) ?
                                <a key={j} href={button.value} target="_blank" rel="noopener noreferrer" style={styles.link}>{button.text}</a> : null
                        )}
                    </CCarouselItem>
                ))}
            </CCarousel>
        </div>
    );
}
