export interface OptionFilterHome {
    name: string;
    type: string;
    end: boolean;
    value?: string[];
    options?: OptionFilterHome[];
}

export type OptionFilterHomeList = OptionFilterHome[];


export const initialOptions: OptionFilterHomeList = [
    {
        name: "Marca",
        type: "brand",
        end: false,
        options: [
            {
                type: "brand",
                name: "Todos",
                value: ["toyota", "lexus"],
                end: true,
            },
            {
                type: "brand",
                name: "Toyota",
                value: ["toyota"],
                end: true,
            },
            {
                type: "brand",
                name: "Lexus",
                value: ["lexus"],
                end: true,
            }
        ]
    },
    {
        name: "Tipo de contacto",
        type: "attClient",
        end: false,
        options: [
            {
                type: "attClient",
                name: "Todos",
                value: ['whatsapp', 'llamada', 'videollamada'],
                end: true,
            },
            {
                type: "attClient",
                name: "Whatsapp",
                value: ["whatsapp"],
                end: true,

            },
            {
                type: "attClient",
                name: "Llamada",
                value: ["llamada"],
                end: true,

            },
            {
                type: "attClient",
                name: "Videollamada",
                value: ["videollamada"],
                end: true,

            }
        ]
    },
    {
        name: "Acción",
        type: "action",
        end: false,
        options: [
            {
                type: "action",
                name: "Comprar",
                value: ["compra"],
                end: false,
                options: [
                    {
                        type: "vehicleType",
                        name: "Todos",
                        value: ["Ocasión", "Nuevo", "Seminuevo"],
                        end: true,
                    },
                    {
                        type: "vehicleType",
                        name: "Ocasión",
                        value: ["Ocasión"],
                        end: true,
                    },
                    {
                        type: "vehicleType",
                        name: "Nuevo",
                        value: ["Nuevo"],
                        end: true,
                    },
                    {
                        type: "vehicleType",
                        name: "Seminuevo",
                        value: ["Seminuevo"],
                        end: true,

                    },
                ],
            },
            {
                type: "action",
                name: "Cita",
                value: ["cita"],
                end: false,
                options: [
                    {
                        type: "typeAppointment",
                        name: "Todos",
                        value: ["mantenimiento", "carroceria", "mecanica", "campaña de seguridad"],
                        end: true,
                    },
                    {
                        type: "typeAppointment",
                        name: "Mantenimiento",
                        value: ["mantenimiento"],
                        end: true,
                    },
                    {
                        type: "typeAppointment",
                        name: "Carrocería",
                        value: ["carroceria"],
                        end: true,
                    },
                    {
                        type: "typeAppointment",
                        name: "Mecánica",
                        value: ["mecanica"],
                        end: true,
                    },
                    {
                        type: "typeAppointment",
                        name: "Campaña de seguridad",
                        value: ["campaña de seguridad"],
                        end: true,
                    },
                ],
            },
            {
                type: "action",
                name: "Renting",
                value: ["renting"],
                end: true,
            },
            {
                type: "action",
                name: "Tasar",
                value: ["tasar"],
                end: true,
            },
        ],
    }
];
