import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { CButton, CButtonGroup, CCard, CCardBody, CCardFooter, CCardHeader, CCol, CCollapse, CContainer, CImage, CRow, CSmartTable } from '@coreui/react-pro'
import STableCUI from '../../components/sTableCUI/STableCUI'


import { Invoicehead } from '../../../core/models/db/invoicehead';
import { User as MyNotifification } from '../../../core/models/db/user';
import { LoginService } from '../../../core/services/login/login-service';
import { LoaderContext } from '../../components/loader/context/LoaderContext';
import SearchTable from '../../components/@filters/searchTable/SearchTable';
import { Pagination } from '../../../core/models/pagination';
import { cilMoodBad, cilPlus } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Item } from '@coreui/react-pro/dist/components/table/types';
import { useUser } from '../../../core/hooks/db/user/useUser';
import { useNotification } from '../../../core/hooks/db/notification/useNotification';
import moment from 'moment';
import GKDateRangePicker from '../../components/@filters/dateRangePicker/DateRangePicker';
import { START_DATE_DEFAULT, END_DATE_DEFAULT } from '../../components/@filters/dateRangePicker/custon-ranges';
import { MyNotification } from '../../../core/models/db/notification';
import CustomerInformation from '../../components/customerInformation/CustomerInformation';
import useWindowDimensions from '../../../core/hooks/window-dimensions/userWindowDimensions';


const INIT_PAGINATION: Pagination = { page: 1, itemsPerPage: 10 };
const INIT_ALL_ROW: Pagination = { page: 1, itemsPerPage: 0 };

const LABEL_PAGE = "Notificaciones"
const Notifications = () => {

    const { getNotification } = useNotification();
    const { width, height } = useWindowDimensions();

    const navigate = useNavigate();

    const [body, setBody] = useState<MyNotifification[]>([]);
    const [reload, setRealod] = useState<boolean>(true);


    const { handleLoading, changeStyle } = useContext(LoaderContext);

    const [token, setToken] = useState(null);

    const [pagination, setPagination] = useState<Pagination>({
        ...INIT_PAGINATION,
        startDate: moment(START_DATE_DEFAULT).format("YYYY-MM-DD HH:mm"),
        endDate: moment(END_DATE_DEFAULT).format("YYYY-MM-DD HH:mm")
    });

    /**
  * Se usa al comienzo de cargar la página para rellenar la tabla
  */
    // useLayoutEffect(() => {
    //     (async () => {
    //         changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
    //         handleLoading(true);
    //         let t;
    //         if (token) {
    //             t = token;
    //             setToken(token)
    //         } else {
    //             t = await LoginService.getTokenAsync();
    //             setToken(t)
    //         }

    //         const response = await getNotification(t, pagination);
    //         if (response.ok) {
    //             let rows = response.item.rows;
    //             for (let i = 0; i < response.item.rows.length; i++) {

    //                 rows[i].priv_role = rows[i]?.roles ? rows[i]?.roles.title : null;
    //             }

    //             setBody(rows);
    //             setPagination({
    //                 ...pagination,
    //                 totalItems: response.item.totalItems,
    //                 totalPages: response.item.totalPages,
    //             })

    //             setTimeout(() => {
    //                 handleLoading(false)
    //             }, 200)
    //         }
    //     })()

    // }, [pagination.page, pagination.itemsPerPage, pagination.order, pagination.wordList, pagination.startDate]);

    const initPage = () => {
        (async () => {
            changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
            handleLoading(true);
            let t;
            if (token) {
                t = token;
                setToken(token)
            } else {
                t = await LoginService.getTokenAsync();
                setToken(t)
            }

            const response = await getNotification(t, pagination);
            if (response.ok) {
                let rows = response.item.rows;
                for (let i = 0; i < response.item.rows.length; i++) {

                    rows[i].priv_role = rows[i]?.roles ? rows[i]?.roles.title : null;
                }

                setBody(rows);
                setPagination({
                    ...pagination,
                    totalItems: response.item.totalItems,
                    totalPages: response.item.totalPages,
                })

                setTimeout(() => {
                    handleLoading(false)
                }, 200)
            }
        })()
    }

    useLayoutEffect(() => {
        setRealod(false);
        if (reload) {
            /**
             * Cuando se inicia la página o se hace algún cambio en el
             * paginado
             */
            initPage();
        }
    }, [reload]);


    const handleDate = (startDate: string, endDate: string) => {
        if (startDate && endDate) {
            setPagination({
                ...pagination,
                page: 1,
                startDate,
                endDate
            });
            setRealod(true);
        }
    }

    /**
     * Buscador
     * @param value 
     */
    const handleMessage = (value: string) => {
        setPagination({
            ...pagination,
            page: 1,
            wordList: value
        })
        setRealod(true);
    }

    /**
    * Buscador con chips
    * @param value 
    */
    const handleMessageList = (value: string[]) => {
        setPagination({
            ...pagination,
            page: 1,
            wordList: value.join(",")
        })
        setRealod(true);
    }

    const [details, setDetails] = useState([])
    const toggleDetails = (index) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
            newDetails.splice(position, 1)
        } else {
            newDetails = [...details, index]
        }
        setDetails(newDetails)
    }




    const buildInformationFromJSONLead = (jsonLead: string) => {
        try {
            if (jsonLead) {
                let object = JSON.parse(jsonLead);
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <div style={{ flex: '1 0 20%', marginTop: '10px' }}>
                            <h4 style={{ color: '#4a4a4a' }}>Información del cliente</h4>
                            {object.name && <p><strong>Nombre:</strong> <span style={{ color: '#999' }}>{object.name}</span></p>}
                            {object.email && <p><strong>Email:</strong> <a href={`mailto:${object.email}`}><span style={{ color: '#999' }}>{object.email}</span></a></p>}
                            {object.phoneNumber && <p><strong>Teléfono:</strong> <a href={`tel:${object.phoneNumber}`}><span style={{ color: '#999' }}>{object.phoneNumber}</span></a></p>}
                            {object.zipCode && <p><strong>Código postal:</strong> <span style={{ color: '#999' }}>{object.zipCode}</span></p>}
                        </div>
                        <div style={{ flex: '1 0 20%', marginTop: '10px' }}>
                            <h4 style={{ color: '#4a4a4a' }}>Petición del cliente</h4>
                            {object.action && <p><strong>Acción:</strong> <span style={{ color: '#999' }}>{object.action}</span></p>}
                            {object.vehicleType && <p><strong>Tipo de vehículo:</strong> <span style={{ color: '#999' }}>{object.vehicleType}</span></p>}
                            {object.brand && <p><strong>Marca:</strong> <span style={{ color: '#999' }}>{object.brand}</span></p>}
                            {object.location && <p><strong>Ubicación:</strong> <span style={{ color: '#999' }}>{object.location}</span></p>}
                        </div>
                        <div style={{ flex: '1 0 20%', marginTop: '10px' }}>
                            <h4 style={{ color: '#4a4a4a' }}>Contacto</h4>
                            {object.attClient && <p><strong>Atención al cliente:</strong> <span style={{ color: '#999' }}>{object.attClient}</span></p>}
                            {object.timeDate && <p><strong>Fecha y hora:</strong> <span style={{ color: '#999' }}>{object.timeDate}</span></p>}
                        </div>
                    </div>
                )
            } else {
                return undefined;
            }
        } catch (e) {
            console.error(e)
            return undefined;
        }
    }




    return (
        <CCard className="mb-4">
            <CCardHeader>
                <CRow>
                    <CCol sm={4}>
                        <h4 id="title-page" className="card-title mb-0">
                            {LABEL_PAGE}
                        </h4>
                        <div className="small text-medium-emphasis">Listado</div>
                    </CCol>
                    <CCol xs={8} md={8} xl={8} className="d-none d-md-block">
                        {/* <Link to={'/dashboard/usuarios/crear'} >
                            <CButton color="primary" className="float-end"  >
                                <CIcon icon={cilPlus} />
                            </CButton>
                        </Link> */}
                        <div className="col-4 float-end me-3">
                            <SearchTable
                                handleMessageList={handleMessageList}
                                handleMessage={handleMessage}
                                disabled={false}
                                multiWord={true} />
                        </div>
                        <div className="col-6 float-end me-3">
                            <GKDateRangePicker handleDate={handleDate} />
                        </div>
                    </CCol>


                </CRow>
            </CCardHeader>

            <CCardBody>
                <CRow>
                    <CCol>

                        {/* No hay información */}
                        {!body || body.length === 0 && (
                            <CContainer>
                                <div className="row justify-content-md-center">
                                    <CCol md="auto">
                                        <div style={{ fontSize: 20 }} className='d-flex justify-content-center align-items-center'>
                                            <span>No hay información <CIcon size='lg' icon={cilMoodBad} /></span>
                                        </div>
                                    </CCol>
                                </div>

                            </CContainer>
                        )}


                        {body && body.length > 0 && pagination && pagination.totalPages && (
                            <div className={width < 780 ? 'table-responsive' : ''}>

                                <CSmartTable
                                    items={body}
                                    columns={[
                                        {
                                            key: "title",
                                            label: "Título",
                                        },
                                        {
                                            key: "email",
                                            label: "Email"
                                        },
                                        {
                                            key: "createdDate",
                                            label: "Creado",

                                        },
                                        {
                                            key: 'show_details',
                                            label: '',
                                            _style: { width: '5%' },
                                            filter: false,
                                            sorter: false,
                                            _props: { className: 'fw-semibold' },
                                        },
                                    ]}
                                    scopedColumns={{
                                        email: (item) => {
                                            let value = "No hay emails";
                                            try {
                                                // console.log(item.email)
                                                let parsedEmail = JSON.parse(item.email)
                                                if (item.email && Array.isArray(parsedEmail) && parsedEmail.length) {
                                                    value = parsedEmail.slice(0, -1).join(', ') +
                                                        (parsedEmail.length > 1 ? ' y ' : '') +
                                                        parsedEmail.slice(-1);
                                                }
                                                return (
                                                    <td> {value} </td>
                                                )
                                            } catch (e) {
                                                console.error(e)
                                                return (
                                                    <td> {value}* </td>
                                                )
                                            }
                                        }
                                        ,
                                        createdDate: (item) =>
                                            (<td> {item.createdDate ? moment(item.createdDate).format("DD/MM/YYYY") : 'Fecha no registrada'}</td>),
                                        show_details: (item) => {
                                            return (
                                                <td className="py-2">
                                                    <CButton
                                                        color="dark"
                                                        variant="outline"
                                                        shape="square"
                                                        size="sm"
                                                        onClick={() => {
                                                            toggleDetails(item.id)
                                                        }}
                                                    >
                                                        {details.includes(item.id) ? 'Ocultar' : 'Mostrar'}
                                                    </CButton>
                                                </td>
                                            )
                                        },
                                        details: (item, number) => {
                                            return (
                                                <CCollapse visible={details.includes(item.id)}>
                                                    <CCardBody className="p-3">
                                                        {/* <h4>{item.title}</h4> */}
                                                        {/* <p className="text-title">
                                                        {moment(item.createdDate).format("DD/MM/YYYY")}
                                                    </p> */}
                                                        {item.userSocialMedias && (
                                                            <table style={{ width: '50%', borderCollapse: 'collapse' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        {/* <td style={{ fontWeight: 'bold' }}>Título email</td> */}
                                                                        <td style={{ fontWeight: 'bold' }}>Plataforma</td>
                                                                        <td style={{ fontWeight: 'bold' }}>Usuario</td>
                                                                    </tr>
                                                                    <tr>
                                                                        {/* {item && item.title &&
                                                                        <td style={{ color: '#999' }}>{item.title}</td>
                                                                    } */}
                                                                        {item && item.userSocialMedias && item.userSocialMedias.platform &&
                                                                            <td style={{ color: '#999' }}>{item.userSocialMedias.platform}</td>
                                                                        }
                                                                        {item && item.userSocialMedias && item.userSocialMedias.idUserPlatform &&
                                                                            <td style={{ color: '#999' }}>{item.userSocialMedias.idUserPlatform}</td>
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        )}
                                                        <div style={{ marginTop: 50 }}>
                                                            <div className="clearfix">
                                                                {/* <pre>{item.jsonLead}</pre>
                                                            <CImage rounded thumbnail src="https://previews.123rf.com/images/ccs0xx01/ccs0xx011504/ccs0xx01150400002/39059726-isometrical-construction-site-icon.jpg" width={200} height={200} /> */}
                                                                <CustomerInformation jsonLead={item.jsonLead} />
                                                            </div>

                                                        </div>
                                                    </CCardBody>
                                                </CCollapse>
                                            )
                                        },
                                    }}

                                    // columnFilter={{
                                    //     external: true
                                    // }}

                                    columnSorter={{
                                        external: true
                                    }}

                                    pagination
                                    clickableRows
                                    itemsPerPageSelect
                                    itemsPerPageLabel={"Registros por página"}
                                    onRowClick={(item: Item, index: number, columnName: string, event) => {
                                        let target = typeof event !== 'boolean' ? event.nativeEvent.target as HTMLElement : event;
                                        let value = typeof target === 'boolean' ? target : target.tagName;

                                        /** Significa que se ha clicado en el check (INPUT) */
                                        if (typeof target !== 'boolean' && value === "INPUT") {
                                            //    Clica al check
                                        } else if (typeof target !== 'boolean' && value === "TD") {
                                            //    Console ir al detalle
                                        }
                                    }}

                                    onSelectAll={() => {
                                        console.log("calling all of ids")
                                    }}
                                    onSelectedItemsChange={(items) => {
                                        console.log("items that you selected", items)
                                    }}


                                    onSorterChange={(value) => {
                                        console.log("what is value?", value)
                                        if (value.state === 0) {
                                            setPagination({
                                                ...pagination,
                                                relatedTableOrder: null,
                                                order: null,
                                                orderBy: null
                                            })
                                        } else if (value.column === "priv_role") {
                                            setPagination({
                                                ...pagination,
                                                relatedTableOrder: "roles",
                                                order: value.state,
                                                orderBy: "title"
                                            })
                                        } else {
                                            setPagination({
                                                ...pagination,
                                                relatedTableOrder: null,
                                                order: value.state,
                                                orderBy: value.column
                                            })
                                        }

                                        setTimeout(() => {
                                            setRealod(true);
                                        }, 100);

                                    }}


                                    paginationProps={{
                                        activePage: pagination.page,
                                        pages: pagination.totalPages,
                                        limit: 4,
                                        onActivePageChange: (activePage: number) => {
                                            console.log(activePage)
                                            setPagination({
                                                ...pagination,
                                                page: activePage,
                                            });
                                            setRealod(true);
                                        }
                                    }}

                                    onItemsPerPageChange={(currItemsPerPage) => {
                                        const { page, totalItems } = pagination;

                                        const totalPages = Math.floor(totalItems / currItemsPerPage) + 1;
                                        const newPositionPage = Math.min(totalPages, page);
                                        setPagination({
                                            ...pagination,
                                            page: newPositionPage,
                                            itemsPerPage: currItemsPerPage
                                        });
                                        setRealod(true);
                                    }}
                                />
                            </div>
                        )}

                    </CCol>



                </CRow>

            </CCardBody>
            {/* <CCardFooter>
        <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
          Footer
        </CRow>
      </CCardFooter> */}
        </CCard >

    )
}

export default Notifications



