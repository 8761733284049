import React, { useEffect, useState } from 'react'
import { CMultiSelect } from '@coreui/react-pro';
import { TableNamesType } from '../../../core/models/interfaces/interfaces';
import { useRole } from '../../../core/hooks/db/role/useRole';
import { useUser } from '../../../core/hooks/db/user/useUser';
import { LoginService } from '../../../core/services/login/login-service';
import { Util } from '../../../core/util/util';
import { useUserSocialMedia } from '../../../core/hooks/db/user-social-media/useUserSocialMedia';



const ChooseHook = (table: TableNamesType) => {
    const userHook = useUser();
    const roleHook = useRole();
    const userSocialMediaHook = useUserSocialMedia();


    let hookFunction;
    switch (table) {
        case "users":
            hookFunction = userHook;
            break;
        case "roles":
            hookFunction = roleHook;
            break;
        case "userSocialMedias":
            hookFunction = userSocialMediaHook;
            break;
    }

    return hookFunction
}

function fillOutObject(table: TableNamesType, rows: any[], defaultRow) {

    return new Promise<any>((res, rej) => {
        let body = [];
        console.log(rows)
        for (let [i, row] of rows.entries()) {
            switch (table) {
                case "users":
                    row = { ...row, text: Util.capitalize(row.username), value: row.id }
                    break;
                case "roles":
                    row = { ...row, text: Util.capitalize(row.title), value: row.id }
                    break;
                case "userSocialMedias":
                    row = { ...row, text: Util.capitalize(row.idUserPlatform), value: row.id }
                    break;

            }
            if (defaultRow && defaultRow.value === row[defaultRow.key]) {
                row.selected = true;
            } else {
                row.selected = false;
            }

            body.push(row)

            if (rows.length - 1 == i) {
                res(body);
            }
        }
    })
}

interface AutocompleteProps {
    placeholder?: string;
    disabled?: boolean;
    id?: string;
    name: string;
    defaultValue?: {
        key: string,
        value: any
    };
    table: TableNamesType;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLSelectElement>) => void;
}

const Autocomplete = (props: AutocompleteProps) => {

    const { table, name, defaultValue, placeholder, id, disabled, onChange, onBlur, } = props;
    const { autocomplete } = ChooseHook(table);

    const [token, setToken] = useState(null);
    const [body, setBody] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [bodyAutocomplete, setBodyAutocomplete] = useState<{ text: string, value: number }[]>([]);


    useEffect(() => {
        (async () => {
            setLoading(true);

            const token = await LoginService.getTokenAsync();
            setToken(token)
            const response = await autocomplete(token);
            console.log(response)
            if (response.ok) {
                let rows = response.item;
                let transformed = await fillOutObject(table, rows, defaultValue);

                setBody(transformed);
                setLoading(false);
            } else {
                setLoading(false);

            }

        })()
    }, []);


    return (
        <CMultiSelect
            id={id ? id : "autocomplete-gk"}
            disabled={disabled}
            loading={loading}
            placeholder={placeholder ? placeholder : "Selecciona un registro"}
            searchNoResultsLabel={"No se encontro ningún resultado"}
            optionsStyle={"text"}
            multiple={false}
            options={body as any}
            // clearSearchOnSelect={false}
            cleaner={true}

            onFilterChange={(e) => {
                // console.log("onFilterChange", e)

                let obj: any = {
                    target: {
                        name: name,
                        value: null
                    }
                }
                onChange(obj)
            }}
            onChange={(e: any) => {
                // console.log("on change", e)
                let value: any;
                if (e && e.length > 0) {
                    value = e[0].value;
                    let obj: any = {
                        target: {
                            name: name,
                            value: value
                        }
                    }
                    onChange(obj)
                } else {
                    if (!name) {
                        throw new Error("No existe la propiedad name en el autocomplete");
                    } else {
                        console.warn(e)
                        throw new Error("Hubo un error en el autocomplete");
                    }
                }


            }}
            onBlur={onBlur as any}
        />
    )
}

export default Autocomplete
