import React from 'react'
import { cibGooglePodcasts, cibMessenger, cibProbot, cilBriefcase, cilEnvelopeClosed, cilExitToApp, cilFace, cilGarage, cilHome, cilPeople, cilSettings, cilSpeedometer, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CNavGroup, CNavItem } from '@coreui/react-pro'
import { ElementType } from 'react'
import { BASE_ROUTE, ROUTE_NAME } from '../../core/constants/route_name'

export type Badge = {
  color: string
  text: string
}

export interface NavItem {
  component: string | ElementType
  name: string | JSX.Element
  icon?: string | JSX.Element
  badge?: Badge
  to: string
  items?: NavItem[]
  roles?: number[] // Aquí añades la propiedad roles
}

const navSideBar: NavItem[] = [
  {
    component: CNavItem,
    name: 'Home',
    roles: [1, 2],
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info-gradient',
    //   text: 'NEW',
    // },
    to: BASE_ROUTE + ROUTE_NAME.home,
  },
  {
    component: CNavGroup,
    name: 'Sistema',
    roles: [1],
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    to: '',
    items: [
      {
        component: CNavItem,
        name: 'Usuarios',
        roles: [1],
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        // badge: {
        //   color: 'info-gradient',
        //   text: 'NEW',
        // },
        to: BASE_ROUTE + ROUTE_NAME.usuarios,
      },
      {
        component: CNavItem,
        name: 'Roles',
        roles: [1],
        icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
        // badge: {
        //   color: 'info-gradient',
        //   text: 'NEW',
        // },
        to: BASE_ROUTE + ROUTE_NAME.rol,
      },
      {
        component: CNavItem,
        name: 'Configuración',
        roles: [1],
        icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
        // badge: {
        //   color: 'info-gradient',
        //   text: 'NEW',
        // },
        to: BASE_ROUTE + ROUTE_NAME.configuration,
      },
      {
        component: CNavItem,
        name: 'Configuración SMTP',
        roles: [1],
        icon: <CIcon icon={cibGooglePodcasts} customClassName="nav-icon" />,
        // badge: {
        //   color: 'info-gradient',
        //   text: 'NEW',
        // },
        to: BASE_ROUTE + ROUTE_NAME.smtp,
      },
    ]
  },
  {
    component: CNavGroup,
    name: 'Bot',
    roles: [1, 2],
    icon: <CIcon icon={cibProbot} customClassName="nav-icon" />,
    to: '',
    items: [
      {
        component: CNavItem,
        name: 'Usuarios del bot',
        roles: [1, 2],
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        to: BASE_ROUTE + ROUTE_NAME.usuariosSocialMedia,
      },
      {
        component: CNavItem,
        name: 'Conversaciones',
        roles: [1, 2],

        icon: <CIcon icon={cibMessenger} customClassName="nav-icon" />,
        to: BASE_ROUTE + ROUTE_NAME.conversaciones,
      },
    ]
  },
  {
    component: CNavItem,
    name: 'Notificaciones',
    roles: [1, 2],

    icon: <CIcon icon={cilEnvelopeClosed} customClassName="nav-icon" />,
    to: BASE_ROUTE + ROUTE_NAME.notificaciones,
  },
  {
    component: CNavItem,
    name: 'Citas',
    roles: [1, 2],
    icon: <CIcon icon={cilGarage} customClassName="nav-icon" />,
    to: BASE_ROUTE + ROUTE_NAME.citas,
  },
  {
    component: CNavItem,
    name: 'Salir',
    roles: [1, 2],
    icon: <CIcon icon={cilExitToApp} customClassName="nav-icon" />,
    to: ROUTE_NAME.login,
  },
]

export default navSideBar
