import { CRow, CToaster, CToast, CToastBody, CToastClose } from '@coreui/react-pro';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { SnackbarState } from './hook/useSnackbar';
import { Portal } from 'react-portal';

const COLOR_SUCCESS = "#68ed87";
const COLOR_ERROR = "#f55b5b";
const COLOR_NEUTRO = "#cccccc";

const MAX_SIZE_TEXT = 50;


export default function Snackbar(props: SnackbarState) {
    const { changeState, message, wait, color, colorAction } = props;
    const [show, setShow] = useState(false);
    const isInitialMount = useRef(true); // Referencia para verificar si es la inicialización del componente

    const chooseColor = () => {
        if (!color && colorAction) {
            if (colorAction === "success") return COLOR_SUCCESS;
            if (colorAction === "error") return COLOR_ERROR;
        } else if (color) {
            return color;
        } else {
            return COLOR_NEUTRO;
        }
    }

    const showToast = useCallback(() => {
        setShow(true);
        setTimeout(() => setShow(false), wait || 5000);
    }, []);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else if (changeState) {

            showToast()
        }
    }, [props])


    return (

        <CRow>
            <Portal node={document && document.body}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'fixed',
                    width: '100%',
                    bottom: 100,
                    zIndex: 1051
                }}>
                    <CToaster key={'toaster'}>
                        <CToast visible={show} style={{ backgroundColor: chooseColor(), borderColor: chooseColor() }}>
                            <div className="d-flex">
                                <CToastBody>
                                    {message && message.length > MAX_SIZE_TEXT
                                        ? message.substring(0, MAX_SIZE_TEXT) + "..."
                                        : message && message.length < MAX_SIZE_TEXT
                                            ? message
                                            : ""
                                    }
                                </CToastBody>
                                <CToastClose className="me-2 m-auto" />
                            </div>
                        </CToast>
                    </CToaster>
                </div>
            </Portal>
        </CRow>





    );
}