import { CSSProperties, useState } from "react";

interface LoaderProps {
    isLoading?: boolean;
    styleLoader?: CSSProperties;
    classNameLoader?: string;
}


export const useLoader = ({ isLoading = false, styleLoader, classNameLoader }: LoaderProps) => {

    const [loading, setLoading] = useState<boolean>(isLoading);
    const [style, setStyle] = useState<CSSProperties>(styleLoader);
    const [className, setClassName] = useState<string>(classNameLoader);


    const [styleBackup, setStyleBackup] = useState<CSSProperties>(styleLoader);
    const [classNameBackup, setClassNameBackup] = useState<string>(classNameLoader);


    const handleLoading = (value: boolean) => {
        setLoading(prev => value);
        if (!value) {
            setStyle(prev => styleBackup);
            setClassName(prev => classNameBackup);
        }
    }

    const changeStyle = (value: CSSProperties) => {
        setStyleBackup(prev => style);
        setStyle(value);

    }

    const changeClassName = (value: string) => {
        setClassNameBackup(prev => className);
        setClassName(value);
    }

    return {
        loading,
        className,
        style,

        handleLoading,
        changeStyle,
        changeClassName,
    }
}