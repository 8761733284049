import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik';
import validationConfigurationSchema from './cf_validator_yup';
import * as Yup from 'yup';
import { cilUser, cilEnvelopeClosed, cilLockLocked, cilInfo, cilFeaturedPlaylist, cibTwitter, cibInstagram, cibFacebook, cilWindowMaximize, cilBriefcase } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CRow, CCol, CInputGroup, CInputGroupText, CFormInput, CAlert, CFormCheck, CFormLabel, CContainer } from '@coreui/react-pro';
import { Autocomplete } from '@mui/material';
import ButtonLoadingSucessForm from '../../components/buttonLoadingSucessForm/ButtonLoadingSucessForm';
import CheckPassword from '../../components/checkPassword/CheckPassword';
import DragDrop from '../../components/drag-drop/DragDrop';
import { Configuration } from '../../../core/models/db/configuration';
import { FileItemObservableService } from '../../components/drag-drop/services/rxJS/fileitem-observable.service';


interface ConfigurationFormProps {
    configuration: Configuration;
    typeAction: "add" | "update";
    loadingButton?: boolean;

    add?: (configuration: Configuration) => Promise<any>;
    update?: (configuration: Configuration) => Promise<any>;
}


const ConfigurationForm = (props: ConfigurationFormProps) => {

    /**
  * Este fragmento es necesario porque el navegador rellena de manera
  * automática los inputs username y password. Después de intentarlo mediante
  * otros métodos se ha optado por el siguiente
  */
    const autocompleteOff = () => setTimeout(() => setAutoOff(false), 100)
    const [autoOff, setAutoOff] = useState(null);
    useEffect(() => {
        autocompleteOff()
    }, [])

    // Comienzo de la página
    // Comienzo de la página
    // Comienzo de la página

    const {
        configuration,
        typeAction,
        loadingButton,

        add,
        update,

    } = props;


    const [showPassword, setShowPassword] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [imageDragDrop, setImageDragDrop] = useState<string>(configuration && configuration.socialLogo ? configuration.socialLogo : "");

    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: {
            ...configuration,
        },
        validateOnBlur: false,
        onSubmit: (value) => {
            console.log(value)
            if (typeAction === "add") {
                add(value);
            } else if (typeAction === "update") {
                update(value);
            }
        },
        validationSchema: Yup.lazy(values => validationConfigurationSchema(values, typeAction, touched))
    })

    /**
     * Esto solo es necesario cuando no vuelves a la pantalla de listado
     */
    useEffect(() => {
        setValues(configuration)
    }, [configuration])



    // Cojo la referencia del observable para saber cuando ha habido un cambio
    const controlFile$ = React.useRef(FileItemObservableService.instance.control$);

    /** Cuando existe un cambio en el observable actializo el listado de items */
    useEffect(() => {
        const subscription = FileItemObservableService.instance
            .control$.subscribe(
                item => {
                    if (item && item.fileItemList && item.fileItemList.length > 0) {
                        values.socialLogo = item.fileItemList[0].pathRelative;
                        setFieldValue('socialLogo', item.fileItemList[0].pathRelative);
                    } else {
                        setFieldValue('socialLogo', "");
                    }
                }
            )
        return () => {
            subscription.unsubscribe();
        }

    }, [controlFile$]);



    const changeToBackup = (value: boolean) => {
        if (value) {
            setValues(
                {
                    ...configuration,
                }
            );
        }
    }

    const isEdit = (value: boolean) => {
        setDisabled(value);
    }

    return (
        <CRow >

            <form onSubmit={handleSubmit} >
                <CRow className="row justify-content-md-center">
                    {/* <pre> {JSON.stringify(configuration, null, 2)}</pre>
                    <pre> {JSON.stringify(values, null, 2)}</pre> */}


                    <CCol sm={6}>

                        <DragDrop
                            styleDD={{ maxWidth: "auto", height: 250, marginTop: 50 }}

                            id={"test-id"}
                            valueDefault={imageDragDrop}
                            isOnlyOneFile={true}
                            disabled={(disabled && typeAction !== "add") || loadingButton}
                            directoryNameList={["fromReact"]}
                            maxSizeMB={19}
                            typeAllowed={[
                                "image/*",
                                "image/gif",
                                "image/jpeg",
                                "image/png",
                                "image/jpg"
                            ]} />


                    </CCol>


                    <CCol sm={6}>

                        <CCol>
                            {/* <CFormLabel htmlFor="email-configuration"><b>Email</b></CFormLabel> */}
                            <CInputGroup className="mb-4">
                                <CFormInput id="email-configuration"
                                    placeholder='Email'
                                    disabled={(disabled && typeAction !== "add") || loadingButton}
                                    type="text"
                                    {...getFieldProps("notificationEmail")} />
                                <CInputGroupText>
                                    {/* <CIcon icon={cilEnvelopeClosed} /> */}
                                    Email
                                </CInputGroupText>
                            </CInputGroup>
                        </CCol>
                        <div style={{ height: "auto" }}>
                            {touched.notificationEmail && errors.notificationEmail && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'>{errors.notificationEmail}</div>
                                </CAlert>
                            )}
                        </div>

                        <CInputGroup className="mb-4">

                            {/* <CFormLabel htmlFor="name-configuration"><b>Nombre referencial</b></CFormLabel> */}

                            <CInputGroupText>
                                {/* <CIcon icon={cilWindowMaximize} /> */}
                                Web
                            </CInputGroupText>
                            <CFormInput id="name-configuration"
                                placeholder='Web'
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                type="text"
                                {...getFieldProps("web")} />


                            <div style={{ height: "auto" }}>
                                {touched.web && errors.web && (
                                    <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                        <div className='small'>{errors.web}</div>
                                    </CAlert>
                                )}
                            </div>



                            {/* <CFormLabel htmlFor="name-configuration"><b>Nombre referencial</b></CFormLabel> */}

                            <CInputGroupText>
                                <CIcon icon={cilBriefcase} />
                                {/* Legal Url */}
                            </CInputGroupText>
                            <CFormInput id="name-configuration"
                                placeholder='Legal URL'
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                type="text"
                                {...getFieldProps("legalUrl")} />


                            <div style={{ height: "auto" }}>
                                {touched.legalUrl && errors.legalUrl && (
                                    <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                        <div className='small'>{errors.legalUrl}</div>
                                    </CAlert>
                                )}
                            </div>
                        </CInputGroup>

                        {/* <CFormLabel htmlFor="email-configuration"><b>Host</b></CFormLabel> */}
                        <CInputGroup className="mb-3">
                            <CFormInput
                                id="host-configuration"
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                placeholder='Título'
                                {...getFieldProps("title")}
                            />
                            <CInputGroupText>
                                {/* <CIcon icon={cilFeaturedPlaylist} /> */}
                                Título
                            </CInputGroupText>
                        </CInputGroup>

                        <div style={{ height: "auto" }}>
                            {touched.title && errors.title && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'>{errors.title}</div>
                                </CAlert>
                            )}
                        </div>



                        <CCol>
                            {/* <CFormLabel htmlFor="port-configuration"><b>Puerto</b></CFormLabel> */}

                            <CInputGroup className="mb-4">
                                <CInputGroupText>
                                    <CIcon icon={cibFacebook} />
                                </CInputGroupText>
                                <CFormInput id="port-configuration"
                                    placeholder='Facebook'
                                    disabled={(disabled && typeAction !== "add") || loadingButton}
                                    type="string"
                                    {...getFieldProps("facebook")} />

                            </CInputGroup>
                        </CCol>
                        <div style={{ height: "auto" }}>
                            {touched.facebook && errors.facebook && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'>{errors.facebook}</div>
                                </CAlert>
                            )}
                        </div>

                        <CCol>
                            {/* <CFormLabel htmlFor="name-configuration"><b>Nombre referencial</b></CFormLabel> */}
                            <CInputGroup className="mb-4">
                                <CInputGroupText>
                                    <CIcon icon={cibInstagram} />
                                </CInputGroupText>
                                <CFormInput id="name-configuration"
                                    placeholder='Instagram'
                                    disabled={(disabled && typeAction !== "add") || loadingButton}
                                    type="text"
                                    {...getFieldProps("instagram")} />

                            </CInputGroup>
                        </CCol>
                        <div style={{ height: "auto" }}>
                            {touched.instagram && errors.instagram && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'>{errors.instagram}</div>
                                </CAlert>
                            )}
                        </div>

                        <CCol>
                            {/* <CFormLabel htmlFor="name-configuration"><b>Nombre referencial</b></CFormLabel> */}
                            <CInputGroup className="mb-4">
                                <CInputGroupText>
                                    <CIcon icon={cibTwitter} />
                                </CInputGroupText>
                                <CFormInput id="name-configuration"
                                    placeholder='Twitter'
                                    disabled={(disabled && typeAction !== "add") || loadingButton}
                                    type="text"
                                    {...getFieldProps("twitter")} />

                            </CInputGroup>
                        </CCol>
                        <div style={{ height: "auto" }}>
                            {touched.twitter && errors.twitter && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    <div className='small'>{errors.twitter}</div>
                                </CAlert>
                            )}
                        </div>



                        <CContainer style={{ marginTop: 50 }}>
                            <ButtonLoadingSucessForm
                                loadingButton={loadingButton}
                                className="d-flex d-row"
                                changeToBackup={changeToBackup}
                                onSubmit={handleSubmit}
                                setDisabled={isEdit}
                                typeAction={typeAction}
                                disabled={!isValid}
                            />
                        </CContainer>


                    </CCol>
                </CRow>
            </form >
        </CRow >
    )
}

export default ConfigurationForm
