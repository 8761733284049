export const COLORS = {
    FACEBOOK: "#3b6ad1",
    INSTAGRAM: "#de2a7b",
    WEB: "#d91821",
    WHATSAPP: "#22b322",
    TELEGRAM: "#5f82d9",

    NIMO_ORIGINAL: '#0a1215',
    NIMO_CLARO: '#101d23',
    NIMO_MAS_CLARO: 'rgba(16, 29, 35, 0.8)',


}




interface ColorScheme {
    primary: string;
    secondary: string;
    tertiary: string;
}

// export const actionColorScheme: { [action: string]: ColorScheme } = {
//     'compra': {
//         primary: '#6F9CDA',
//         secondary: '#4D7298',
//         tertiary: '#2F4B67',
//     },
//     'cita': {
//         primary: '#EB9C4D',
//         secondary: '#BA6F31',
//         tertiary: '#895218',
//     },
//     'renting': {
//         primary: '#58A4B0',
//         secondary: '#3F7078',
//         tertiary: '#27483F',
//     },
//     'alquiler diario': {
//         primary: '#CA555E',
//         secondary: '#963E45',
//         tertiary: '#62272C',
//     },
//     'horario y contacto': {
//         primary: '#A6738C',
//         secondary: '#7E5367',
//         tertiary: '#553542',
//     },
//     'tasar vehículo': {
//         primary: '#74B49B',
//         secondary: '#517C6E',
//         tertiary: '#2F4B42',
//     },
// };


export const actionColorScheme: { [action: string]: ColorScheme } = {
    'compra': {
        primary: '#FF6347',    // Tomate
        secondary: '#FF7F50',  // Coral
        tertiary: '#FF8C00',   // Naranja oscuro
    },
    'cita': {
        primary: '#FFD700',    // Oro
        secondary: '#DAA520',  // Barra de oro
        tertiary: '#CD853F',   // Peru
    },
    'renting': {
        primary: '#7FFF00',    // Chartreuse
        secondary: '#ADFF2F',  // Verde amarillo
        tertiary: '#7CFC00',   // Verde césped
    },
    'alquiler diario': {
        primary: '#FF4500',    // Rojo naranja
        secondary: '#FF6347',  // Tomate
        tertiary: '#FF7F50',   // Coral
    },
    'horario y contacto': {
        primary: '#8A2BE2',    // Violeta azul
        secondary: '#9400D3',  // Violeta oscuro
        tertiary: '#9932CC',   // Orquídea oscuro
    },
    'tasar': {
        primary: '#1E90FF',    // Azul dodger
        secondary: '#00BFFF',  // Azul cielo profundo
        tertiary: '#87CEFA',   // Azul cielo claro
    },
};