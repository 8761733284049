import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { Util } from '../../../../core/util/util';
import { actionColorScheme } from '../../../../core/constants/colors';

Chart.register(...registerables);

// Declare the type for chartData explicitly
type ChartData = {
    [platform: string]: {
        total: number,
        notifications: number,
        actions: {
            [action: string]: {
                total: number,
                notifications: number
            }
        }
    }
}

export const HomeBarChart = ({ chartData }: { chartData: ChartData }) => {

    const actions = new Set<string>();
    Object.values(chartData).forEach(item => {
        Object.keys(item.actions).forEach(action => {
            actions.add(action);
        });
    });

    const barData = Array.from(actions).map((action: string) => ({
        type: 'bar',
        label: Util.capitalize(action),
        data: Object.keys(chartData).map(social => chartData[social].actions[action]?.total || 0),
        backgroundColor: actionColorScheme[action]?.primary || `rgb(${Math.floor(Math.random() * 100)},${Math.floor(Math.random() * 100)},${Math.floor(Math.random() * 100)})`,
    }));

    const totalBarData = {
        type: 'bar',
        label: 'Total',
        data: Object.keys(chartData).map(social => chartData[social].total),
        backgroundColor: `rgb(${Math.floor(Math.random() * 100)},${Math.floor(Math.random() * 100)},${Math.floor(Math.random() * 100)})`,
        order: 2
    };

    const data = {
        labels: Object.keys(chartData),
        datasets: [...barData, totalBarData] as any,
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    return <Bar data={data} options={options as any} />;
};
