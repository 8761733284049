import React, { useState, useEffect } from "react";
import './style-fsd.scss'; // Importar los estilos

import { cilBackspace } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { classNames } from "../../../utils/classes-css";
import { COLORS } from "../../../../core/constants/colors";

const FilterSelectDataComponent = ({ initialOptions, onFilter }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredObject, setFilteredObject] = useState({});
  const [menuVisible, setMenuVisible] = useState(false);
  const [endOption, setEndOption] = useState(false);

  // useEffect(() => {
  //   if (endOption) {

  //     let obj = {};
  //     selectedOptions.forEach(option => {
  //       obj[option.type] = option.value;
  //     });
  //     setFilteredObject(obj);
  //     onFilter(obj); // Pass the filtered object to parent component
  //   }
  // }, [endOption, selectedOptions, onFilter]);


  useEffect(() => {

    if (endOption) {
      let obj = {};
      selectedOptions.forEach(option => {
        obj[option.type] = option.value;
      });
      setFilteredObject(obj);
      onFilter(obj);
    } else {
      onFilter(undefined)
    }
  }, [endOption, selectedOptions]);

  // Similarmente añade console.logs en handleGoBack y handleClear


  const getOptions = () => {
    let options = initialOptions;
    for (const selectedOption of selectedOptions) {
      const nextOption = options.find(option => option.name === selectedOption.name);
      options = nextOption.options || [];
    }
    return options;
  };

  const handleOptionClick = (option) => {
    setSelectedOptions([...selectedOptions, option]);
    setEndOption(option.end);
  };

  const handleGoBack = () => {
    if (selectedOptions.length > 0) {
      setSelectedOptions([...selectedOptions.slice(0, selectedOptions.length - 1)]);
      setEndOption(false);
    }
  };

  const handleClear = () => {
    setSelectedOptions([]);
    setEndOption(false);
    setFilteredObject({});
  }

  const handleFilter = () => {
    setMenuVisible(true);
    handleClear();
  }

  const handleButtonClick = () => {
    if (endOption) {
      handleFilter();
    } else {
      setMenuVisible(!menuVisible);
    }
  }

  return (
    <div style={{ userSelect: 'none', }} className="filter-select-data-component__flex align-items-center">
      <button style={{ backgroundColor: COLORS.NIMO_CLARO }} className="btn btn-primary btn-loading filter-select-data-component__btn-filter filter-select-data-component__margin-right"
        onClick={handleButtonClick}>
        <i className="fas fa-filter mr-1"></i> {
          endOption ? (<span onClick={handleClear}>Filtrar</span>) :
            menuVisible ? (<span onClick={handleClear}>Limpiar</span>) : (<span onClick={handleClear}> Filtrar</span>)
        }
      </button>
      <div className="filter-select-data-component__flex flex-wrap filter-select-data-component__align-items-center filter-select-data-component__margin-bottom">
        {selectedOptions.map((option, index) => (
          <div key={index} className="filter-select-data-component__chip filter-select-data-component__margin-right filter-select-data-component__margin-bottom">
            {option.name}
          </div>
        ))}
      </div>

      {menuVisible && !endOption && (
        <>
          <div className={classNames(selectedOptions ? 'filter-select-data-component__options-border' : '', 'filter-select-data-component__padding', "filter-select-data-component__border-radius filter-select-data-component__margin-top")}>
            {getOptions().map((option, index) => (
              <div key={option.id || index}
                className="w-100 filter-select-data-component filter-select-data-component__align-items-center justify-content-between filter-select-data-component__options-filter"
                onClick={() => handleOptionClick(option)}>
                <span>{option.name}</span>
              </div>
            ))}
          </div>
        </>
      )}

      {selectedOptions.length > 0 && !endOption && (
        <div onClick={handleGoBack} style={{ marginLeft: 10 }} className="filter-select-data-component__btn-back filter-select-data-component__margin-bottom">
          <CIcon icon={cilBackspace} style={{ marginRight: 3 }} /> Volver
        </div>
      )}
    </div >
  );
};

export default FilterSelectDataComponent;
