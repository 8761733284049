import { TableNamesType } from "../../../../core/models/interfaces/interfaces";
import { ActionBarOptions } from "../interfaces";

export interface ActionBarReduxState {
    showActionBar: boolean;
    table?: TableNamesType
    reset?: boolean;
    options?: ActionBarOptions;
}

export type ActionBarReducerRootState = ReturnType<typeof actionBarReducer>;

const initialState: ActionBarReduxState = {
    showActionBar: false,
    reset: false
};

function actionBarReducer(state = initialState, action) {

    // console.log(state, action)
    switch (action.type) {
        case 'SHOW_ACTION_BAR':
            return {
                ...state,
                reset: false,
                showActionBar: true,
            };
        case 'HIDE_ACTION_BAR':
            return {
                ...state,
                showActionBar: false,
            };
        case 'LOAD_ACTION_BAR':
            return {
                ...state,
                reset: false,
                options: action.payload
            };
        case 'RESET_PAGE_ACTION_BAR':
            return {
                ...state,
                reset: true,
                showActionBar: false,
            };
        default:
            return state;
    }
}

export default actionBarReducer;